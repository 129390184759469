import './index.css'
import { Icon } from '@iconify/react';
import { toast } from 'react-hot-toast';
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';

import ModalAdress from '../../../../components/ModalAdress';
import ButtonBackAnimate from '../../CartPage/ButtonBackAnimate';
import ModalWarning from '../../../../components/Modals/ModalWarning';

import FreightService from '../../../../services/FreightService';
import CustomerService from '../../../../services/CustomerService';

import CartContext from '../../../../context/cart/cartContext';
import AuthContext from '../../../../context/auth/AuthContext';

const SelectAddress = () => {
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const cartContext = useContext(CartContext);

    const [loading, setLoading] = useState(true);
    const [addresses, setAddresses] = useState([]);
    const [freightData, setFreightData] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [selectedFreight, setSelectedFreight] = useState({});
    const [deletedAddressId, setDeletedAddressId] = useState(0);
    const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false);
    const [showCreateNewAddressModal, setShowCreateNewAddressModal] = useState(false);

    useEffect(() => {
        getCustomerData();
    }, []);

    const getCustomerData = async () => {
        const customerId = authContext.user.id;

        CustomerService.getUserById(customerId).then(response => {
            const { address } = response.data;
            const defaultAddress = address.find(address => address?.is_default);
            
            setAddresses(address);
            setSelectedAddress(defaultAddress);

            if (defaultAddress) {
                calculateFreight(defaultAddress?.zipcode);
            }

        }).finally(() => setLoading(false));
    }

    const calculateFreight = cepValue => {
        return FreightService.calculateCartFreight(cepValue)
        .then(response => {
            const freightData = response;
            setFreightData(freightData);
        })
    }

    const handleCreateCustomerAddress = () => {
        toast.promise(getCustomerData(), {
            loading: 'Carregando...',
            success: 'Usuário carregado com sucesso',
            error: 'Não foi possível carregar o usuário'
        });
    }

    const handleUpdateSelectedAddress = address => {
        setSelectedFreight({});
        setSelectedAddress(address);

        toast.promise(calculateFreight(address?.zipcode), {
            loading: "Calculando frete...",
            success: "Frete calculado com sucesso.",
            error: "Não foi possível calcular o frete"
        });
    }

    const calculateCartTotal = () => {
        const cartTotal = parseFloat(cartContext?.totalPrice);
        const cartTotalWithDiscounts =  parseFloat(cartContext?.totalWithDiscounts);
        const freightValue = selectedFreight?.valor ? parseFloat(selectedFreight?.valor) : 0;
 
        if(cartTotalWithDiscounts > 0) {
            return (cartTotalWithDiscounts + freightValue)?.toFixed(2)?.replace('.', ',');
        } else {
            return (cartTotal + freightValue)?.toFixed(2)?.replace('.', ',');
        }
    }

    const handleUpdateSelectedFreight = freight => {
        setSelectedFreight(freight);
    }

    const handleSaveFreightData = () => {
        const isFreightSelected = Object.keys(selectedFreight)?.length > 0;
        
        
        if(isFreightSelected) {
            const selectedAddressId = selectedAddress?.id;

            toast.promise(cartContext.updateCartFreightData(selectedAddressId, selectedFreight), {
                loading: "Salvando os dados da entrega",
                success: "Pronto!",
                error: "Não foi possível salvar os dados do endereço"
            }).then(() => {
                setTimeout(() => {
                    window.location.href = "/pagamento";
                }, 1500)
            });

        } else {
            toast.error("Selecione o frete.");
        }
    }

    const handleDeleteAddress = addressId => {
        setDeletedAddressId(addressId);
        setShowDeleteAddressModal(true)
    }

    const deleteCustomerAddressbyId = () => {
        toast.promise(CustomerService.deleteAddressById(deletedAddressId), {
            loading: 'Excluindo endereço...',
            success: 'Endereço excluido!',
            error: 'Não foi possível excluir o endereço'
        }).then(() => {
            const updatedAddressesList = addresses.filter(address => address?.id !== deletedAddressId);
            setAddresses(updatedAddressesList);
        }).catch(setShowDeleteAddressModal(false));
    }

    return(
        <>
            {loading ?
                <div className="container-lds"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            :
                <>
                    <ModalWarning message="Deseja realmente excluir esse endereço?" buttonMessage="Excluir endereço" callbackFunction={deleteCustomerAddressbyId} showModal={showDeleteAddressModal}  setShowModal={setShowDeleteAddressModal}/>
                    <main className="main-container">
                        <div className="adress-main-content">
                            <ButtonBackAnimate link={'/carrinho'} buttonText="Voltar para o carrinho" />
                            <ModalAdress showModal={showCreateNewAddressModal}  setShowModal={setShowCreateNewAddressModal} updateAddressesList={handleCreateCustomerAddress}/>
                            <div className='container-modal-select-adress'>
                              <span className='title-modal-select-adress'>Escolha seu endereço de entrega</span>
                                {addresses?.map(address => (
                                    <div className="card-adress-container"  key={address?.id}>
                                        <a className="select-adress  d-flex" onClick={() => handleUpdateSelectedAddress(address)} style={{width:'100%'}} >
                                            <label htmlFor="option-1" className={`option ${selectedAddress?.id === address?.id && "select"} card-adress`} >
                                            <div className="clicked-area"></div>
                                                <div className="dot"></div>
                                                <div className="address-data">
                                                    <span>{address?.address_name} {address?.is_default && <b>(Endereço padrão)</b>}</span>
                                                    <span>Endereço: {address?.street} - nº {address?.number} - cep: {address?.zipcode}</span>
                                                    <span>Bairro: {address?.district} - Estado: {address?.city} - {address?.state}</span>
                                                    {address?.complement && <span>Complemento: {address?.complement}</span>}
                                                    {address?.reference && <span>Reference: {address?.reference}</span>}
                                                </div>
                                            </label>
                                        </a>
                                        <button className="btn-delete-address" onClick={() => handleDeleteAddress(address?.id)}>
                                            <Icon icon="ph:trash-bold" width="18px"/>
                                        </button>
                                    </div>
                                ))}
                                <div className="row-extra-form mt-25 add-adress" style={{width: 'fit-content'}} onClick={() => setShowCreateNewAddressModal(true)}>
                                    <span>
                                        <Icon icon="material-symbols:add" />
                                        <span>
                                            ADICIONAR UM NOVO ENDEREÇO
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {freightData?.length > 0 && 
                                <div className="freight-container">
                                    <div className="freight-title">
                                        <h2>Frete</h2>
                                    </div>
                                    {freightData?.map(freight => (
                                        <a className="select-adress" key={freight?.codigo} onClick={() => handleUpdateSelectedFreight(freight)}>
                                            <label htmlFor="option-1" className={`option ${selectedFreight?.codigo === freight?.codigo && "select"}`}>
                                            <div className="clicked-area"></div>
                                                <div className="dot" ></div>
                                                <div className="address-data">
                                                    <span>Serviço: {freight?.servico?.replace('.', ',')}</span>
                                                    <span>Prazo: {freight?.prazo} Dia{freight?.prazo > 1 && "s"}</span>
                                                    <span>Valor: R$ {freight?.valor?.toFixed(2)?.replace('.', ',')}</span>
                                                </div>
                                            </label>
                                        </a>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="order-resume-content">
                            <div className="order-resume">
                                <h4>Resumo da Entrega</h4>
                                <div className="d-flex flex-column">
                                    <span>Pedido: <b>R$ {cartContext?.totalPrice?.replace('.', ',')}</b></span>
                                    {Object.keys(selectedFreight)?.length > 0 && <span>Frete: <b>R$ {selectedFreight?.valor?.toFixed(2)?.replace('.', ',')}</b></span>}
                                    {cartContext?.totalWithDiscounts > 0 && <span>Descontos: <b>R$ {Math.round(cartContext?.totalPrice - cartContext?.totalWithDiscounts).toString()?.replace('.', ',')}</b></span>}
                                    <span>Total: <b>R$ {calculateCartTotal()}</b></span>
                                </div>
                                <button className='btn-confirm-adress' onClick={handleSaveFreightData}>
                                Prosseguir para o pagamento
                            </button>
                            </div>
                        </div>
                   </main>                
                </>
            }
        </>
    )
}

export default SelectAddress;