import './index.css'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom';

const Order = ({ listOrder }) => {
   
    const getImageUrl = imageData => {
        const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
        const imagePath = imageData?.path;
        const imageName = imageData?.name;
        const imageUrl = imageBaseUrl + imagePath + imageName;

        return imageUrl;
    }

    function setStatus(status){
        switch(status){
            case 'Aguardando pagamento':
                return '#d11313'
            case 'Pagamento realizado':
                return '#bd6128'
            case 'Pagamento aprovado':
                return '#d3d615'
            case 'Em separação':
                return '#999999'
            case 'Produtos enviados':
                return '#426bff'
            case 'Compra finalizada':
                return '#2bd611'
        }
    }

    const date = new Date (listOrder.created_at)
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false, timeZone: 'America/Sao_Paulo'};

    function calculateOrderTotal(totalPrice, totalWithDiscounts, freight) {
        if(!totalPrice) return; 

        const cartTotal = parseFloat(totalPrice);
        const cartTotalWithDiscounts =  parseFloat(totalWithDiscounts);
        const freightValue = parseFloat(freight);
 
        if(cartTotalWithDiscounts > 0) {
            return (cartTotalWithDiscounts + freightValue)?.toFixed(2)?.replace('.', ',');
        } else {
            return (cartTotal + freightValue)?.toFixed(2)?.replace('.', ',');
        }
    }

    return(
        <div className="order">
            <div className="top-side-order">
                <div className="grid-image">
                    {listOrder?.order_product.length === 3 &&
                        <>
                            <div className="left-grid"><img src={getImageUrl(listOrder?.order_product[0]?.product_image[0])} alt="" /></div>
                            <div className="right-one"><img src={getImageUrl(listOrder?.order_product[1]?.product_image[0])} alt="" /></div>
                            <div className="right-two"><img src={getImageUrl(listOrder?.order_product[2]?.product_image[0])} alt="" /></div>
                        </>
                    }
                    {listOrder?.order_product.length !== 3 &&
                        <>
                            <div className="default-grid"><img src={getImageUrl(listOrder?.order_product[0]?.product_image[0])} alt="" /></div>
                        </>
                    }
                </div>
                <div className="resume-order-area">
                    <span>Resumo do pedido</span>
                    <span className="resume-order">
                        {listOrder.order_product.map(product => {
                            return product.product_data.Nome_Produto
                        }).join().substring(0,80)+'...'}
                    </span>
                </div>
                <div className="price-order-area">
                    <span>Preço</span>
                    <span className="price-order">R$ {calculateOrderTotal(listOrder?.total, listOrder?.total_with_discounts, listOrder?.freight?.freight_value)} </span>
                </div>
                <div className="status-area">
                    <span>Status</span>
                    <div className="status">
                        <Icon icon="fluent-mdl2:status-circle-inner" color={setStatus(listOrder?.order_status?.status_name)}/> 
                        <span>{listOrder?.order_status?.status_name}</span>
                    </div>
                </div>
                <button>
                    <Link to={`/pedidos/${listOrder.id}/detalhes`} style={{color: 'white'}}> Ver Pedido</Link>
                </button>
            </div>
            <div className="bot-side-order">
                <div className="box-bot-order">
                    <div className="id-order">
                        <span>Pedido : #{listOrder.id}</span>
                    </div>
                    <div className="date-order">
                        <span>{new Intl.DateTimeFormat('pt-BR', options).format(date)}</span>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Order;