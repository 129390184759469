import './index.css'
import Header from '../../components/Header';
import { Icon } from '@iconify/react';
import '../Web/Checkout/index.css'
import { useState, useEffect, useContext } from 'react';
import { Row } from 'reactstrap';
import AuthContext from '../../context/auth/AuthContext';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput';
import {motion} from 'framer-motion/dist/framer-motion';
import CustomerService from '../../services/CustomerService';
import toast from 'react-hot-toast';
import ModalAdress from '../../components/ModalAdress';
import ModalWarning from '../../components/Modals/ModalWarning';

const spring = {
    type: "spring",     
    stiffness: 700,
    damping: 30
};

const PersonalInformation = (props) => {
    const authContext = useContext(AuthContext);

    const [modalAdress, setModalAdress] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletedAddressId, setDeletedAddressId] = useState(null);
    const [failedFields, setFailedFields] = useState([]);
    const [updatedUserData, setUpdatedUserData] = useState({});
    const [showAddressesCollapse, setShowAddressesCollapse] = useState([])
    const [customerAddress, setCustomerAddress] = useState([]);
    const [customerData, setCustomerData] = useState({
        user_firstname: "",
        user_lastname: "",
        birth_date: "",
        telephone: "",
        email: "",
        password: "",
        password_confirmation: "",
        cep: "",
        cpf: "",
        cnpj: "",
        cnpj_social_reason: "",
        cnpj_fantasy_name: "",
        cnpj_state_inscription: "",
        is_physical_person: true

    });


    useEffect(() => {
        getCustomerData();
    }, []);

    const getCustomerData = async () => {
        const customerId = authContext.user.id;
        
        CustomerService.getUserById(customerId).then(response => {
            const {customer, address} = response.data;

            const birthDate = new Date(customer?.birth_date).toISOString();
            const fixedDate = birthDate.slice(0, birthDate.indexOf("T"));

            const ddd = customer?.telephone?.toString()?.slice(0, 2)
            const telephone = customer?.telephone?.toString()?.slice(2);
            const telephoneWithDDD = `(${ddd})${telephone}`;

            const isPhysicalPerson = customer?.cpf ? true : false;

            setCustomerAddress(address);

            setCustomerData({
                 ...customer, 
                birth_date: fixedDate,
                ddd,
                telephone: telephoneWithDDD,
                is_physical_person: isPhysicalPerson
            });
        });

    }

    const handleUserDataChange = (inputKey, inputValue) => {
        setCustomerData(prevState => {
            return {
                ...prevState,
                [inputKey]: inputValue
            }
        });

        insertValueInUpdatedUserData(inputKey, inputValue);
    }



    const handleUserAddressChange = (address, event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;

        address[inputName] = inputValue;
        setCustomerAddress([...customerAddress])

    }

    const insertValueInUpdatedUserData = (inputKey, inputValue) => {
        setUpdatedUserData(prevState => {
            return {
                ...prevState,
                [inputKey]: inputValue
            }
        })
    }
    
    
    const handleCustomerUpdate = event => {
        event.preventDefault();
        const userId = authContext.user.id;

        toast.promise(CustomerService.updateCustomerById(userId, updatedUserData), {
            loading: "Atualizando usuário",
            success: "Usuário atualizado",
            error: "Não foi possível atualizar o usuário"
        }).then(() => {
            setTimeout(() => {
                // props.history.push("/");
            }, 1500)
        }).catch(response => {
            if(response?.failed_fields) {
                setFailedFields(response?.failed_fields);
            } 
        });
    }

    const handleZipCodeAutoComplete = async (address) => {
        fetch(`https://viacep.com.br/ws/${address.zipcode}/json`)
        .then(response => {
            return response.json();
        })
        .then(data => {
            address['district'] = data?.bairro;
            address['street'] = data?.logradouro;
            address['city'] = data?.localidade;
            address['state'] = data?.uf;

            setCustomerAddress([...customerAddress]);
        })
        .catch(() => {
            toast.error('Cep inválido.')
        });
    }

    const handleAddressUpdate = addressData => {
        toast.promise(CustomerService.updateCustomerAddress(addressData), {
            loading: "Atualizando endereço",
            success: "Endereço atualizado com sucesso!",
            error: "Não foi possível atualizar o endereço"
        });
    }

    const handleShowAddressesCollapse = addressId => {
        const addressAlreadyOpen = showAddressesCollapse.includes(addressId);

        if (addressAlreadyOpen) {
            const listWithRemovedItem = showAddressesCollapse.filter(value => value !== addressId);
            setShowAddressesCollapse(listWithRemovedItem);
        } else {
            const updatedOpenCollapsesList = showAddressesCollapse.concat(addressId);
            setShowAddressesCollapse(updatedOpenCollapsesList);
        }
    }

    const updateAdressesList = () => {
        toast.promise(getCustomerData(), {
            loading: 'Carregando...',
            success: 'Usuário carregado com sucesso',
            error: 'Não foi possível carregar o usuário'
        })
    }

    const handleSetAddressDefault = (address) => {
        const customerId = authContext.user.id;
        const addressId = address?.id;
        const isDefault = address?.is_default;

        toast.promise(CustomerService.setAddressAsDefault(customerId, addressId, isDefault), {
            loading: 'Tornando endereço como padrão',
            success: 'Endereço atualizado com sucesso',
            error: 'Não foi possível atualizar o endereço'
        }).then(() => {
            const updatedDefaultAddresses = customerAddress.map(address => {
                const newDefaultAddress = address?.id === addressId;
                if(newDefaultAddress) {
                    address['is_default'] = true;
                }

                const oldDefaultAddress = address?.is_default === true && address?.id !== addressId
                if(oldDefaultAddress) {
                    address['is_default'] = false;
                }
    
                return address;
            });
    
            setCustomerAddress(updatedDefaultAddresses);
        });
    }

    const handleDeleteCustomerAddress = addressId => {
        setShowDeleteModal(true);
        setDeletedAddressId(addressId);
    }

    const deleteCustomerAddressbyId = () => {
        toast.promise(CustomerService.deleteAddressById(deletedAddressId), {
            loading: 'Excluindo endereço...',
            success: 'Endereço excluido!',
            error: 'Não foi possível excluir o endereço'
        }).then(() => {
            const updatedAddressesList = customerAddress.filter(address => address?.id !== deletedAddressId);
            setCustomerAddress(updatedAddressesList)

            setDeletedAddressId(null);
            setShowDeleteModal(false);
        }).catch(setShowDeleteModal(false));
    }
    
    return(
        <div className="personal-container">
            <ModalAdress showModal={modalAdress}  setShowModal={setModalAdress} updateAddressesList={updateAdressesList}/>
            <ModalWarning message="Deseja realmente excluir esse endereço?" buttonMessage="Excluir endereço" callbackFunction={deleteCustomerAddressbyId} showModal={showDeleteModal}  setShowModal={setShowDeleteModal}/>
            <Header/>
            <span className="title-personal-container"><Icon icon="healthicons:ui-user-profile" color="#9d7fad" height="64px" width="64px"/> <span>Informações Pessoais</span> </span>
            <div className="info-form-area">
                <Row>
                    <span className='init-form'>Informações Pessoais</span>
                    <div className="form-row column">
                        <label htmlFor="name">Nome</label>
                        <input value={customerData?.user_firstname} className={`inputs-form-checkout ${failedFields.includes('user_firstname') && "failed-input"}`}  type="text" id="name" name="name" onChange={event => handleUserDataChange('user_firstname', event.target.value)} />
                        <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                    </div>
                    <div className="form-row inputs-checkouts column">
                        <label htmlFor="lastname">Sobrenome</label>
                        <input value={customerData?.user_lastname} className={`inputs-form-checkout ${failedFields.includes('user_lastname') && "failed-input"}`} type="text" id="lastname" name="lastname" icon="icon-park-outline:edit-name" onChange={event => handleUserDataChange('user_lastname', event.target.value)}/>
                        <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                    </div>
                </Row>
                <Row className="mb-3">
                    <div className={`form-row inputs-checkouts column ${customerData?.is_physical_person ? "fr-3" : "fr-3"}`}>
                        <label htmlFor="date_birth">Data de Nascimento</label>
                        <input value={customerData?.birth_date} className={`inputs-form-checkout ${failedFields.includes('birth_date') && "failed-input"}`} type="date" id="date_birth" name="date_of_birth" min="1900-01-01" onChange={event =>{ handleUserDataChange('birth_date', event.target.value)}}/>
                    </div>
                    <div className={`form-row inputs-checkouts column ${customerData?.is_physical_person ? "fr-3" : "fr-3"}`} style={{width: '51%'}}>
                        <label htmlFor="tel">Telefone</label>
                         <TelefoneBrasileiroInput
                            id="tel"
                            temDDD={true}
                            className={`inputs-form-checkout ${failedFields.includes('telephone') && "failed-input"}`}
                            value={customerData?.telephone}
                            placeholder="Telefone"
                            onChange={event => handleUserDataChange('telephone',  event.target.value)}
                        />
                        <Icon className='icon-input' icon="ic:baseline-phone" color="rgba(0, 0, 0, 0.324)" />
                    </div>
                    {customerData.is_physical_person &&
                        <div className="form-row inputs-checkouts column fr-2" style={{display: `${customerData?.is_physical_person ? "flex" : "none"}`}}>
                            <label htmlFor="tel">CPF</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('cpf') && "failed-input"}`} type="text" id='cpf' name='cpf' value={customerData?.cpf}   onChange={event => handleUserDataChange('cpf', event.target.value)} style={{borderColor: `${failedFields.includes('cpf') && "red"}`}}/>
                            <Icon className='icon-input' icon="bi:people-fill" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    }
                </Row>
                {!customerData.is_physical_person > 0 &&  
                    <>
                        <Row style={{display: "flex"}}>
                            <div className="form-row inputs-checkouts  column">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('cnpj') && "failed-input"}`}  type="text" id='cnpj' name='cnpj' value={customerData?.cnpj ? customerData?.cnpj : ''} onChange={event => handleUserDataChange('cnpj', event.target.value)} />
                                <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                            <div className="form-row inputs-checkouts  column">
                                <label htmlFor="fantasy_name">Nome Fantasia</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('fantasy_name') && "failed-input"}`} type="text" id="fantasy_name" name='fantasy_name' value={customerData?.cnpj_fantasy_name ? customerData?.cnpj_fantasy_name : ''} onChange={event => handleUserDataChange('cnpj_fantasy_name', event.target.value)} />
                                <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                        </Row>
                        <Row style={{display: "flex"}}>
                            <div className="form-row inputs-checkouts  column">
                                <label htmlFor="corporate_name">Razão social</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('social_reason') && "failed-input"}`} type="text" placeholder="Razão Social" id="corporate_name" name='corporateName' value={customerData?.cnpj_social_reason ? customerData?.cnpj_social_reason : ''} onChange={event => handleUserDataChange('cnpj_social_reason', event.target.value)} />
                                <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                            <div className="form-row inputs-checkouts  column">
                                <label htmlFor="state_registration">Ins. Estadual</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('state_inscription') && "failed-input"}`} type="text" placeholder="Ins. Estadual" id="state_registration" name='stateRegistration' value={customerData?.cnpj_state_inscription ? customerData?.cnpj_state_inscription : ''} onChange={event => handleUserDataChange('cnpj_state_inscription', event.target.value)} />
                                <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                        </Row>
                    </>
                }
                <button type='submit' className='finish-signup my-5'  onClick={handleCustomerUpdate}> Salvar </button>

                <Row>
                    <span className='init-form'>Endereço</span>
                </Row>
                {customerAddress?.map(address => (
                    <div key={address.id} className={`box-adress mb-20 ${showAddressesCollapse?.includes(address.id) && 'active'}`}>
                        <span className='init-box-adress'>
                            <p className="m-0">
                                {address?.address_name ? address?.address_name : 'Endereço sem nome'}
                                <b>{address?.is_default && ' (Endereço padrão)'}</b>
                            </p>
                            <Icon className="cursor-pointer" onClick={(e)=> handleShowAddressesCollapse(address.id)} icon="material-symbols:arrow-drop-down-rounded" width="42px" color="#676767" />
                        </span>
                        <div className="content-box-adress">
                            <Row className="d-flex align-items-center">
                                <div className="form-row inputs-checkouts  column fr-2">
                                    <label htmlFor="cep">Nome do endereço</label>
                                    <input value={address?.address_name ? address?.address_name : ''} className={`inputs-form-checkout ${failedFields.includes('address_name') && "failed-input"}`} type="text"  id="cep" name='address_name' onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                            </Row>
                            <Row className="d-flex align-items-center">
                                <div className="form-row inputs-checkouts  column fr-2">
                                    <label htmlFor="cep">CEP</label>
                                    <input value={address?.zipcode} className={`inputs-form-checkout ${failedFields.includes('zipcode') && "failed-input"}`} type="text"  id="cep" name='zipcode' onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                                {address?.zipcode?.length > 0 &&
                                    <div className="form-row inputs-checkouts column fr-2">
                                        <button type="button" className="verify-cep" onClick={() => handleZipCodeAutoComplete(address)}> Verificar cep </button>
                                    </div>                       
                                }
                            </Row>
                            <Row>
                                <div className="form-row inputs-checkouts  column fr-1">
                                <label htmlFor="adress">Endereço</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('street') && "failed-input"}`} type="text" id="adress" name='street' value={address?.street} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="ri:home-5-fill" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                            </Row>
                            <Row>
                                <div className="form-row inputs-checkouts  column fr-2">
                                <label htmlFor="adress_number">Número</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('number') && "failed-input"}`} type="text"  id="adress_number" name='number' value={address?.number} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                                <div className="form-row inputs-checkouts column fr-2">
                                <label htmlFor="customerAddress_district">Bairro</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('district') && "failed-input"}`} type="text"  id="customerAddress_district" name='district' value={address?.district} onChange={event => handleUserAddressChange(address, event.target.value)} />
                                    <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                                </div>                 
                            </Row>
                            <Row>

                                <div className="form-row inputs-checkouts  column fr-2">
                                <label htmlFor="complete">Município</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('city') && "failed-input"}`} type="text" id="complete" name='city' value={address?.city} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                                <div className="form-row inputs-checkouts  column fr-2">
                                <label htmlFor="address_state"> UF </label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('state') && "failed-input"}`} type="text" id="address_state" name='state' value={address?.state} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                                </div>
                        
                            </Row>
                            <Row>
                                <div className="form-row inputs-checkouts  column fr-3">
                                <label htmlFor="complete">Complemento</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('complement') && "failed-input"}`} type="text" id="complete" name='complement' value={address?.complement} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                                </div>  
                                <div className="form-row inputs-checkouts  column fr-3">
                                <label htmlFor="address_reference">Ponto de Referência</label>
                                    <input className={`inputs-form-checkout ${failedFields.includes('reference') && "failed-input"}`} type="text" id="address_reference" name='reference' value={address?.reference} onChange={event => handleUserAddressChange(address, event)} />
                                    <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                                    
                                </div>  
                            </Row>
                            <Row>
                                <button type='submit' className='finish-signup mt-20 ml-15 px-5'  onClick={() => handleAddressUpdate(address)} > Salvar Endereço</button>
                                <button type='submit' className='finish-signup mt-20 ml-15 px-5'  onClick={() => handleDeleteCustomerAddress(address?.id)} > Excluír Endereço</button>
                                {address?.is_default === false &&
                                    <button type='submit' className='finish-signup mt-20 px-5'  onClick={() => handleSetAddressDefault(address)} style={{marginLeft: '15px'}}> Tornar Esse o Endereço Padrão </button>
                                }
                            </Row>
                        </div>
                    </div>
                ))}
                    
                <div className="extra-form">
                    <div className="row-extra-form">
                        <span onClick={()=> setModalAdress(true)}>
                            <Icon icon="material-symbols:add" />
                            <span>
                                ADICIONAR UM NOVO ENDEREÇO
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PersonalInformation;