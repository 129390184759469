import {React, useState, useEffect, useContext} from "react";
import './index.css'

import { Link, useHistory } from "react-router-dom";
import ImageService from '../../services/ImageService';
import SideBar from "./SideBar";
import CartContext from "../../context/cart/cartContext";
import CartSideBar from "./CartSideBar";
import Profile from "../Profile";
import MenuDepartaments from "../MenuDepartaments";
import Menu from "../Menu";
import { Icon } from "@iconify/react";
import ProductService from "../../services/ProductService";
import NewMenu from "../NewMenu";



const Header = () => {
    const history = useHistory();
    const cartContext = useContext(CartContext);

    const [logo, setLogo] = useState('');
    const [show, setShow] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [productSearch, setProductSearch] = useState("");
    const [showDepartments, setShowDepartments] = useState(false);
    const [showMenu,setShowMenu] = useState(false);
    const [showCart,setShowCart] = useState(false);

    useEffect(() => {
        getActiveLogo();
        getDepartments();
    }, []);

    useEffect(()=>{
        if(cartContext?.productsCount < 1){
            setShowCart(false)
        }
    },[cartContext?.productsCount])

    const getActiveLogo = () => {
        ImageService.getActiveLogoPath().then((data) => {
            setLogo(data);
        });
    }

    const getDepartments = () => {
        ProductService.getDepartments().then(response => {
            setDepartments(response);
        }).finally(() => setShowDepartments(true));
    }

    const handleProductSearch = event => {
        event.preventDefault();

        history.push(`/busca/${productSearch}`);
    }

    return(
        <div className="header">
            <div className="search-mobile">
                <input type="text" name="search_mobile" id="search_mobile" placeholder="Pesquise algo"/>
                <Icon icon="uil:search" className="icon-search"/>
            </div>
            <div className="header-container">
                <Link to={`/`}>
                    <img src={logo} className="logo-header" alt="" />
                </Link>
                <ul className="menus">
                    <li><Link to={'/'}>HOME</Link> </li>
                    <li><Link to={'/blog/externo'}>BLOG</Link></li>
                    <li><Link to={'/sobre-nos'}>SOBRE NÓS</Link></li>
                </ul>
                <div className="search">
                    <form className="search-area" onSubmit={event => handleProductSearch(event)}>
                        <button type="submit" className="btn-search" >
                            <Icon icon="uil:search" height="32px" width="32px" />
                        </button>
                            <input 
                                id="search" 
                                type="text"
                                name="search" 
                                placeholder="Pesquisar produtos" 
                                className="search-input" 
                                onChange={event => setProductSearch(event.target.value)}
                            />
                    </form>
                </div>
                <Profile setShowMenu={setShowMenu}/>
                <div className="extra-area-header">
                    <div className="cart" onClick={()=>{ cartContext.productsCount && setShowCart(true)}}>
                        <Icon icon="ph:shopping-cart-simple-fill" height="24px" width="24px" color="61496D" />
                        <h6>Carrinho</h6>
                        <h5>{cartContext?.productsCount}</h5>
                    </div>
                    <div className="cart mobile" onClick={()=>{ cartContext.productsCount && setShowCart(true)}}>
                        <Icon icon="ph:shopping-cart-simple-fill" height="24px" width="24px" color="61496D" />
                        <h5>{cartContext?.productsCount}</h5>
                    </div>
                    <div className="menu-toggle" onClick={()=>{ setShowMenu(true); }}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
              
                <SideBar departments={departments} showMenu={showMenu} setShowMenu={setShowMenu}/>
                <CartSideBar show={showCart} setShow={setShowCart}/>
            </div>
            {showDepartments && <NewMenu departments={departments}/>}
        </div>
    );
}

export default Header;