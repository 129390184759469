import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Components
import Header from "../../../components/Header";
import BannerIndex from "./Banner/Index";
import FeaturedProducts from "./FeaturedProducts";
import SliderProductsCatalog from "./SliderProductsCatalog";
import ResumeBenefits from "./ResumeBenefits";
import FeaturedDepartaments from "./FeaturedDepartaments";
import SliderDepartaments from "./SliderDepartaments";
import TestimonialIndex from "../../../components/Testimonial/Index"
import Footer from "./Footer";
import SpecialSection from "../../../components/SpecialSection";
import BlogLatest from "../Home/Partials/Blog/Latest";
import SocialMedia from "../../../components/SocialMedia";
import Newsletter from "../../../components/Newsletter";
import Testimonial from "../../../components/TestTestimonial";
import ButtonBackToTop from "../../../components/ButtonBackToTop";
import LoginModal from "../../../components/LoginModal";
import '../../../assets/css/responsive/responsive-global-home.css'
const HomePage = () => {
    const currentUrl = useLocation();
    
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [redirectUrlAfterLogin, setRedirectUrlAfterLogin] = useState("/");    

    useEffect(() => {
        updateLoginModalRedirectUrl();
    }, []);

    const updateLoginModalRedirectUrl = () => {
        const urlParameters = new URLSearchParams(currentUrl?.search);
        const redirectUrl = urlParameters?.get("redirect_url")
        
        if(redirectUrl) {
            setShowLoginModal(true);
            setRedirectUrlAfterLogin(redirectUrl);
        }
    }

    return(
        <div className="homepage">
            <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} redirectUrl={redirectUrlAfterLogin} />
            <Header/> 
            <BannerIndex/>
            <FeaturedProducts/>
            <SliderProductsCatalog/>
            <SpecialSection/>
            <FeaturedDepartaments/>
            <SliderDepartaments/>
            <Testimonial/>
            {/* <BlogLatest /> */}
            {/* <Newsletter/> */}
            <SocialMedia/>
            <Footer/>
            <ButtonBackToTop/>
        </div>
    );
}

export default HomePage;