import React, { Component, useEffect, useState, useContext } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import ResumeBenefits from '../HomePage/ResumeBenefits';
import NewsletterIndex from '../Home/Partials/Newsletter/Index';
import SocialIndex from '../Home/Partials/Social/Index';
import Footer from '../HomePage/Footer';
import Header from '../../../components/Header';
import LoaderIndex from '../Layout/Loader/Index';
import ProductBannerIndex from './Partials/Banner/Index';
import { FaStar, FaFacebook, FaTwitter } from 'react-icons/fa';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import ProductService from '../../../services/ProductService';
import { Button } from 'reactstrap';
import toast from 'react-hot-toast';
import stars from '../../../assets/img/stars.png'
import { Icon } from '@iconify/react';
import CartContext from '../../../context/cart/cartContext';
import AuthContext from '../../../context/auth/AuthContext';
import WishlistContext from '../../../context/wishlist/wishlistContext';
import { Link } from 'react-router-dom';
import WishlistService from '../../../services/WishlistService';
import FreightService from '../../../services/FreightService';
import AliceCarousel from 'react-alice-carousel';
import ProductCard from '../../../components/ProductCardComponent';
import CarouselItems from '../../../components/CarouselItems';

const path = process.env.REACT_APP_IMAGE_BASE_URL;
const imageNotFound = 'https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg';

function Example1(props) {
    const id = parseInt(props.match.params.external_code);
    
    const cartContext = useContext(CartContext);
    const authContext = useContext(AuthContext);
    const wishlistContext = useContext(WishlistContext);

    const [amount, setAmount] = useState(1);
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false);
    const [freightData, setFreightData] = useState([]);
    const [selectedSize, setSelectedSize] = useState(0);
    const [variantsFilter, setVariantsFilter] = useState("");
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [oftenPurshasedProducts, setOftenPurshasedProducts] = useState([]);

    useEffect(() => {
        getProductById();
        getRelatedProducts();
        getOftenPurshasedProducts()
        productIsInsertedOnWishlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const productIsInsertedOnWishlist = () => {
        const wishlistExists = localStorage.hasOwnProperty("wishlist-products");
        
        if(wishlistExists) {
            const wishlistProducts = JSON.parse(localStorage.getItem("wishlist-products"));
            const productAlreadyInserted = wishlistProducts.includes(id);

            const isUserLoggedIn = authContext.isAuthenticated;
            if(productAlreadyInserted && isUserLoggedIn) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false)
            }
        }
    }

    const getRelatedProducts = () => {
        const productId = props.match.params.external_code;
        
        ProductService.getRelatedProducts(productId)
        .then(response => {
            const relatedProducts = response?.data;
            setRelatedProducts(relatedProducts);
        }); 
    }

    const getOftenPurshasedProducts = () => {
        ProductService.getOftenPurshasedProducts()
        .then(response => {
            const oftenPurshasedProducts = response?.data;
            setOftenPurshasedProducts(oftenPurshasedProducts)
        })
    }

    const handleInsertProductInWishlist = () => {
        if (!authContext.isAuthenticated) {
            toast.error('Faça seu login para adicionar o produto na lista de desejos.');
            return;
        }

        const userId = authContext?.user?.id;
        const loadingMessage = toast.loading("Atualizando lista de desejos.");

        WishlistService.insertProduct(userId, id)
        .then(response => {
            const productData = response.data;
            const productWasInserted = response.status === 201;

            if(productWasInserted) {
                toast.remove(loadingMessage);
                toast.success("Produto inserido na lista de desejos.");
                
                setIsFavorite(true);
                wishlistContext.insertProduct(productData);
            } else {
                setIsFavorite(false);
                toast.success("Produto removido da lista de desejos.");
                wishlistContext.removeProduct(id);
            }
            
            toast.remove(loadingMessage);
        });
    }
    

    const getProductById = () => {
        ProductService.getProductById(id).then((data) => {
            let productImage = path + data?.image?.path + data?.image?.name;

            setProduct({
                ...data,
                default_product_image: data.image ? productImage : imageNotFound
            });

            setLoading(false);
        });
    }

    const getSelectedSizePrice = () => {
        const size = product?.product_sizes?.find(size => {
            if(size?.CodigoInterno_ProEmb === selectedSize) {
                return size;
            }
        });   

        return size?.PrecoVendaECommercePraticado_ProEmpEmbPre?.replace('.',',');
    }

    const handleCalculateFreight = event => {
        event.preventDefault();

        const productId = props.match.params.external_code;
        const cepValue = event.target.cep.value;
        
        if(cepValue.length > 7) {
            toast.promise(FreightService.calculateProductFreight(cepValue, productId, amount), {
                loading: "Calculando frete...",
                success: "Pronto!",
                error: "Não foi possível calcular o frete."
            }).then(response => {
                const freightData = response?.data;
                setFreightData(freightData);
            });
        } else {
            toast.error('Cep inválido!');
        }
    }

    return (
        <div className="wrapper home-default-wrapper">
            {loading ?
                <LoaderIndex />
                :
                <>
                    <Header />
                    <main className="main-content">
                        <ProductBannerIndex productData={product} />
                        <section className="product-area product-single-area">
                            <div className="container pt-60 pb-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-single-item" data-margin-bottom="62">
                                            <div className="row">
                                                <div className="col-lg-3" >
                                                    {product?.has_variants &&
                                                        <div className="shop-sidebar-wrapper">
                                                            <div className="sidebar-item">
                                                                <h4 className="sidebar-title mb-45">Filtrar variantes</h4>
                                                            </div>
                                                            <div className="newsletter-content-wrap" style={{ marginTop: '-30px' }}>
                                                                <div className="newsletter-form">
                                                                    <input 
                                                                        type="text" 
                                                                        id="txtSearch" 
                                                                        autoComplete="off" 
                                                                        placeholder="Digite uma cor ou código" 
                                                                        className="form-control search-button" 
                                                                        onChange={event => setVariantsFilter(event.target.value)}
                                                                    />
                                                                    <button className="btn btn-submit" type="submit">
                                                                        <i className="icon-magnifier icon-search-head"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="group-pallete-color-img mt-3">
                                                                {/* <div className="option-pallete">
                                                                    {
                                                                        this.state.colors.map((color, index) => {
                                                                            return (
                                                                                <div key={index} className="ball-color-pallete" onClick={() => this.scrollToColor(color)} style={{ borderRadius: '50%', height: '15px', width: '15px', margin: '5px', backgroundColor: color.hex }} />
                                                                            )
                                                                        })
                                                                    }
                                                                </div> */}
                                                                <div className="scroll-itens-product" /* ref={this.colorContainerRef} */>
                                                                    <div className="itens-thumb">
                                                                        {/* {
                                                                            this.state.colors.map(color => {
                                                                                return (
                                                                                    color.children.filter(this.filterChild).map((child, index) => {
                                                                                        let image = this.getCoverImage(child) || '';

                                                                                        let validationImage = this.getExtension(image);
                                                                                        image = (validationImage) ? image : noPhoto;
                                                                                        return (
                                                                                            <>
                                                                                                <div className="item-product-line-modal" style={{ width: "100%" }} ref={index === 0 ? color.ref : null}>
                                                                                                    <img src={image} style={{ width: '100%', padding: '0 35px 0 35px', marginTop: '20px', cursor: 'pointer' }} onClick={this.changeDefaultImage(child, image)} alt={`Imagem ${child.Abreviacao_Cor} - ${child.Nome_Cor.substring(0, 35)}`} />
                                                                                                </div>
                                                                                                <div className="product-description" onClick={this.changeDefaultImage(child, image)}>
                                                                                                    <p style={{ color: '#000000', fontSize: '14px', fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}>{`${child.Abreviacao_Cor} - ${child.Nome_Cor.substring(0, 35)}`}</p>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                );
                                                                            })
                                                                        } */}
                                                                        {product?.variants?.map((variant, index) => {
                                                                            const variantName = variant?.Nome_Produto?.toLowerCase();
                                                                            const searchValue = variantsFilter?.toLowerCase();

                                                                            if(variantName?.includes(searchValue) && variant?.PrecoVendaECommercePraticado_ProEmpEmbPre > 0) {
                                                                                return(
                                                                                    <div key={index}>
                                                                                        <Link to={`/produto/${variant.CodigoInterno_Produto}/exemplo-1`}>
                                                                                            <div style={{ width: "100%" }} /* ref={index === 0 ? color.ref : null} */>
                                                                                                <img src={ variant?.image ? path + variant?.image?.path + variant?.image?.name : imageNotFound } style={{ width: '100%', padding: '20px 35px', marginTop: '20px', cursor: 'pointer', maxHeight: 230 }} /* onClick={this.changeDefaultImage(child, image)} */ alt={variant?.Nome_Produto} />
                                                                                            </div>
                                                                                            <div className="product-description" /* onClick={this.changeDefaultImage(child, image)} */>
                                                                                                <p style={{ color: '#000000', fontSize: '14px', fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}>{variant?.Nome_Produto}</p>
                                                                                            </div>
                                                                                            {variant?.is_available_in_stock === false && <span className='unavaliable-product-alert' > <Icon icon="iconamoon:unavailable" width="24px" color='red' /> Este produto nao está indisponível </span>}
                                                                                        </Link>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                
                                                <div className='col-md-5 fix-width'>
                                                    <div className='product-thumb'>
                                                        
                                                        <div className='main-product'>
                                                            <InnerImageZoom src={product?.default_product_image} zoomScale={2} zoomSrc={product?.default_product_image} moveType="pan" />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-4 mt-17 fix-width">
                                                    <div className="product-single-info">
                                                        <h1 className="title-product">{product?.Nome_Produto}</h1>
                                                        <div className="product-info">
                                                        <div className="ranking my-3">
                                                            {[...Array(5)].map((value, index) => {
                                                                return (
                                                                    <Icon 
                                                                        key={index} 
                                                                        width="25px" 
                                                                        color={((product?.product_rating?.average_rating - 1 >= index) ) ? "#FFD700" : "#CCCCCC"}
                                                                        icon="material-symbols:star-rounded" 
                                                                    />
                                                                );
                                                            })}

                                                            {product?.product_rating && 
                                                                <h3 className="ranking-number">
                                                                    {Number(product?.product_rating?.average_rating)?.toFixed(1)?.replace('.', ',')} ({product?.product_rating?.total_rating})
                                                                </h3>
                                                            }
                                                        </div>
                                                        <div>
                                                            {product?.size &&
                                                                <h5>
                                                                    Tamanho: {product?.size?.Nome_Tamanho}
                                                                </h5> 
                                                            }
                                                        </div>
                                                        {product?.product_sizes?.length > 1 &&
                                                            <div className='container-size-select flex gap-2'>
                                                                {product?.product_sizes?.map(size => {
                                                                    // if(size?.NomeReduzido_ProEmb?.length > 0) {
                                                                        return(
                                                                            <div 
                                                                                key={size?.CodigoInterno_ProEmb} 
                                                                                className={`button-size-select ${size?.CodigoInterno_ProEmb === selectedSize && 'selected'}`} 
                                                                                onClick={() => setSelectedSize(size?.CodigoInterno_ProEmb)}
                                                                            >
                                                                                {size?.NomeReduzido_ProEmb} ({size?.Nome_Embalagem})
                                                                            </div>
                                                                        )
                                                                    // }
                                                                })}
                                                            </div>
                                                        }  

                                                        {/*
                                                        <ul className="comments-advices">
                                                            <li><a href="#/" className="reviews"><i className="fa fa-commenting-o"></i>Read reviews (1)</a></li>
                                                            <li><a href="#/" className="comment"><i className="fa fa-pencil-square-o"></i>Write a review</a></li>
                                                        </ul>
                                                        */}
                                                        </div>
                                                        <div className="prices">
                                                            {selectedSize 
                                                                ? <span className="price">R$ {getSelectedSizePrice()}</span>
                                                                : <span className="price">R$ {product?.PrecoVendaECommercePraticado_ProEmpEmbPre.toFixed(2).replace('.',',')}</span>
                                                            }
                                                            {/* <div className="tax-label">Tax included</div> */}
                                                        </div>
                                                        {/* <div className="product-description">
                                                            <ul className="product-desc-list">
                                                                <p>Descrição curta do produto</p>
                                                                <p>
                                                                    <b>
                                                                        <span style={{ fontSize: '12px', textTransform: 'uppercase', color: '#656565' }}>
                                                                            <font size="4">DESCRIÇÃO</font>
                                                                        </span>
                                                                    </b>
                                                                </p>
                                                            </ul>
                                                        </div> */}
                                                        {!product.has_variants ?
                                                            <>
                                                                <div className="product-quick-action">
                                                                    <div className="product-quick-qty">
                                                                        <div className="pro-qty">
                                                                            <input type="text" title="Quantidade a ser comprada" value={amount || 1} onChange={(e) => setAmount(parseFloat(e.target.value || 1))}></input>
                                                                            <div className="inc qty-btn" onClick={() => setAmount(Math.min(amount + 1, 50))} >
                                                                                <i className="fa fa-angle-up"></i>
                                                                            </div>
                                                                            <div className="dec qty-btn" onClick={() => setAmount(Math.max(amount - 1, 1))} >
                                                                                <i className="fa fa-angle-down"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <a className="btn-product-add" href="#" onClick={() => this.addToCart(child)}>ADICIONAR AO CARRINHO</a> */}
                                                                    {/* <a className="btn-product-add" href="#">ADICIONAR AO CARRINHO</a> */}
                                                                    {product?.is_available_in_stock ? 
                                                                        <button className="add-to-cart" style={{maxWidth: 'max-content', paddingRight: '120px'}}onClick={() => cartContext.addProduct(product, amount)}>
                                                                            <Icon icon="carbon:add" width="24" height="24" color='white' /> Adicionar ao Carrinho
                                                                        </button>
                                                                        : <span className='unavaliable-product-alert' > <Icon icon="iconamoon:unavailable" width="24px" color='red' /> Este produto nao está indisponível </span>
                                                                    }
                                                                </div>
                                                                <div className="product-wishlist-compare">
                                                                    <Icon icon={`mdi:cards-heart${isFavorite ? "" : "-outline"}`} onClick={handleInsertProductInWishlist} className={`favorite ${isFavorite && "animate"}`} color="772AA0" width="24" height="24"/>Adicionar a lista de desejo
                                                                </div>
                                                            </>
                                                            :
                                                            <h5>* Apenas as variantes desse produto podem ser compradas </h5>
                                                        }

                                                        <div className="consult-freight-container w-75 ">
                                                            <div className="mb-30">
                                                                {freightData &&
                                                                    freightData?.map(freight => (
                                                                        <>
                                                                            <div key={freight?.codigo} className="freight-items">
                                                                                <span>{freight?.servico}</span>
                                                                                <div className="d-flex gap-3">
                                                                                    <h6>R$ {freight?.valor?.toFixed(2)?.replace('.', ',')}</h6>
                                                                                    <h6>Prazo ({freight?.prazo} Dias)</h6>
                                                                                </div>
                                                                            </div>
                                                                            {freight?.obs && <h6  className="mb-50 mt-5">* {freight?.obs}</h6>}
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>

                                                            <form onSubmit={event => handleCalculateFreight(event)}>
                                                                <div>
                                                                    <h5>Consultar frete e prazo de entrega</h5>
                                                                </div>
                                                                <div className="inputs-calcul-shipping mt-20">
                                                                    <input type="text" maxLength="9" placeholder="Digite seu CEP" name="cep" className="w-100" />
                                                                    <button type="submit">CALCULAR</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-41">
                                        <div className="product-review-tabs-content">
                                            <ul className="nav product-tab-nav " >
                                                <li role="presentation">
                                                    <h5 className='title-decoration'>Produtos relacionados</h5>
                                                </li>
                                            </ul>
                                                <CarouselItems items={relatedProducts} />
                                        </div>
                                    </div>
                                </div>
                                {oftenPurshasedProducts?.length > 0 && 
                                    <div className="row">
                                        <div className="col-12 mt-41">
                                            <div className="product-review-tabs-content">
                                                <ul className="nav product-tab-nav " >
                                                    <li role="presentation">
                                                        <h5 className='title-decoration'>Os clientes também compraram</h5>
                                                    </li>
                                                </ul>
                                                    <CarouselItems items={oftenPurshasedProducts} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-12 mt-41">
                                        <div className="product-review-tabs-content">
                                            <ul className="nav product-tab-nav" >
                                                <li role="presentation">
                                                    <h5 className='title-decoration'>DESCRIÇÃO</h5>
                                                </li>
                                            </ul>
                                            <div className="tab-content product-tab-content">
                                                <div className="product-description" dangerouslySetInnerHTML={{__html: product?.ComplementoCommerce_Produto}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section className="divider-area">
                            <div className="container pt-90 pt-lg-70 pb-lg-60">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="divider-style-wrap">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <SocialIndex />
                                                </div>
                                                {/* 
                                                <div className="col-md-6">
                                                    <NewsletterIndex />
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    {/* Footer Area Wrapper */}
                    <Footer />
                </>
            }
        </div>
    )
}

export default Example1