import api from "./api";

class OrderService {
    createOrder() {
        const response = api.post("/store/orders/create");
        return response;
    }

    getCustomerOrders(user, filters){
        const { filterByStatus, orderByCreationDate } = filters

        const response = api.get(`/store/orders/list?user_id=${user}&filter=${filterByStatus}&order_by=${orderByCreationDate}`);
        return response
    }

    getOrderDetails(orderId, customerId){
        const response = api.get(`/store/orders?order_id=${orderId}&customer_id=${customerId}`);
        return response
    }

    async getOrderStatuses() {
        const response = await api.get("/store/orders/status");
        return response.data;
    }

    async cancelOrder(orderId) {
        const response = await api.patch("/store/orders/cancel-order", { order_id: orderId });
        return response.data;
    }
}

export default new OrderService();