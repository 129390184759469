import './index.css'
import ProuctCardComponent from '../ProductCardComponent'
import { useEffect, useState } from 'react'

const CarouselItems = ({items}) => {

    const[marginLeft, setMarginLeft] = useState(0)
    const handleNext = (e) => {

        const componentWidth = document.querySelector('.product').clientWidth
        const productComponentMargin = getComputedStyle(document.querySelector('.margin-carousel'))
        const productMarginLeft = productComponentMargin.marginLeft
        const productMargiRight = productComponentMargin.marginRight
        const productWidthTotal = parseInt(componentWidth) + parseInt(productMarginLeft) + parseInt(productMargiRight)
        const containerWidth = items.length * productWidthTotal
        const containerLimit = productWidthTotal * 3

        if(marginLeft < containerWidth - containerLimit){
            setMarginLeft( marginLeft + productWidthTotal )
        }
       else{
            setMarginLeft(0)
       }
    
    }

    const handlePrevious = () => {
        const productComponentWidth = document.querySelector('.product').clientWidth
        const productComponentMargin = getComputedStyle(document.querySelector('.margin-carousel'))
        const productMarginLeft = productComponentMargin.marginLeft
        const productMargiRight = productComponentMargin.marginRight
        const productWidthTotal = parseInt(productComponentWidth) + parseInt(productMarginLeft) + parseInt(productMargiRight)
        const containerWidth = items.length * productWidthTotal
        const containerLimit = productWidthTotal * 3

        if(marginLeft > 0){
                setMarginLeft( marginLeft - productWidthTotal)
        }
       else{
                setMarginLeft(containerWidth - containerLimit)
        }
    }

    
    return <>
        <div className="carousel-item-container">
            <div className="carousel-item-content" id='carousel-products' style={{marginLeft:`-${marginLeft}px`}}>
                {items?.map((product)=>{
                    return(
                        <div className='margin-carousel'>
                            <ProuctCardComponent item={product}/>
                        </div>
                    )
                })}
                
            </div>
            <button className='next-btn-carousel' id="btn-next" onClick={(e)=> handleNext(e)}>&#10095;</button>
            <button className='previous-btn-carousel' id="btn-prev" onClick={(e)=>{ handlePrevious(e) }}>&#10094;</button>
        </div>
    </>
}

export default CarouselItems    