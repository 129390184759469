import BlogRecent from '../../../components/BlogRecent';
import BlogViews from '../../../components/BlogViews';
import Newsletter from '../../../components/Newsletter';
import SocialMedia from '../../../components/SocialMedia';
import Header from '../../../components/Header';
import Footer from '../HomePage/Footer'
import { useEffect,useState } from 'react';
import BlogService from '../../../services/BlogService';

function PostList(){
    const [mostRecentPosts, setMostRecentPosts] = useState([]);
    const [mostAccessedPosts, setMostAccessedPosts] = useState([]);

    useEffect(async ()=>{
        getMostRecentPosts();
        getMostAccessedPosts();
    }, []);
    
    const getMostAccessedPosts = async () => {        
        const postsAmount = 6;
        const posts =  await BlogService.getMostAccessedPosts(postsAmount)
        
        setMostAccessedPosts(posts); 
    }

    const getMostRecentPosts = async () => {
        const postsAmount = 3;
        const recentPosts = await BlogService.getMostRecentPosts(postsAmount);

        setMostRecentPosts(recentPosts);
    }

    return <>
        <Header />
        <BlogRecent posts={mostRecentPosts}/>
        <BlogViews  BlogData={mostAccessedPosts}/>
        {/* <Newsletter/> */}
        <SocialMedia/>
        <Footer/>
    </>
}

export default PostList;