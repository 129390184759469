import { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { Route, Switch } from "react-router-dom";
import PaymentMethod from "../../views/Web/Checkout/PaymentMethod";
import CreditCardMethod from "../..//views/Web/Checkout/CreditCardMethod"
import DebitCardMethod from "../..//views/Web/Checkout/DebitCardMethod"
import GiftCard from "../../views/Web/Checkout/GiftCardMethod";
import WishListPage from "../../views/Web/Wish";
import OrdersPage from "../../views/Web/OrdersPage";
import CartPage from "../../views/Web/CartPage";
import PersonalInformation from "../../views/PersonalInformation";
import SelectAddress from "../../views/Web/Checkout/SelectAddress";
import OrderDetails from "../../views/Web/OrdersPage/OrderDetails";

const ProtectedRoutes = () => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const redirectUser = true;
        authContext.getUserSession(redirectUser)
    }, []);

    if(authContext?.isAuthenticated === true) {
        return (
           <Switch>
                <Route exact path="/lista-de-desejos" component={WishListPage} />
                <Route exeact path="/carrinho/endereco" component={SelectAddress}/>
                <Route exact path="/pagamento" component={PaymentMethod}/>
                <Route exact path="/pagamento/cartao-de-credito" component={CreditCardMethod}/>
                <Route exact path="/pagamento/cartao-de-debito" component={DebitCardMethod}/>
                <Route exact path="/carrinho/checkout/payment-method/gift-card" component={GiftCard}/>
                <Route exact path="/informações-pessoais" component={PersonalInformation} />
                <Route exact path="/pedidos" component={OrdersPage} />
                <Route exact path="/pedidos/:order_id/detalhes/:product_to_rate?" component={OrderDetails} />
                <Route exact path="/carrinho" component={CartPage}/>
                <Route exact path="/informações-pessoais" component={PersonalInformation} />
           </Switch>
        );
    }

    return null;
}

export default ProtectedRoutes;