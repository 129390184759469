import './index.css'
import { Icon } from '@iconify/react'

const ButtonBackToTop = () => {

    const handleBackToTop = () => {
        window.scrollTo(0,0)

    } 

    return(
        <button className='button-back-to-top' onClick={handleBackToTop}>
            <Icon icon="material-symbols:arrow-upward-rounded" color="white" width="32" />
        </button>
    )
}

export default ButtonBackToTop