import React from "react";
import './index.css';

const GiftCard = () => {
    return(
        <div>
            
        </div>
    );
}

export default GiftCard;