import "./index.css";

import ImageNotice from "../../assets/img/trico.jpg";
import ReadTime from "../../assets/img/readTime.png";
import Twitter from "../../assets/img/twitterColors.png";
import Instagram from "../../assets/img/instaColors.png";
import Whatssap from "../../assets/img/whatsColors.png";
import Facebook from "../../assets/img/faceColors.png";
import Profile from "../../assets/img/profile.png";

import Newsletter from "../../components/Newsletter";

import Footer from '../../views/Web/HomePage/Footer/index'
import Header from "../../components/Header"
import BlogService from "../../services/BlogService";

import { useEffect, useState } from "react";

let base = process.env.REACT_APP_IMAGE_BASE_URL;

function BlogPostComponent({postData}) {
  const [posts, setPosts] = useState([]);
  const [mostRecentPosts, setMostRecentPosts] = useState([]);
  
  const path = postData?.image?.path;
  const name = postData?.image?.name;
  const BannerURL = base + path + name;

  useEffect(() => {
    getPosts();
    getMostRecentPosts();
  }, []);

  const getMostRecentPosts = async () =>  {
    const totalPosts = 4;
    
    const recentPosts = await BlogService.getMostRecentPosts(totalPosts);
    setMostRecentPosts(recentPosts);    
  }

  const getPosts = async () => {
    const totalPosts = 8;

    const posts = await BlogService.getMostAccessedPosts(totalPosts);
    setPosts(posts)
  }

  const createPostImageUrl = (imagePath, imageName) => {
    const imageUrl = base + imagePath + imageName;
    return imageUrl;
  }

  const formatDate = post  => {
    if(post?.created_at) {
      const postDate = new Date(post?.created_at);
      const day = postDate?.getDate();
      const month = postDate?.getMonth();
      const year = postDate?.getFullYear();
    
      const hours = postDate?.toLocaleTimeString("pt-BR", {hour: '2-digit', minute:'2-digit'});

      return `${day}/${month}/${year} às ${hours}`
    }
  }

  return (
    <div className="corpo">
      <Header/>
      <div className="HeaderNotice">
        <img src={BannerURL} alt="" className="backgroundHeader" />
        <div className="contentHeader">
          <div className="titleHeader">
            <span className="titleSpan">
              {postData.title}
            </span>
          </div>
        </div>
      </div>
      <div className="contentBlog">
        <div className="blogNews">
          <div className="Details">
            <div className="infos">
              <div className="dataPubli">
                <span>{formatDate(postData)}</span>
              </div>
              <div className="tempRead">
                <span>
                  {/* 13 minutos de leitura <img src={ReadTime} alt="" /> */}
                </span>
              </div>
            </div>
            <div className="infos">
              {/* <div className="authorInfos">
                <div
                  className="profileInfos"
                  style={{ backgroundImage: `url("${Profile}")` }}
                ></div>
                <span>
                  
                   Escrito por <strong>Maria Almeida</strong>
                </span>
              </div> */}
              <div className="share">
                <div
                  className="shareIcon"
                  style={{ backgroundImage: `url("${Twitter}")` }}
                ></div>
                <div
                  className="shareIcon"
                  style={{ backgroundImage: `url("${Instagram}")` }}
                ></div>
                <div
                  className="shareIcon"
                  style={{ backgroundImage: `url("${Whatssap}")` }}
                ></div>
                <div
                  className="shareIcon"
                  style={{ backgroundImage: `url("${Facebook}")` }}
                ></div>
              </div>
            </div>
            <div className="News">
            <div className='containerNewsData' dangerouslySetInnerHTML={{__html: postData.content }}></div>
            </div>
            {/* <div className="AuthorGeneral">
              <div
                className="profileAuthor"
                style={{ backgroundImage: `url("${Profile}")` }}
              ></div>
              <div className="AuthorGeneralInfos">
                <h5>Maria Almeida</h5>
                <h6>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda fugiat, quod minima repudiandae labore consectetur.
                </h6>
              </div>
            </div> */}
          </div>
        </div>
       
        <div className="Relacionados">
          <h3>Mais Recentes</h3>
          <div className="content-cards">
              {mostRecentPosts.map(post => (
                <a href={`/blog/${post?.id}/postagem-blog`} key={post?.id}>
                  <div className="cardRelacionados" href="#" style={{backgroundImage: `url("${createPostImageUrl(post?.image?.path, post?.image?.name)}")`}}>
                    <span className="categoriesRelacionados">
                      {post?.category}
                    </span>
                    <h3 className="title-notice-blogRelacionados">
                      {post?.title}
                    </h3>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
         
      <div className="MoreContentText">
        <span> Veja Mais Conteúdos </span>
      </div>
      <div className="MoreContent">
         {posts?.map(post => (
            <a className="MoreContentCards" href={`/blog/${post?.id}/postagem-blog`} key={post?.id}>
              <div className="imgMoreCards" style={{backgroundImage: `url("${createPostImageUrl(post?.image?.path, post?.image?.name)}")`}} />
              <div className="infoContentCards">
                <h4 className="title-more-cards">
                  {post?.title}
                </h4>
                <div className="MoreData">
                  <span>Criado em: {formatDate(post)}</span>
                  {/* <span>8 minutos <img src={ReadTime} alt="" /> </span> */}
                </div>
               </div>  
            </a>
          )
        )}
      </div>
      {/* <Newsletter /> */}
      <Footer />
    </div>
  );
}

export default BlogPostComponent;
