import React from 'react';
import CategoryProductList from './List';
import ProductCardComponent from '../../../components/ProductCardComponent'
const CategoryProductIndex = ({data, dataFull, page, perPage, setPagination}) => {
   const increasePage = () => {
        const updatedPage =  Math.min(page + 1, dataFull.last_page);

        setPagination(prevState => {
            return {
                ...prevState,
                page: updatedPage
            }
       });
   }

   const decreasePage = () => {
        const updatedPage = Math.max(page - 1, 1);

        setPagination(prevState => {
            return {
                ...prevState,
                page: updatedPage
            }
        });
   }

   const updatePerPage = (perPageValue) => {
        setPagination(prevState => {
            return {
                ...prevState,
                per_page: perPageValue
            }
        });
   }

   const handleNavigateToPage = page => { 
        setPagination(prevState => {
            return {
                ...prevState,
                page
            }
        });
   }

   return (
        <div className="product-body-wrap">
            <div className="tab-content product-tab-content">
                <div className="tab-pane fade show active">
                <div className='FilterPerPage'>
                    <select value={perPage} onChange={(event)=> updatePerPage(event.target.value) }>
                        <option value={15}>15 Produtos por página</option>
                        <option value={30}>30 Produtos por página</option>
                        <option value={60}>60 Produtos por página</option>
                        <option value={105}>105 Produtos por página</option>
                    </select>
                </div> 
                    <div className="row grid-products">
                            {
                                data?.map( produto => {
                                        return (
                                            <div className='item-grid-products' key={produto.CodigoInterno_Produto}>
                                                <ProductCardComponent
                                                    item={produto}
                                                    wish={true}
                                                />
                                            </div> 
                                        )
                                    })
                            }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="pagination-content-wrap">
                        <nav className="pagination-nav">
                            <ul className="pagination justify-content-center">
                                <li>
                                    <a onClick={() => handleNavigateToPage(1)}>1</a>
                                </li>
                                
                                <li >
                                    <a href="#/" style={{display: "flex", justifyContent: "center", alignItems:"center"}}  onClick={decreasePage}>
                                        <i className="icon-arrow-left"></i>
                                    </a>
                                </li>

                                <li><a className="active" href="#/">{page}</a></li>
                                
                                <li>
                                    <a href="#/" onClick={increasePage} style={{ display: "flex", justifyContent: "center", alignItems:"center"}}>
                                        <i className="icon-arrow-right"></i>
                                    </a>
                                </li>
                                
                                <li onClick={() => handleNavigateToPage(dataFull?.last_page)}>
                                    <a>{dataFull.last_page}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryProductIndex;