import React, { Component } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import ResumeBenefits from '../HomePage/ResumeBenefits';
import NewsletterIndex from '../Home/Partials/Newsletter/Index';
import SocialIndex from '../Home/Partials/Social/Index';
import Footer from '../HomePage/Footer';
import Header from '../../../components/Header';
import LoaderIndex from '../Layout/Loader/Index';
import ProductBannerIndex from './Partials/Banner/Index';
import { FaStar, FaFacebook, FaTwitter } from 'react-icons/fa';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Carrinho from "../../WishList/Index";
import Wish from "../../WishList/wish";
import { useState } from 'react';
import ProductService from '../../../services/ProductService';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import toast from 'react-hot-toast';

function Example2(props) {

    const path = process.env.REACT_APP_IMAGE_BASE_URL;

    const id = props.match.params.external_code;

    const [loading, setLoading] = useState(true);

    const [product, setProduct] = useState({});

    const [amount, setAmount] = useState(1);

    const getProductById = () => {
        ProductService.getProductById(id).then((data) => {
            let imageNotFound = 'https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg';

            let productImage = path + data?.product_image?.path + data?.product_image?.name;

            setProduct({
                ...data,
                default_product_image: !data.product_image ? imageNotFound : productImage
            });

            setLoading(false);
        });
    }

    useEffect(() => {
        getProductById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="wrapper home-default-wrapper">
            {loading ?
                <LoaderIndex />
                :
                <>
                    <Header />

                    <main className="main-content">
                        <ProductBannerIndex
                            nome_produto={product?.name_ecommerce}
                        />

                        <section className="product-area product-single-area">
                            <div className="container pt-60 pb-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-single-item" data-margin-bottom="62">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="product-thumb">
                                                        <div class2="swiper-container single-product-thumb-content single-product-thumb-slider2 swiper-container-fade swiper-container-initialized swiper-container-horizontal swiper-container-free-mode">
                                                            <div class2="swiper-wrapper" style={{ transitionDuration: '0ms' }}>
                                                                <div class2="swiper-slide zoom zoom-hover swiper-slide-active" style={{ width: '100%', maxWidth: '500px', marginLeft: "0px", opacity: '1', transform: "translate3d('0px, 0px, 0px')", position: 'relative', overflow: 'hidden', transitionDuration: '0ms' }}>
                                                                    <div className="__fluid__image-container" style={{ marginLeft: '-15px' }}>
                                                                        <InnerImageZoom src={product?.default_product_image} zoomSrc={product?.default_product_image} />
                                                                    </div>
                                                                    <div className="swiper-container single-product-nav-content single-product-nav-slider2 swiper-container-initialized swiper-container-horizontal swiper-container-free-mode swiper-container-thumbs">
                                                                        <div className="swiper-wrapper">
                                                                            <div className="swiper-slide swiper-slide-active swiper-slide-thumb-active" style={{ width: '153.75px', marginRight: '10px', borderRadius: '.2857rem', boxShadow: '0 3px 25.5px 4.5px rgb(0 0 0 / 6%)' }}>
                                                                                <img src={product?.default_product_image} /* onClick={() => this.setState({ selectedImage: `${path}/${image.nome_imagem}` })} */ alt={product?.name_ecommerce} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="product-single-info mt-sm-70">
                                                        <h1 className="title-product">{product?.name_ecommerce}</h1>
                                                        <h1 className="title-product">{/* {this.state.child.Abreviacao_Cor} */} - {/* {this.state.child.Nome_Cor} */}</h1>
                                                        <div className="product-info">
                                                            <div className="star-content">
                                                                <FaStar color={'#fdd835'} />
                                                                <FaStar color={'#fdd835'} />
                                                                <FaStar color={'#fdd835'} />
                                                                <FaStar color={'#fdd835'} />
                                                                <FaStar color={'#fdd835'} />
                                                                {/* <i className="ion-md-star icon-color-gray"></i> */}
                                                            </div>
                                                            {/*
                                                            <ul className="comments-advices">
                                                                <li><a href="#/" className="reviews"><i className="fa fa-commenting-o"></i>Read reviews (1)</a></li>
                                                                <li><a href="#/" className="comment"><i className="fa fa-pencil-square-o"></i>Write a review</a></li>
                                                            </ul>
                                                            */}
                                                        </div>
                                                        <div className="prices">
                                                            <span className="price">R$ 0,00</span>
                                                            {/* <div className="tax-label">Tax included</div> */}
                                                        </div>
                                                        <div className="product-description">
                                                            <ul className="product-desc-list">
                                                                {/* <p>Descrição curta do produto</p> */}
                                                                <p>
                                                                    <b>
                                                                        <span style={{ fontSize: '12px', textTransform: 'uppercase', color: '#656565' }}>
                                                                            <font size="4">DESCRIÇÃO</font>
                                                                        </span>
                                                                    </b>
                                                                </p>
                                                            </ul>
                                                        </div>
                                                        <div className="product-quick-action">
                                                            <div className="product-quick-qty">
                                                                <div className="pro-qty">
                                                                    <input type="text" title="Quantidade a ser comprada" value={amount || 1} onChange={(e) => setAmount(parseFloat(e.target.value || 1))}></input>
                                                                    <div className="inc qty-btn" onClick={() => setAmount(amount + 1)} >
                                                                        <i className="fa fa-angle-up"></i>
                                                                    </div>
                                                                    <div className="dec qty-btn" onClick={() => setAmount(amount - 1)}>
                                                                        <i className="fa fa-angle-down"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <a className="btn-product-add" href="#" onClick={() => this.addToCart(child)}>ADICIONAR AO CARRINHO</a> */}
                                                            {/* <a className="btn-product-add" href="#">ADICIONAR AO CARRINHO</a> */}
                                                            <Button className="btn-product-add" onClick={() => toast.success('Função em desenvolvimento')}>ADICIONAR AO CARRINHO</Button>
                                                        </div>
                                                        <div className="product-wishlist-compare">
                                                            <a href="#/" className="btn-wishlist"><i className="icon-heart"></i>Lista de desejo</a>
                                                            <a href="#/" className="btn-compare"><i className="icon-shuffle"></i>Comparar</a>
                                                        </div>
                                                        <div className="social-sharing">
                                                            <span>Compartilhar</span>
                                                            <div className="social-icons">
                                                                <a href="#/">
                                                                    <FaFacebook />
                                                                </a>
                                                                <a href="#/">
                                                                    <FaTwitter />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <section className="product-area mt-41">
                                    <div className="container">
                                        <hr />
                                        <div className="row">
                                            {[...Array(6)].map((e, index) =>
                                                <div key={index} className="col-md-2 product-item-block" style={{ marginTop: '25px' }} >
                                                    <div className="item-product-line-modal" style={{ width: "100%" }} /* ref={index === 0 ? color.ref : null} */>
                                                        <img src={product?.default_product_image} style={{ width: '100%', height: '180px', cursor: 'pointer' }} /* onClick={this.changeDefaultImage(child, image)} */ alt={product?.name_ecommerce} />
                                                    </div>
                                                    <div className="product-description" /* onClick={this.changeDefaultImage(child, image)} */>
                                                        <p style={{ color: '#000000', fontSize: '14px', fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}>{product?.name_ecommerce.substring(0, 35)}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <hr />
                                    </div>
                                </section>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-review-tabs-content">
                                            <ul className="nav product-tab-nav" >
                                                <li role="presentation">
                                                    <h5>DESCRIÇÃO</h5>
                                                </li>
                                            </ul>
                                            <div className="tab-content product-tab-content">
                                                <div className="product-description">
                                                    <p>
                                                        <b>*INSERIOR AQUI A DESCRIÇÃO DO PRODUTO</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="divider-area">
                            <div className="container pt-90 pt-lg-70 pb-lg-60">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="divider-style-wrap">
                                        <div className="row">
                                                <div className="col-md-12">
                                                    <SocialIndex />
                                                </div>
                                                {/* 
                                                <div className="col-md-6">
                                                    <NewsletterIndex />
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    {/* Footer Area Wrapper */}
                    <Footer />
                </>
            }
        </div>
    )
}

export default Example2