import { useState } from "react";

import Footer from "../Web/HomePage/Footer";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import PageService from "../../services/PageService";
import SocialMedia from "../../components/SocialMedia";

export default function CookiesPage() {
    const location = useLocation();

    const [textTitle, setTextTitle] = useState("");
    const [cookiesText, setCookiesText] = useState("");
    
    useState(async () => {
       const currentRoute = location.pathname.replace("/", "");

       const pageData = await PageService.getLgpdPageByPageName(currentRoute);
       
       const text = pageData?.data?.content;
       const title = pageData?.data?.title;
       
       setCookiesText(text);
       setTextTitle(title);
    }, []);

    return (
        <div className="wrapper home-default-wrapper">
            <Header />
            <main className="main-content" style={{ padding: 50 }}>
                <div className="News">
                    <div className="mb-4">
                        <h1>{textTitle}</h1>
                    </div>
                    <div className='containerNewsData' dangerouslySetInnerHTML={{__html: cookiesText}}></div>
                </div>
            </main>
            <SocialMedia />
            <Footer />
        </div>
    );
}