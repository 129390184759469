import axios from 'axios';
// toast
import toast from "react-hot-toast";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

api.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('auth-token');
    const guestToken = localStorage.getItem('guest-token');
    
    if (guestToken) {
        config.headers['guest-token'] = guestToken;
    }
    
    if (typeof authToken !== undefined) {
        // config.headers.Authorization = authToken;
        config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
});

api.interceptors.response.use(
    response => {
        if (response.status >= 400) {
            return Promise.reject(response);
        }

        return Promise.resolve(response)
    }, error => {

        const redirectUserToLogin  = () => {
            const url = window.location.pathname;
            window.location.href = `/?redirect_url=${url}`;
        }

        if (error.response.status === 401) {
            const { data } = error?.response

            if(data?.show_message) {
                toast.error(data.message);
                return Promise.reject(error);
            }

            const shouldRedirectUser = error.config?.redirect;
            if (shouldRedirectUser) {
                redirectUserToLogin();
            }
        }

        if (error.response.status === 403) {
            window.location.href = '/';
        }

        if (error.response.status === 400) {
            let { data } = error.response;

            Object.keys(data).map(key => {
                data[key].map(message => toast.error(message))
            })

        }

        if (error.response.status === 404) {
            window.location.href = '/404';
        }

        if (error.response.status === 422) {
            const { data }= error?.response;
            
            if (data?.errors) {
                Object.values(data?.errors).map(value => {
                    toast.error(value)
                });
            } else {
                toast.error(data?.message);
                return Promise.reject(data);
            }
        }

        if (error.response.status === 500) {
            console.log(error.response.data);
            console.log('Error message: ' + error.response.data.message);
            //toast.error(error.response.data.message);
            toast.error('Falha na comunicação com o servidor!');
        }

        if (error.response.status === 502) {
            const errors = error.response.data;

            errors?.map(error => {
                if (error !== "") {
                    toast.error(error);
                }
            })
        }

        return Promise.reject(error);
    }
);

export default api;
