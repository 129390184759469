//import React,{Component, useEffect, useRef, useState,createRef} from "react";

function wish() {

    this.wishes = [];

    this.getWishes = () => { return this.wishes };
    this.getCounterWishes = () => { return this.wishes.length };

    this.addWishes = (child, quantidade) => {
        let childIndex = this.wishes.findIndex(p => p.child.CodigoInterno_Produto === child.CodigoInterno_Produto);
        if (childIndex === -1) {
            this.wishes.push({ child: child, quantidade: quantidade });
        } else {
            this.wishes[childIndex].quantidade += quantidade;
        }
        this.triggerEvent();
    }

    this.trashWish = (index) => {
        this.wishes.splice(index, 1);
    }

    this.callbacks = [];

    this.onWishChange = (callback) => {
        this.callbacks.push(callback)
    }

    this.triggerEvent = () => {
        this.callbacks.map((callback) => {
            callback(this.wishes);
        })
    }
}

export default new wish;