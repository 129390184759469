import "./index.css";

import { Icon } from "@iconify/react";
import { toast } from "react-hot-toast";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import { useContext, useEffect, useState } from 'react';

import ProductService from "../../services/ProductService";
import AuthContext from "../../context/auth/AuthContext";

const ProductRatingModal = ({ openModal = false, setOpenModal, product = null, redirectUrl = "/" }) => {
    const authContext = useContext(AuthContext)

    const [loading, setLoading] = useState(true);
    const [ratedDate, setRatedDate] = useState("");
    const [productImage, setProductImage] = useState("");
    const [activeStarId, setActiveStarId] = useState(null)
    const [selectedRating, setSelectedRating] = useState(null);

    useEffect(() => {
        if(product && openModal) {
            getProductRating();
        }

        return () => {
            setLoading(true);
            setActiveStarId(null);
            setSelectedRating(null);
        }
    }, [product]);

    const getImageUrl  = () => {
        const productImage = product?.product_image?.[0];

        const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
        const imagePath = productImage?.path;
        const imageName = productImage?.name;

        const imageUrl = imageBaseUrl + imagePath + imageName;
        setProductImage(imageUrl)
    }

    const getProductRating  = () => {
        const productId = product?.external_code;
        const customerId = authContext?.user?.id;

        ProductService.getProductRating(productId, customerId).then(response => {
            const ratingData = response?.data;
            const imageExists = product?.product_image?.[0];

            if(imageExists) {
                getImageUrl();
            }

            if(ratingData) {
                const selectedRatingIndex = ratingData?.product_rating - 1;
                const ratedDate = new Date(ratingData?.created_at).toLocaleString('pt-BR').replace(',', ' às ');
                
                setRatedDate(ratedDate);
                setActiveStarId(selectedRatingIndex);
                setSelectedRating(selectedRatingIndex);
            }

            setLoading(false);
        });
    }

    const handleSelectedRating = starsIndex => {
        const loggedCustomer = authContext?.user;

        if(loggedCustomer && product) {
            const ratingValue = starsIndex + 1;
            const customerId = loggedCustomer?.id;
            const productId = product?.external_code;

            
            toast.promise(ProductService.createProductRating(customerId, productId, ratingValue), {
                loading: 'Carregando',
                error: 'Não foi possível avaliar o produto',
                success: 'Produto avaliado'
            }).then(() => {
                window.location.href = redirectUrl;
            });
        }
        
        setSelectedRating(starsIndex);
        setActiveStarId(starsIndex);
    }

    return (
        <Modal isOpen={openModal} centered={true} toggle={() => setOpenModal(!openModal)} >
            <ModalHeader>
                <h4 className="modal-title">Avalie o produto</h4>
            </ModalHeader>
            <ModalBody>
                <div className="modal-container">
                    {loading ? 
                        <div className="container-lds" style={{minHeight: "15vh", minWidth: "25vw"}}><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                        :
                        <>
                            {product && 
                                <>
                                    <h5>{product.product_data?.Nome_Produto}</h5>
                                    {productImage !== "" && <img src={productImage} alt="Imagem do produto" className="product-image"/>}
                                </>
                            }

                            {ratedDate &&
                                <span>
                                    <h6>Produto avaliado em: {ratedDate}</h6>
                                </span> 
                            }
                            <span className="order-rating-stars" onMouseLeave={() => selectedRating === null && setActiveStarId(null)}>
                                {[...Array(5)].map((value, index) => {
                                    return (
                                        <Icon 
                                            key={index} 
                                            width="40px" 
                                            onClick={() => handleSelectedRating(index)}
                                            onMouseOver={() => selectedRating === null && setActiveStarId(index)}              
                                            color={((activeStarId >= index) && activeStarId !== null) ? "yellow" : "black"}
                                            icon="material-symbols:star-rounded" 
                                        />
                                    );
                                })}
                            </span>
                        </>
                    }
                </div>
                <div className="close-modal-container">
                    <button className="close-modal-button" onClick={() => setOpenModal(false)}>Fechar</button>
                </div>
            </ModalBody>

        </Modal>
    );
}

export default ProductRatingModal;