import {React, useContext, useState} from "react";
import "./index.css"

// Components
import ButtonBackAnimate from "./ButtonBackAnimate";
import LoginModal from "../../../components/LoginModal";
import Descont from '../../../assets/img/Cart/descont.png';
import CartItem from './CartItem'

import { useHistory } from "react-router-dom";
import FreightService from "../../../services/FreightService";

import CartContext from '../../../context/cart/cartContext';
import AuthContext from "../../../context/auth/AuthContext";

// Assets
import VectorBottom from '../../../assets/img/Cart/vector-bottom-cart.png'
import VectorTop from '../../../assets/img/Cart/vector-top-cart.png'
import VectorTopMobile from '../../../assets/img/Cart/vector-top-mobile.png'

import { toast } from "react-hot-toast";
import { Modal, ModalBody } from 'reactstrap';
import { useEffect } from "react";

const CartPage = () => {
    const history = useHistory();
    const cartContext = useContext(CartContext);
    const authContext = useContext(AuthContext)

    const [coupon, setCoupon] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [freightData, setFreightData] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);

    useEffect(()=>{
        handleOverflow()
    },[])

    const handleApplyCouponDiscount = event => {
        event.preventDefault();

        const user = authContext.user;
        if (user === null) {
            toast.error('Faça seu login antes de aplicar um cupom.');
            return;
        }
        
        cartContext.addCouponDiscount(coupon, user);
    }
    
    const handleFinishOrder = () => {
        const isLoggedIn = authContext.isAuthenticated;

        if(isLoggedIn) {
            history.push("/carrinho/endereco");
        } else {
            setShowLoginModal(true)
        }
    }

    const handleCalculateFreight = event => {
        event.preventDefault();

        const cepValue = event.target.cep.value;

        toast.promise(FreightService.calculateCartFreight(cepValue), {
            loading: "Calculando frete...",
            success: "Pronto!",
            error: "Não foi possível calcular o frete."
        }).then(response => {
            const freightData = response;
            setFreightData(freightData);
        });
    }

    const handleOverflow = () => document.documentElement.style.overflowY = 'auto'

    return(
        <div className="cart-container">
            <ButtonBackAnimate link={'/'} buttonText="Continuar comprando" />
            <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} redirectUrl="/carrinho/checkout/pagamento" />
            <img src={VectorTopMobile} className="vector-top-mobile" alt="" />
            <img src={VectorBottom} className="vector-bottom" alt="" />
            <img src={VectorTop} className="vector-top"  alt="" />
            <div className="cart-content">
                <div className="cart-content-wrapper">
                    <h3 className="title-cart"> Seu Carrinho </h3>
                    <div className="box-items-cart">
                        {cartContext?.products?.map(product =>{
                            return(
                                <CartItem 
                                    key={product?.external_code}
                                    product={product}
                                />
                            )
                        })}
                    </div>
                    <div className="wrapper-rest">
                        <div className="rest-area">
                            <div className="shipping-area mt-10">
                                <h3 className="calc-text">Calcular o frete</h3>
                                <form onSubmit={event => handleCalculateFreight(event)}>
                                    <div className="inputs-calcul-shipping mt-20">
                                        <input type="text" maxLength="9" placeholder="Digite seu CEP" name="cep" />
                                        <button type="submit">CALCULAR</button>
                                    </div>
                                </form>
                                <div style={{marginTop:'10px'}}>
                                        {freightData &&
                                            freightData?.map(freight => (
                                                <div key={freight?.codigo} className="freight-items">
                                                    <span>{freight?.servico}</span>
                                                    <div style={{display:'flex', alignItems:'center', gap: '25px'}}>
                                                        <h6>R$ {freight?.valor?.toFixed(2)?.replace('.', ',')}</h6>
                                                        <h6>Prazo ({freight?.prazo} Dias)</h6>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                </div>
                               
                            </div>
                            <div className="cart-price-area">
                                <div className="price-list">
                                    <h5>SUBTOTAL</h5>
                                    <h5 className="subtotal">R$ {cartContext?.totalPrice}</h5>
                                </div>
                                {cartContext.totalWithDiscounts > 0 && 
                                    <div className="price-list">
                                        <h5>DESCONTO</h5>
                                        <h5 className="descont">-R$ {Math.round(cartContext?.totalPrice - cartContext?.totalWithDiscounts)}</h5>
                                    </div>
                                }
                                <hr />
                                <div className="price-list final">
                                    <h5>Total</h5>
                                    <h5 className="descont">R$ {cartContext?.totalWithDiscounts > 0 ?  cartContext?.totalWithDiscounts : cartContext?.totalPrice }</h5>
                                </div>
                            </div>
                        </div>
                        <form className="discount-container" onSubmit={event => handleApplyCouponDiscount(event)}>
                            <div className="descont-area">
                                <h4 className="descont-text"> Digite abaixo o seu cupom de desconto</h4>
                                <div className="input-descont-area">
                                    <input type="text" className='input-descont' placeholder='Digite seu cupom' value={coupon?.toUpperCase()} onChange={event => setCoupon(event.target.value?.toUpperCase())} />
                                    <button type='submit'>Aplicar</button> 
                                </div>
                            </div>
                        </form>
                        <button 
                            className="finish-shipping-btn"
                            style={{backgroundColor: '#5f3b73'}}
                            onClick={handleFinishOrder}
                        >
                            Finalizar Pedido
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartPage;