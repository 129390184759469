import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import './errorPage.css'
import '../Web/HomePage/FeaturedProducts/index.css'
import { Icon } from "@iconify/react";

class Error404 extends Component {
    render() {
        return (
            <div className="not-found-page">
                <div className="not-found-container">
                    <span className="primary-text-container">
                        <Icon icon="fluent-mdl2:sad-solid" color="#9d7fad" width="52px" />
                        <h2 className="not-found-error title">404</h2>
                        <span className="page-not-found">Page not found</span>
                    </span>
                    <span className="secondary-text-container">
                        <span className="text-desc-error">Eita! Parece que algo deu errado enquanto você vinha para cá!
                        Mas você pode voltar para a loja normalmente clicando no botão abaixo
                        </span>
                    </span>

                    <a href="/" className="back-to-shop">Voltar para a loja</a>
                </div>
            </div>
        )
    }
}

export default Error404;