import './index.css'
import { useContext } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

import CartContext from '../../../../context/cart/cartContext';

const CartItemSideBar = () => {
    const cartContext = useContext(CartContext);

    const handleRemoveCartProduct = product => {
        cartContext.removeProduct(product)
    }
   
    const handleIncreaseProductQuantity = product => {
        cartContext.increaseProductQuantity(product);
    }

    const handleDecreaseProductQuantity = product => {
        cartContext.decreaseProductQuantity(product);
    }

    const getProductTotal = (productValue, amount) => {
        const total = productValue * amount;
        return total.toFixed(2).replace('.', ',')
    }

    return(
        <div className="cart-sidebar-area">
            {cartContext?.products?.map((product) =>{
                const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
                const  noPhoto = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";
                let image = (product?.cart_product_image === null) ? noPhoto : imageUrl + product?.cart_product_image.path + product?.cart_product_image.name;
                
                return(
                    <div className='cart-item-sidebar' key={product?.cart_product_data?.CodigoInterno_Produto}>
                        <Link to={`/produto/${product?.external_code}/${product.has_variants ? 'exemplo-3' : 'exemplo-1'}`} style={{position: 'absolute', width: '100%', height: '100%'}} />
                        <div className="product-img">
                            <img src={image} alt="" />
                        </div>
                        <div className="info-product">
                            <span>{product?.cart_product_data?.Nome_Produto?.substring(0,20)+'...'}</span>
                            {/* <span className='product-size'>Tamanho: 70m</span> */}
                        </div>
                        <div className="qt">
                            <button style={{marginLeft: '0'}} onClick={() => handleDecreaseProductQuantity(product)}>-</button>
                            <span>{product?.amount}</span>
                            <button onClick={() => handleIncreaseProductQuantity(product)}>+</button>
                        </div>
                        <span className="price-product">
                            R$ {getProductTotal(product?.cart_product_data?.PrecoVendaECommercePraticado_ProEmpEmbPre, product?.amount)}
                        </span>
                        <Icon 
                            icon="uiw:close-square" 
                            width="24px" height="24px" 
                            className="btn-close-cart-item" 
                            color="#9d7fad" 
                            onClick={() => handleRemoveCartProduct(product)}
                        />
                        
                    </div>
                )
            })}
        </div>
    )
}

export default CartItemSideBar;