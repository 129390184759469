import React from "react";
import './index.css';
// Assets
import ProductImage from '../../../../assets/img/Cart/product-image.png'
import Close from '../../../../assets/img/Cart/button-close.png'
import { useContext } from "react";
import CartContext from "../../../../context/cart/cartContext";

const CartItem = ({ product }) => {
    const cartContext = useContext(CartContext)
    const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    const  noPhoto = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";         
    const image = (product?.cart_product_image === null) ? noPhoto : imageUrl + product?.cart_product_image.path + product?.cart_product_image.name;
    
    const handleRemoveItem = (product) => {
        cartContext.removeProduct(product)
    }            

    const handleIncreaseProductQuantity = () => {
        cartContext.increaseProductQuantity(product);
    }

    const handleDecreaseProductQuantity = () => {
        cartContext.decreaseProductQuantity(product);
    }

    const getProductTotal = (productValue, amount) => {
        const total = productValue * amount;
        return total.toFixed(2).replace('.', ',')
    }

    return(
        <div className="cart-item">
            <div className="box">
                <div className="image-cart-item">
                    <img src={image} alt="" />
                </div>
                <div className="details">
                    <div className="info-name-product">
                        <h3 className="name-cart-item"><span>{product?.cart_product_data?.Nome_Produto}</span> </h3>
                        <h3 className="brand-name-cart-item"><span>Marca: {product?.cart_product_data?.supplier?.NomeFantasia_Fornecedor}</span></h3>
                    </div>
                    <div className="specifications">
                        {/* <span className="size">Tamanho: 4 metros</span>
                        <span className="weight">Peso: 10 gramas</span> */}
                        {product?.cart_product_data?.color?.NomeSite_Cor &&
                            <div className="color-specifications"><span className="color">Cor:</span><span className="color">{product?.cart_product_data?.color?.NomeSite_Cor}</span></div>
                        }
                    </div>
                </div>
            </div>
            <div className="box gap" style={{alignItems: 'center'}}>
            <div className="amount">
                <h3 className="amount-text">Quantidade</h3>
                <div className="amount-controller">
                    <button onClick={handleDecreaseProductQuantity}>-</button>
                        <h3>{product?.amount}</h3>
                    <button onClick={handleIncreaseProductQuantity}>+</button>
                </div>
            </div>
            <div className="cart-price">
                {/* <h3 className="cart-descont-price">R$ 12,99</h3> */}
                <h3 className="cart-actually-price">R$ {getProductTotal(product?.cart_product_data?.PrecoVendaECommercePraticado_ProEmpEmbPre, product?.amount)}</h3>
            </div> 
            </div>
            <img src={Close} className="close-btn" onClick={()=> handleRemoveItem(product)} alt="" />
        </div>
    );
}

export default CartItem;