import { createContext } from "react";

const authContext = createContext({
    token: "",
    user: null,
    isAuthenticated: false,
    authenticateUser: () => {},
    getUserSession: () => {},
    signOutUser: () => {}
});

export default authContext;