import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

const testimonials = [
    {
        id: 1,
        name: 'Cliente 01',
        email: 'cliente@mail.com',
        photo: 'https://template.hasthemes.com/argima/argima/assets/img/testimonial/01.png',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet viverra ex, eu vulputate nisi semper in. Donec convallis elit orci, vitae suscipit felis pellentesque quis.'
    },
    {
        id: 2,
        name: 'Cliente 02',
        email: 'cliente@mail.com',
        photo: 'https://template.hasthemes.com/argima/argima/assets/img/testimonial/01.png',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere est lorem, vitae fringilla massa tristique sit amet. Interdum et malesuada fames ac ante ipsum.'
    },
    {
        id: 3,
        name: 'Cliente 03',
        email: 'cliente@mail.com',
        photo: 'https://template.hasthemes.com/argima/argima/assets/img/testimonial/01.png',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut finibus suscipit ante, non finibus felis volutpat at. Quisque iaculis sem mi, eget vehicula libero interdum sit amet'
    }
];

class TestimonialIndex extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === testimonials.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? testimonials.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        return (
            <section className="testimonial-area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 m-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Depoimentos</h2>
                                <div className="desc">
                                    <p>O que nossos clientes dizem!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Carousel className="owl-carousel owl-theme owl-loaded" activeIndex={activeIndex} next={this.next} previous={this.previous}>
                                <CarouselIndicators items={testimonials} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                {
                                    testimonials.map(testimonial => {
                                        return (
                                            <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={testimonial.id}>
                                                <div className="grid-template">
                                                    <div></div>
                                                    <div className="testimonials-slider enabled">
                                                        <div className="testimonial-item testi-height-style">
                                                            <div className="testi-inner-content">
                                                                <div className="testi-author">
                                                                    <div className="testi-thumb">
                                                                        <img src={testimonial.photo} alt="Image-HasTech" className="img" />
                                                                    </div>
                                                                    <div className="testi-info">
                                                                        <span className="name">{testimonial.name}</span>
                                                                        <span className="email">{testimonial.email}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="testi-content">
                                                                    <p>{testimonial.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div></div> */}
                                                </div>
                                            </CarouselItem>
                                        );
                                    })
                                }
                                <div className="owl-nav">
                                    <CarouselControl className="owl-prev" direction='prev' directionText='Anterior' onClickHandler={this.previous} />
                                    <CarouselControl className="owl-next" direction='next' directionText='Próximo' onClickHandler={this.next} />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TestimonialIndex;