import React from "react";
import './index.css';
import logo from '../../../src/assets/img/Logo.png';

function Newsletter(){
    return(
        <div>
            <div className="containerNewsletter">
                <div className="contentNewsletter">
                    <div className="boxNewsletter">
                        <div className="profile-pic">
                            <img src={logo} alt="" />
                        </div>
                        <h3 className="newsCopy">Fique atualizado sobre nossas postagens</h3>
                        <input type="text" placeholder='Digite seu e-mail' className="input-newsletter"/>
                        <button type="submit" className="newsletter-btn">QUERO FICAR ATUALIZADO</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;