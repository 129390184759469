
import LoaderIndex from "../Web/Layout/Loader/Index";
import Header from "../../components/Header";
import ProductService from "../../services/ProductService";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import CategoryBannerIndex from "../../components/Category/Banner/Index";
import CategoryFilterLeft from "../../components/Category/Filter/Left";
// import CategoryFilterTop from "../../components/Category/Filter/Top";
import CategoryProductIndex from "../../components/Category/Product/Index";
import ResumeBenefits from "../Web/HomePage/ResumeBenefits";
import SocialIndex from "../Web/Home/Partials/Social/Index";
import Footer from "../Web/HomePage/Footer";
import NewsletterIndex from "../Web/Home/Partials/Newsletter/Index";

function Index() {
    const { group, subgroup, department } = useParams();
    
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseFull, setResponseFull] = useState({});
    const [filteredGroup, setFilteredGroup] = useState(null);
    const [filteredSection, setFilteredSection] = useState(null);
    const [productFilterRequestBody, setProductFilterRequestBody] = useState({
        department_id: department,
        section_id: group ? group : null,
        group_id: subgroup ? subgroup: null,
        page: 1,
        per_page: 15,
        product_name: '',
        brands: [],
        sizes: [],
        colors: [],
        prioritize_query: false
    });

    useEffect(() => {
        getProducts();
        setLoading(true);
    }, [productFilterRequestBody, department, group, subgroup]);

    const clearFilters = () => {
        const filtersCleared = {
            department_id: department,
            section_id: group ? group : null,
            group_id: subgroup ? subgroup: null,
            page: 1,
            per_page: 15,
            product_name: '',
            brands: [],
            sizes: [],
            colors: [],
            prioritize_query: false
        }
        
        setProductFilterRequestBody(filtersCleared);
    }
    
const getProducts = () => {
        ProductService.getFilteredProducts(productFilterRequestBody, department, group, subgroup)
        .then(response => {
            setProducts(response.data);
            setResponseFull(response);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="wrapper home-default-wrapper">
            <Header />
            <main className="main-content">
                <CategoryBannerIndex  departmentId={department}/>
                <section className="product-area">
                    <div className="container mt-41">
                        <div className="row">
                            <CategoryFilterLeft
                                categoryId={department}
                                filters={productFilterRequestBody}
                                setFilters={setProductFilterRequestBody}
                                clearFilters={clearFilters}
                                productsLength={products?.length}
                                productsLoading={loading}
                                setShowLoading={setLoading}
                                selectedGroup={filteredGroup}
                                selectedSection={filteredSection}
                                updateSelectedSection={setFilteredSection}
                                updateSelectedGroup={setFilteredGroup}
                            />
                            <div className="col-lg-9 order-0 order-lg-2">
                                {loading && <div className="container-lds" style={{height: "100%"}}><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
                                {!loading &&
                                    <CategoryProductIndex
                                        categoryId={department}                                                 
                                        data={products}
                                        page={productFilterRequestBody?.page}
                                        perPage={productFilterRequestBody?.per_page}
                                        setPagination={setProductFilterRequestBody}
                                        dataFull={responseFull}
                                    />
                                }
                            </div> 
                        </div>
                    </div>
                </section>

                <section className="divider-area">
                    <div className="container pt-90 pt-lg-70 pb-lg-60">
                        <div className="row">
                            <div className="col-12">
                                <div className="divider-style-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <SocialIndex />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <NewsletterIndex />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Index;