import { useState } from "react";

import wishlistContext from "./wishlistContext";
import WishlistService from "../../services/WishlistService";

function WishlistProvider (props) {
    const [wishlistProducts, setWishlistProducts] = useState([]);
    
    const handleUpdateWishlist = product  => {
        setWishlistProducts(product)
    }
    
    const handleInsertProduct = (product) => {
        setWishlistProducts(prevState => {
            return [
                ...prevState,
                product
            ]
        });
        
        const productId = product.external_code;
        saveProductListOnLocalStorage(productId);
    }

    const handleRemoveProduct = (productId) => {
        const updatedProductsArray = wishlistProducts.filter(product => product.external_code !== productId); 
        
        setWishlistProducts(updatedProductsArray);
        removeProductOnLocalStorageList(productId);
    }

    const saveProductListOnLocalStorage = productId => {
        const wishlistExists = localStorage.hasOwnProperty("wishlist-products");

        if(wishlistExists) {    
            const wishlist = localStorage.getItem("wishlist-products");
            const localWishlist = JSON.parse(wishlist);
            const updatedProductsList = localWishlist.concat(productId);

            localStorage.setItem("wishlist-products", JSON.stringify(updatedProductsList));
        } else {
            const productIdList = wishlistProducts.map(product => product.external_code);
            const updatedProductsList = productIdList.concat(productId);
            
            localStorage.setItem("wishlist-products", JSON.stringify(updatedProductsList))
        }
    }

    const removeProductOnLocalStorageList = productId => {
        const productsList = JSON.parse(localStorage.getItem("wishlist-products"));
        const removedProducts = productsList.filter(product => product !== productId);

        localStorage.setItem("wishlist-products", JSON.stringify(removedProducts));
    }
    
    const wishlistData = {
        products: wishlistProducts,
        updateWishlist: handleUpdateWishlist,
        insertProduct: handleInsertProduct,
        removeProduct: handleRemoveProduct
    };

    return (
        <wishlistContext.Provider value={wishlistData}>
            {props.children}
        </wishlistContext.Provider>
    );
}

export default WishlistProvider;
