import './index.css'
import MultipleBannerSection from './MultipleBannerSection';
import SingleBannerSection from './SingleBannerSection';
import { useEffect, useState } from 'react';
import ProductService from '../../services/ProductService';

const SpecialSection = () => {
    const [banner, setBanner] = useState({});

    useEffect(() => {
        ProductService.getBannerSpecialSection().then(response => {
            setBanner(response);
        });
    }, []);

    return(
        <>
            {banner?.section_type === 1
                ? <SingleBannerSection banner={banner}/>
                : banner && <MultipleBannerSection banner={banner}/>
            }
        </>
    )
}

export default SpecialSection;