import { createContext } from "react";

const CartContext = createContext({
    cartData: {},
    products: [],
    totalAmount: 0,
    productsCount: 0,
    selectedAddress: {},
    selectedFreightService: {},
    totalWithDiscounts: 0,
    addProduct: item => {},
    removeProduct: id => {},
    updateCartProducts: () => {},
    addCouponDiscount: () => {},
    increaseProductQuantity: () => {},
    decreaseProductQuantity: () => {},
    clearCartProducts: () => {},
    updateCartFreightData: () => {}
});


export default CartContext;