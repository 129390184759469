import React, { Component } from 'react';

class NewsletterIndex extends Component {
    render() {
        return (
            <div className="divider-content text-center">
                <h4 className="title hidden-sm-down" data-margin-bottom="32">Cadastre-se para receber novidades</h4>
                <h4 className="title2 hidden-md-up collapsed mt-4" data-bs-toggle="collapse" data-bs-target="#newsletter">Cadastrre-se e receba nossas novidades!</h4>
                <div id="newsletter" className="collapse">
                    <div className="newsletter-content-wrap">
                        <div className="newsletter-form">
                            <form>
                                <input type="email" className="form-control" placeholder="Insira aqui seu e-mail" />
                                <button className="btn btn-submit" style={{margin: '0px'}} type="button">Cadastrar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsletterIndex;