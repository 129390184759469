import React, { Component } from 'react';

class SocialIndex extends Component {
    render() {
        return (
            <div className="divider-content text-center">
                <h4 className="title hidden-sm-down">Siga-nos nas redes sociais!</h4>
                <h4 className="title2 hidden-md-up collapsed" data-bs-toggle="collapse" data-bs-target="#social">Siga-nos nas redes sociais!</h4>
                <div id="social" className="collapse">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/lojalanamaria/" target="_blank">
                            <i className="la la-facebook"></i>
                        </a>
                        {/* <a href="#/">
                            <i className="la la-twitter"></i>
                        </a>
                        <a href="#/">
                            <i className="la la-youtube"></i>
                        </a> */}
                        <a href="https://www.instagram.com/lojalanamaria/" target="_blank">
                            <i className="la la-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default SocialIndex;