import React from "react";
import Linha from './linha_teste.jpg';
import './style.css'

const Apresentation = () => {
    return(
        <div>
            <div className="apresentation-wrapper">
                <div className="apresentation-text">
                <h3><span>O Lá Na Maria é o lugar</span> onde você encontra tudo que <span>precisa para se  aventurar no mundo</span> do artesanato, 
                faz oficinas com quem é <span>apaixonado pelo assunto</span>, se inspira, troca experiências e dá forma <span>às ideias 
                nos ateliês</span> de criação. </h3>
                </div>
                <svg width="3142" height="416" className="line-svg" viewBox="0 0 3142 416" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 116.795C47.3649 109.474 92.1074 105.116 139.621 103.296C213.142 100.48 322.395 98.7524 395.836 106.671C426.696 109.998 457.405 115.929 487.212 124.583C520.252 134.175 582.322 169.517 613.243 181.822C754.824 238.166 912.403 261.236 1063.89 266.189C1164.23 269.469 1248.25 266.415 1347.88 251.522C1393.34 244.726 1438.89 236.937 1483 224.005C1533.75 209.127 1615.62 174.958 1653.81 131.332C1678.28 103.377 1685.02 63.0321 1652.51 36.8411C1618.76 9.65364 1541.02 -0.970251 1502.21 1.53687C1478.39 3.07522 1391.92 20.0935 1382.02 59.1658C1376.68 80.227 1387.69 104.862 1402.01 121.208C1475.08 204.658 1622.5 253.69 1714.81 283.711C1918.82 350.06 2093.6 384.077 2308.24 403.382C2516.6 422.124 2649.52 417.14 2852.21 394.296C2956.14 382.583 3015.56 371.186 3107.51 345.364C3144.69 334.924 3131.7 343.354 3141 329.399" stroke="#9D7FAD" strokeWidth="1.70908" strokeDasharray="8.55 8.55"/>
                </svg>
                <div className="eclipse"></div>
            </div>
        </div>
    );
}

export default Apresentation;