import Item from "antd/lib/list/Item";
import React from "react";
import './index.css';
import { Link } from "react-router-dom";

function BlogViews({BlogData}){
  
    function GetBanner(item){
        let base = process.env.REACT_APP_IMAGE_BASE_URL;
        let path = item.image.path;
        let name = item.image.name;
        let BannerURL =  base + path + name;

        return BannerURL;
    }

    return(
        <div className="containerView" style={{marginTop:"50px"}}>
            <div className="contentView">
                <h3 className="Title">Postagens</h3>
                <div className="boxView">
                    {BlogData.filter((item, idx) => idx < 6).map((item)=>{
                        let BannerURL = GetBanner(item);
                        return(
                            <Link to={`/blog/${item.id}/postagem-blog`} key={item.id}>
                                <div className="cardView" href="#" style={{backgroundImage: `url(${BannerURL})`}}>
                                    <span className="categoriesView">{item.category}</span>
                                    <h3 className="title-notice-blogView">{item.title}</h3>
                                </div>
                            </Link>
                        )
                    })}   
                </div>
            </div>
        </div>
    )
}

export default BlogViews;