const debitCardPaymentStatusTranslation = {
    "approved": "Aprovado",
    "denied": "Reprovado",
    "canceled": "Cancelado",
    "pending": "Pendente"
}

const refundStatusTranslation = {
    done: "Concluído",
    denied: "Negado",
    processing: "Em processamento"
}

export default function DebitCard({ payment }) {

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return new Intl.DateTimeFormat('pt-BR', {dateStyle: "short", timeStyle: "short"}).format(date)?.replace(',', ' -');
    }
    
    
    function formatCurrency(currency) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        })
    
        return formatter?.format(parseFloat(currency) / 100);
    }

    return (
        <>
            <h5>Forma De Pagamento</h5>
            CARTÃO DE DÉBITO
            <div>
                <br />
                <i>
                    <b>Numero:</b> {payment?.authorization?.cardBin} ****** {payment?.authorization?.last4} <br />
                    <b>Nome do portador:</b> {payment?.authorization?.cardHolderName}<br />
                    <b>Status da transação: </b> {debitCardPaymentStatusTranslation[payment?.authorization?.status.toLowerCase()]} <br />
                    {payment?.refunds?.[0] && 
                        <>
                           <b>Reembolso: </b>{formatCurrency(payment?.refunds?.[0]?.amount)} às {formatTimestamp(payment?.refunds?.[0]?.refundDateTime)} - <b> Status:</b> {refundStatusTranslation?.[payment?.refunds?.[0]?.status?.toLowerCase()]}
                        </>
                    }
                </i>
            </div>
        </>
    );
}