import React from "react";
import './index.css'

import Artesanato from '../../../assets/img/artesanato.png'
import Line from './line.png'
import Wave from './wave.png'

const Story = () => {
    return (
        <div className="container-story">
            <div className="content-story">
            <img src={Line} className="line" alt="" />
            <img src={Artesanato} className="artesanato-img-float" alt="" />
            <div className="text-story">
                <h2 className="title-story">Nossa história</h2>
                <h5 className="story">
                    <p>
                        O Lá Na Maria começou em 2009 na cidade de Ribeirão Preto (SP). Antes de se tornar um negócio já era tradição na família reunir-se a 
                        mesa para o café da tarde e ter aquele bolo caseiro e quentinho saindo do forno nesse momento especial.
                        Com a necessidade de ter um negócio próprio a tradição familiar e os bolos caseiros que levam a nostalgia se tornaram um negócio de sucesso 
                        e hoje estão presentes em 163 cidades em 370 lojas espalhadas pelo Brasil.
                    </p>                                   
                </h5>
            </div> 
            </div>  
            <img src={Wave} className="wave"alt="" />

        </div>
    );
}


export default Story;