import Routes from "./routes";
import { Toaster } from "react-hot-toast";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Switch,  } from "react-router-dom";

import ImageService from "./services/ImageService";
import LoaderIndex from "./components/Loader/Index";

import CartContext from "./context/cart/cartContext";
import AuthContext from "./context/auth/AuthContext";

function App() {
    const cartContext = useContext(CartContext);
    const authContext = useContext(AuthContext)

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        updateCart();
        getActiveLogo();
        customerHasSession();
    }, []);

    const getActiveLogo = () => {
        ImageService.getActiveLogo().then(data => {
            let path = process.env.REACT_APP_IMAGE_BASE_URL;
            let activeLogoPath = path + data.image_path;
            ImageService.setActiveLogo(activeLogoPath);

            setLoading(false);
        });
    }

    const updateCart = () => {
        cartContext.getUserCart();
    }

    const customerHasSession = () => {
        const redirectUser = false;
        authContext.getUserSession(redirectUser);
    }
    
    return (
        <div className="App">
            {loading ?
                <LoaderIndex />
                :
                <BrowserRouter>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        toastOptions={{
                            style: {
                                background: '#8c5899',
                                color: '#fff',
                                zIndex: 1
                            }
                        }}
                    />
                    <Switch>
                        <Routes />
                    </Switch>
                </BrowserRouter>
            }
        </div>
    );
}

export default App;
