import './index.css'
import ProfilePic from '../../assets/img/profile-pic.png'
import Empty from '../../assets/img/empty-pic.png'
import ProfileI from '../../assets/img/Icones/profile.png'
import Favorite from '../../assets/img/Icones/favorite.png'
import History from '../../assets/img/Icones/history.png'
import Logout from '../../assets/img/Icones/logout.png'
import {Link} from 'react-router-dom'
import { useState, useContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CustomerService from '../../services/CustomerService'
import AuthContext from '../../context/auth/AuthContext'
import CartContext from '../../context/cart/cartContext'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import LoginModal from '../LoginModal'
import OrderImg from '../../assets/img/orders.png'
import { Icon } from '@iconify/react'

const Profile = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const cartContext = useContext(CartContext);

    const [profileIsOpen, setProfileIsOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
 
    const handleSignOut = () => {
        setProfileIsOpen(false);
        
        authContext.signOutUser();
        cartContext.getUserCart();
        history.push("/");
    }
    
    return(
        <>
            {authContext?.isAuthenticated === true ?
                <div className='profile'>
                    <div className="modal-script" style={{display: `${profileIsOpen ? 'block' : 'none'}`}} onClick={()=> setProfileIsOpen(false)}></div>
                    <div className="image-area-pic-profile" onClick={()=> setProfileIsOpen(true)}>
                        <img src={Empty} className='profile-pic-header' alt="" />
                        <div className="profile-area-menu" style={{display: `${profileIsOpen ? 'flex' : 'none'}`}}>
                            <div className="links-menu-profile">
                                <span> <Link to={'/lista-de-desejos'}><img src={Favorite} alt="" />Lista de desejos</Link> </span>
                                <span> <Link to={'/pedidos'}><Icon icon="material-symbols:order-approve" color="#9d7fad" /> Pedidos</Link> </span>
                                <span> <Link to={'/informações-pessoais'}><Icon icon="healthicons:ui-user-profile" color="#9d7fad" />Informações Pessoais</Link></span>
                            </div>
                            <span className='logout' onClick={ handleSignOut }><Link to={'#'}><img src={Logout} alt=""  /> Sair da conta</Link></span>
                        </div> 
                    </div>
                    <span className="text-profile">
                        Olá <span>{authContext?.user?.user_firstname}</span>, como vai ?
                    </span>
                </div> 
                : 
                <div className='profile'>
                    <button className='btn-login' onClick={() => setShowLoginModal(true)}>Entrar</button>
                    <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} />
                </div>
            }

        </>
    );
}

export default Profile;