import api from "./api";

class CouponService {
  async applyDiscount(couponName, user) {
    const requestBody = {
      coupon: couponName,
      user
    }

    const response = await api.post("store/coupon/apply-coupon", requestBody);
    return response;
  }
}

export default new CouponService();