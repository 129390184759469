import api from "./api";

class WishlistService {
    async insertProduct(userId, productId) {
        const requestBody = {
            user_id: userId,
            product_id: productId
        };

        const response = await api.post("/store/wishlist/insert-product", requestBody);
        return response;
    }

    async getWishlistByUserId(userId) {
        const response = await api.get(`/store/wishlist?user_id=${userId}`);
        return response;
    }
}

export default new WishlistService();