import {React, useState, useEffect} from "react";
import './index.css';
import { Icon } from '@iconify/react';
import Profile from "../../Profile";
import menuItems from "../../MenuDepartaments/navAPI";
import {Link} from 'react-router-dom';
import ProductService from '../../../services/ProductService';
import { Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';

const SideBar = ({departments, showMenu, setShowMenu}) => {
    const [isVisible, setIsVisible] = useState(false);

    return(
        <div className={`container-sidebar ${showMenu && 'show'}`}>
            <div className="content-sidebar">
                <div className="top">
                    <div className="close" onClick={()=>{ setShowMenu(false);}}>
                        <div className="dt"></div>
                        <div className="dt second"></div>
                    </div>
                </div>
                <Sidebar>
                    <Menu
                        menuItemStyles={{
                            button: {
                                ['&.active']: {
                                    backgroundColor: '#13395e',
                                    color: '#b6c8d9',
                                },
                            },
                        }}
                    >
                        <MenuItem component={<Link to="/" />}> Home</MenuItem>
                        <MenuItem component={<Link to="/blog/externo" />}> Blog</MenuItem>
                        <MenuItem component={<Link to="/sobre-nos" />}> Sobre Nós</MenuItem>
                        <SubMenu label="Departamentos">  
                            {departments?.map((item, index)=>{
                                return(
                                    <SubMenu label={`${item?.Nome_DepartamentoCommerce}`} key={index}>
                                        <MenuItem component={<Link to={`/departamento/${item?.CodigoInterno_DepartamentoCommerce}`}/> } >{item?.Nome_DepartamentoCommerce} </MenuItem>
                                        {item?.commerce_sections.map((subitem,index)=>{
                                            return(
                                                <MenuItem key={index} component={<Link to={`/departamento/${subitem?.CodigoInterno_DepartamentoCommerce}/g/${subitem?.CodigoInterno_SecaoCommerce}`}/> }>{subitem?.Nome_SecaoCommerce} </MenuItem>
                                            )
                                        })}
                                    </SubMenu>
                                )
                            })}
                        </SubMenu>
                    </Menu>
                </Sidebar>;
                <Profile/>
            </div>
        </div>
    );
}

export default SideBar;