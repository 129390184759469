import { useEffect, useState } from 'react';

import BlogService from '../../../services/BlogService';
import BlogPostComponent from '../../../components/BlogPostComponent';



function BlogPost(props){
    const [post, setPost] = useState({});

    useEffect(async () => {
        const postId = props.match.params.id;
        const postData = await BlogService.getBlogPostById(postId);
        setPost(postData);
      },[]);

    return<>
    <BlogPostComponent postData={post} />
    </>
}

export default BlogPost;