import React from "react";
import './index.css'
import Marquee from "react-fast-marquee";
import Trico from '../../../../assets/img/trico.jpg'
import Wave from '../../../../assets/img/home-wave.png'
import WaveReverse from '../../../../assets/img/home-wave-reverse.png'
import { Link } from "react-router-dom";
import Armarinho from '../../../../assets/img/departaments/armarinho.jpg'
import Biscuit from '../../../../assets/img/departaments/biscuit.jpg'
import Bordado from '../../../../assets/img/departaments/bordado.jpg'
import Costura from '../../../../assets/img/departaments/costura.jpeg'
import Croche from '../../../../assets/img/departaments/croche.jpg'
import Decoupagem from '../../../../assets/img/departaments/decoupage.jpg'
import MDFs from '../../../../assets/img/departaments/mdfs.jpg'
import Papelaria from '../../../../assets/img/departaments/papelaria.jpg'
import Pintura from '../../../../assets/img/departaments/pintura.jpg'
import Resina from '../../../../assets/img/departaments/resina.jpg'


const SliderDepartaments = () => {
    return(
        <div className="container-slider-departaments">
            <div className="title-slider-departaments">Aproveite a nossa gama de departamentos</div>
            <Marquee className="slider-main" gradient={false}>
                <div className="depart-slider">
                    <a href="/departamento/8" className="link-overlay"></a>
                    <img src={Armarinho} alt="" /> 
                    <h3 className="text-slider">ARMARINHO</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/6" className="link-overlay"></a>
                    <img src={Biscuit} alt="" /> 
                    <h3 className="text-slider">BISCUIT</h3>
                </div>
                <div className="depart-slider">
                     <a href="/departamento/2" className="link-overlay"></a>
                    <img src={Bordado} alt="" /> 
                    <h3 className="text-slider">BORDADO</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/10" className="link-overlay"></a>
                    <img src={Costura} alt="" /> 
                    <h3 className="text-slider">COSTURA </h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/1" className="link-overlay"></a>
                    <img src={Croche} alt="" /> 
                    <h3 className="text-slider">CROCHE</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/14" className="link-overlay"></a>
                    <img src={Decoupagem} alt="" /> 
                    <h3 className="text-slider">DECOUPAGEM</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/4" className="link-overlay"></a>
                    <img src={MDFs} alt="" /> 
                    <h3 className="text-slider">MDFs</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/7" className="link-overlay"></a>
                    <img src={Papelaria} alt="" /> 
                    <h3 className="text-slider">PAPELARIA</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/9"></a>
                    <img src={Pintura} alt="" /> 
                    <h3 className="text-slider">PINTURA</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/3"></a>
                    <img src={Resina} alt="" /> 
                    <h3 className="text-slider">RESINA</h3>
                </div>
                <div className="depart-slider">
                    <a href="/departamento/5"></a>
                    <img src={Trico} alt="" /> 
                    <h3 className="text-slider">TRICO</h3>
                </div>
            </Marquee>
            
            <img src={Wave} alt="" className="wave-home" />
            <img src={WaveReverse} alt="" className="wave-reverse" />
        </div>
    );
}

export default SliderDepartaments;