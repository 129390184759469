import React, { Component } from 'react';
import { Col } from 'reactstrap';

const latests = [
    {
        id: 1,
        title: 'Como fazer "Sousplat de Crochê" com a Linha EuroRoma Passione',
        category: 'Crochê',
        photo: 'https://blog.bazarhorizonte.com.br/wp-content/uploads/2019/03/sousplat-1.jpg',
    },
    {
        id: 2,
        title: 'Como fazer "Amigurumi Arara" com Fio Tropfil',
        category: 'Amigurumi',
        photo: 'https://blog.bazarhorizonte.com.br/wp-content/uploads/2021/10/1-5.jpg'
    },
    {
        id: 3,
        title: 'Como fazer "Almofada de Tricô" com Tiras de Malha Extra',
        category: 'Tricô',
        photo: 'https://blog.bazarhorizonte.com.br/wp-content/uploads/2021/09/1-6.jpg'
    }
];

class BlogLatest extends Component {
    render() {
        return (
            <section className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 m-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Últimas do blog</h2>
                                <div className="desc">
                                    <p>Apresente as postagens da melhor maneira para destacar momentos interessantes do seu blog</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Col>
                            <div className="owl-carousel owl-theme owl-loaded owl-drag">
                                <div className="grid-template-blog">
                                    {
                                        latests.map((latest, index) => {
                                            return (
                                                <div key={index} className="owl-item active blog-product">
                                                    <div className="post-item">
                                                        <div className="inner-content">
                                                            <div className="thumb">
                                                                <a href="#/">
                                                                    <img src={latest.photo} className="img" alt={`Imagem ${latest.title}`} />
                                                                </a>
                                                            </div>
                                                            <div className="content">
                                                                <a className="category" href="#/"><b>{latest.category}</b></a>
                                                                <h4 className="title">
                                                                    <a href="#/">{latest.title}</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </section>
        );
    }
}

export default BlogLatest;