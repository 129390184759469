import {React, useState, useEffect} from "react";
import './index.css'
import Marquee from "react-fast-marquee";
import ProductService from "../../../../services/ProductService";
import { Link } from "react-router-dom";

const SliderProductsCatalog = () => {
    const [products, setProducts] = useState([]);
   
    useEffect(()=>{
        getFeaturedProducts();
    }, []);

    const getFeaturedProducts = () => {
        ProductService.getRandomProducts()
            .then(response => {
                setProducts(response);
            })
    }

    const getProductImageUrl = product => {
        const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
        const productImagePath = product?.image?.path;
        const productImageName = product?.image?.name;

        const imageNotFound = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg"; 
        if(productImagePath && productImageName) {
            return imageBaseUrl + productImagePath + productImageName;
        } else {
            return imageNotFound;
        }
    }

    const getProductUrl = product => {
        const productHasVariants = product?.has_variants;
        if(productHasVariants) {
            return `/produto/${product?.CodigoInterno_Produto}/exemplo-3`
        } else {
            return `/produto/${product?.CodigoInterno_Produto}/exemplo-1`
        }
    }
    return(
        <div className="container-slider-catalog">
            <h3 className="title-slider-catalog">Aproveite a infinidade de produtos únicos</h3>
            <Marquee gradient={false} speed={10} >
               {products?.map(item => {
                if(item?.image !== null) {
                    return(
                        <div className="image-slider-container" key={item?.CodigoInterno_Produto}>
                            <Link to={getProductUrl(item)}><img className="image-slider" src={getProductImageUrl(item)} alt="Imagem produto" /></Link> 
                        </div>
                    )
                }
               })}
               </Marquee>  
        </div>
    );
}

export default SliderProductsCatalog;