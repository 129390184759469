import React, { Component , useContext} from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import ResumeBenefits from '../HomePage/ResumeBenefits';
import NewsletterIndex from '../Home/Partials/Newsletter/Index';
import SocialIndex from '../Home/Partials/Social/Index';
import Footer from '../HomePage/Footer';
import Header from '../../../components/Header';
import LoaderIndex from '../Layout/Loader/Index';
import ProductBannerIndex from './Partials/Banner/Index';
import { FaStar, FaFacebook, FaTwitter } from 'react-icons/fa';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Carrinho from "../../WishList/Index";
import Wish from "../../WishList/wish";
import { useState } from 'react';
import ProductService from '../../../services/ProductService';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import toast from 'react-hot-toast';
import stars from '../../../assets/img/stars.png'
import { Icon } from '@iconify/react';
import CartContext from '../../../context/cart/cartContext';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth/AuthContext';
import WishlistContext from '../../../context/wishlist/wishlistContext';
import WishlistService from '../../../services/WishlistService';
import FreightService from '../../../services/FreightService';
import AliceCarousel from 'react-alice-carousel';
import ProductCard from '../../../components/ProductCardComponent';
import CarouselItems from '../../../components/CarouselItems';

const imageNotFound = 'https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg';

function Example1(props) {
    const cartContext = useContext(CartContext);
    const authContext = useContext(AuthContext);
    const wishlistContext = useContext(WishlistContext);

    const [mainImage, setMainImage] = useState({});
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState(1);
    const [freightData, setFreightData] = useState([]);
    const [isFavorite, setIsFavorite] = useState(false);
    const [selectedSize, setSelectedSize] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [oftenPurshasedProducts, setOftenPurshasedProducts] = useState([]);

    useEffect(() => {
        getProductById();
        getRelatedProducts();
        getOftenPurshasedProducts()
        productIsInsertedOnWishlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const productIsInsertedOnWishlist = () => {
        const id = parseInt(props.match.params.external_code);
        const wishlistExists = localStorage.hasOwnProperty("wishlist-products");
        
        if(wishlistExists) {
            const wishlistProducts = JSON.parse(localStorage.getItem("wishlist-products"));
            const productAlreadyInserted = wishlistProducts.includes(id);

            const isUserLoggedIn = authContext.isAuthenticated;
            if(productAlreadyInserted && isUserLoggedIn) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false)
            }
        }
    }

    const getRelatedProducts = () => {
        const productId = props.match.params.external_code;
        
        ProductService.getRelatedProducts(productId)
        .then(response => {
            const relatedProducts = response?.data;
            setRelatedProducts(relatedProducts);
        }); 
    }

    const getOftenPurshasedProducts = () => {
        ProductService.getOftenPurshasedProducts()
        .then(response => {
            const oftenPurshasedProducts = response?.data;
            setOftenPurshasedProducts(oftenPurshasedProducts)
        })
    }

    const getProductById = () => {
        const id = props.match.params.external_code;

        ProductService.getProductVariantById(id).then(response => {
            const { data } = response; 
            const mainImage = data?.images[0] ? data?.images[0] : null;

            setProduct(data);
            setLoading(false);
            setMainImage(mainImage);
        });
    }

    const handleInsertProductInWishlist = () => {
        const id = parseInt(props.match.params.external_code);

        if (!authContext.isAuthenticated) {
            toast.error('Faça seu login para adicionar o produto na lista de desejos.');
            return;
        }

        const userId = authContext?.user?.id;
        const loadingMessage = toast.loading("Atualizando lista de desejos.");

        WishlistService.insertProduct(userId, id)
        .then(response => {
            const productData = response.data;
            const productWasInserted = response.status === 201;

            if(productWasInserted) {
                toast.remove(loadingMessage);
                toast.success("Produto inserido na lista de desejos.");
                
                setIsFavorite(true);
                wishlistContext.insertProduct(productData);
            } else {
                setIsFavorite(false);
                toast.success("Produto removido da lista de desejos.");
                wishlistContext.removeProduct(id);
            }
            
            toast.remove(loadingMessage);
        });
    }
    
    const getImageUrl = imageData => {
        if(imageData) {
            const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            const imagePath = imageData?.path;
            const imageName = imageData?.name;
            const imageUrl = imageBaseUrl + imagePath + imageName;

            return imageUrl;
        }

        return imageNotFound;
    }

    const getSelectedSizePrice = () => {
        const size = product?.product_sizes?.find(size => {
            if(size?.CodigoInterno_ProEmb === selectedSize) {
                return size;
            }
        });   

        return size?.PrecoVendaECommercePraticado_ProEmpEmbPre?.replace('.',',');
    }

    const handleCalculateFreight = event => {
        event.preventDefault();

        const productId = props.match.params.external_code;
        const cepValue = event.target.cep.value;
        
        if(cepValue.length > 7) {
            toast.promise(FreightService.calculateProductFreight(cepValue, productId, amount), {
                loading: "Calculando frete...",
                success: "Pronto!",
                error: "Não foi possível calcular o frete."
            }).then(response => {
                const freightData = response?.data;
                setFreightData(freightData);
            });
        } else {
            toast.error('Cep inválido!');
        }
    }

    return (
        <div className="wrapper home-default-wrapper">
            {loading ?
                <LoaderIndex />
                :
                <>
                    <Header />

                    <main className="main-content">
                        <ProductBannerIndex productData={product} />
                        <section className="product-area product-single-area">
                            <div className="container pt-60 pb-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-single-item" data-margin-bottom="62">
                                            <div className="row">
                                                <div className="col-md-6 fix-width">
                                                    <div className="product-thumb">
                                                        <div className='product-image-extra'>
                                                            { product?.images?.map(image => ( 
                                                                    <span style={{cursor: "pointer"}} key={image?.id}>
                                                                        <img src={getImageUrl(image) } onClick={() => setMainImage(image)} alt={product?.name_ecommerce} style={{maxHeight: 260}}/>
                                                                    </span>
                                                            ))}
                                                        </div>
                                                        <div className='main-product' style={{display: "flex", width: "100%", justifyContent: "center"}}> 
                                                            <InnerImageZoom src={getImageUrl(mainImage)} zoomSrc={product?.default_product_image} zoomScale={2} zoomPreload={true} moveType="pan"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 fix-width">
                                                    <div className="product-single-info mt-sm-70">
                                                        <h1 className="title-product">{product?.Nome_Produto}</h1>
                                                        <div className="product-info">
                                                            <div className="ranking my-3">
                                                                {[...Array(5)].map((value, index) => {
                                                                    return (
                                                                        <Icon 
                                                                            key={index} 
                                                                            width="25px" 
                                                                            color={((product?.product_rating?.average_rating - 1 >= index) ) ? "#FFD700" : "#CCCCCC"}
                                                                            icon="material-symbols:star-rounded" 
                                                                        />
                                                                    );
                                                                })}
                                                                {product?.product_rating && 
                                                                    <h3 className="ranking-number">
                                                                        {Number(product?.product_rating?.average_rating)?.toFixed(1)?.replace('.', ',')} ({product?.product_rating?.total_rating})
                                                                    </h3>
                                                                }
                                                            </div>
                                                            <div>
                                                                {product?.size &&
                                                                    <p>
                                                                        Tamanho: {product?.size?.Nome_Tamanho}
                                                                    </p> 
                                                                }
                                                            </div>
                                                            {product?.product_sizes?.length > 1 &&
                                                                <div className='container-size-select flex gap-2'>
                                                                    {product?.product_sizes?.map(size => {
                                                                        // if(size?.NomeReduzido_ProEmb?.length > 0) {
                                                                            return(
                                                                                <div 
                                                                                    key={size?.CodigoInterno_ProEmb}
                                                                                    className={`button-size-select ${size?.CodigoInterno_ProEmb === selectedSize && 'selected'}`} 
                                                                                    onClick={() => setSelectedSize(size?.CodigoInterno_ProEmb)}
                                                                                >
                                                                                    {size?.NomeReduzido_ProEmb} ({size?.Nome_Embalagem})
                                                                                </div>
                                                                            )
                                                                        // }
                                                                    })}
                                                                </div>
                                                            }
                                                            {/*
                                                            <ul className="comments-advices">
                                                                <li><a href="#/" className="reviews"><i className="fa fa-commenting-o"></i>Read reviews (1)</a></li>
                                                                <li><a href="#/" className="comment"><i className="fa fa-pencil-square-o"></i>Write a review</a></li>
                                                            </ul>
                                                            */}
                                                        </div>
                                                        <div className="prices">
                                                        <div className="prices">
                                                            {selectedSize 
                                                                ? <span className="price">R$ {getSelectedSizePrice()}</span>
                                                                : <span className="price">R$ {product?.PrecoVendaECommercePraticado_ProEmpEmbPre.toFixed(2).replace('.',',')}</span>
                                                            }
                                                            {/* <div className="tax-label">Tax included</div> */}
                                                        </div>

                                                            {/* <span className="price">R${product?.PrecoVendaECommercePraticado_ProEmpEmbPre.toFixed(2).replace('.',',')}</span> */}
                                                            {/* <div className="tax-label">Tax included</div> */}
                                                        </div>
                                                        
                                                        <div className="product-quick-action">
                                                            <div className="product-quick-qty">
                                                                <div className="pro-qty">
                                                                    <input type="text" title="Quantidade a ser comprada" value={amount || 1} onChange={(e) => setAmount(parseFloat(e.target.value || 1))}></input>
                                                                    <div className="inc qty-btn" onClick={() => setAmount(Math.min(amount + 1, 50))} >
                                                                        <i className="fa fa-angle-up"></i>
                                                                    </div>
                                                                    <div className="dec qty-btn" onClick={() => setAmount(Math.max(amount - 1, 1))}>
                                                                        <i className="fa fa-angle-down"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <a className="btn-product-add" href="#" onClick={() => this.addToCart(child)}>ADICIONAR AO CARRINHO</a> */}
                                                            {/* <a className="btn-product-add" href="#">ADICIONAR AO CARRINHO</a> */}
                                                            {product?.is_available_in_stock ? 
                                                                <button className="add-to-cart" style={{maxWidth: 'max-content', paddingRight: '120px'}}onClick={() => cartContext.addProduct(product, amount, selectedSize)}>
                                                                    <Icon icon="carbon:add" width="24" height="24" color='white' /> Adicionar ao Carrinho
                                                                </button>
                                                                : <span className='unavaliable-product-alert' > <Icon icon="iconamoon:unavailable" width="24px" color='red' /> Este produto nao está indisponível </span>
                                                            }

                                                        </div>
                                                        <Icon icon={`mdi:cards-heart${isFavorite ? "" : "-outline"}`} 
                                                        onClick={handleInsertProductInWishlist}  
                                                        className={`favorite ${isFavorite && "animate"}`} 
                                                        color="772AA0" width="24" height="24"/>
                                                        
                                                        Adicionar a lista de desejos

                                                        <div className="consult-freight-container w-75 ">
                                                            <div className="mb-30">
                                                                {freightData &&
                                                                    freightData?.map(freight => (
                                                                        <>
                                                                            <div key={freight?.codigo} className="freight-items">
                                                                                <span>{freight?.servico}</span>
                                                                                <div className="d-flex gap-3">
                                                                                    <h6>R$ {freight?.valor?.toFixed(2)?.replace('.', ',')}</h6>
                                                                                    <h6>Prazo ({freight?.prazo} Dias)</h6>
                                                                                </div>
                                                                            </div>
                                                                            {freight?.obs && <h6  className="mb-50 mt-5">* {freight?.obs}</h6>}
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>

                                                            <form onSubmit={event => handleCalculateFreight(event)}>
                                                                <div>
                                                                    <h5>Consultar frete e prazo de entrega</h5>
                                                                </div>
                                                                <div className="inputs-calcul-shipping mt-20">
                                                                    <input type="text" maxLength="9" placeholder="Digite seu CEP" name="cep" className="w-100" />
                                                                    <button type="submit">CALCULAR</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-41">
                                        <div className="product-review-tabs-content">
                                            <ul className="nav product-tab-nav " >
                                                <li role="presentation">
                                                    <h5 className='title-decoration'>Descrição</h5>
                                                </li>
                                            </ul>
                                            <div className="tab-content product-tab-content">
                                                <div className="product-description" dangerouslySetInnerHTML={{__html: product?.ComplementoCommerce_Produto}}>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 mt-41">
                                        <div className="product-review-tabs-content">
                                            <ul className="nav product-tab-nav " >
                                                <li role="presentation">
                                                    <h5 className='title-decoration'>Produtos relacionados</h5>
                                                </li>
                                            </ul>
                                                    <CarouselItems items={relatedProducts} />
                                        </div>
                                    </div>
                                </div>
                                {oftenPurshasedProducts?.length > 0 && 
                                    <div className="row">
                                        <div className="col-12 mt-41">
                                            <div className="product-review-tabs-content">
                                                <ul className="nav product-tab-nav " >
                                                    <li role="presentation">
                                                        <h5 className='title-decoration'>Os clientes também compraram</h5>
                                                    </li>
                                                </ul>
                                                    <CarouselItems items={oftenPurshasedProducts} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                
                            </div>
                        </section>

                        <section className="divider-area">
                            <div className="container pt-90 pt-lg-70 pb-lg-60">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="divider-style-wrap">
                                        <div className="row">
                                                <div className="col-md-12">
                                                    <SocialIndex />
                                                </div>
                                                {/* 
                                                <div className="col-md-6">
                                                    <NewsletterIndex />
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    {/* Footer Area Wrapper */}
                    <Footer />
                </>
            }
        </div>
    )
}

export default Example1