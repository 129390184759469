import ReactDOM from 'react-dom';
import AuthProvider from './context/auth/AuthProvider';
import CartProvider from "./context/cart/CartProvider";
import WishlistProvider from './context/wishlist/WishlistProvider';

import './assets/css/animate.css';
import './assets/css/bootstrap.min.css';
import './assets/css/lineIcons.css';
import './assets/css/simple-line-icons.css';
import './assets/css/spacing.css';
import './assets/css/style.css';

import App from './App';

ReactDOM.render(
    <AuthProvider>
        <CartProvider>
            <WishlistProvider>
                <App />
            </WishlistProvider>
        </CartProvider>
    </AuthProvider>
    ,
    document.getElementById('root')
);