import React from "react";
import './index.css'

import Shape from '../../../assets/img/lanamaria-shape.png'
import Facebook from '../../../assets/img/Icones/facebook.png'
import Instagram from '../../../assets/img/Icones/instagram.png'
import Twitter from '../../../assets/img/Icones/twitter.png'
import Youtube from '../../../assets/img/Icones/youtube.png'

const Description = () => {
    return(
            <div className="container-description">
                <div className="text-description"> 
                    <h3>Um conceito elegante e organizacional para florescer sua criatividade atráves dos nossos produtos</h3>
                </div>
                <div className="image-description-area">
                    <img src={Shape} alt="" />
                    <h6 className="text-image-description">
                    Elegante e organizacional com o intuito de fazer você ter uma experiência prazerosa de compra e que a criatividade seja aflorada com a grande variedade de produtos disponíveis na empresa.
                    </h6>
                </div>
                <div className="social-media"> 
                <a href="" className="facebook"><img src={Facebook} alt="" /></a>
                <a href="" className="instagram"><img src={Instagram} alt="" /></a>
                <a href="" className="twitter"><img src={Twitter} alt="" /></a>
                <a href="" className="youtube"><img src={Youtube} alt="" /></a>
                </div>
            </div>
    );
}


export default Description;

