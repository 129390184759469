import './index.css'
import InitialMak from "../../assets/img/init-mark.png"
import FinalMark from "../../assets/img/final-mark.png"
import Profile from '../../assets/img/profile-testimonial.png'
import Header from '../Header'
import CartContext from '../../context/cart/cartContext'
import { useContext } from 'react'

const Testimonial = () => {
    return(
        <div >
            <div className="testimonial-container">
                <img src={Profile} className="testimonial-profile" alt="" />
                <span className="name-testimonial">Mariana A.</span>
                <span className="testimonial-date">Pratica artesanato a 4 anos</span>
                <span className="ind-testimonial-text">
                    <img src={InitialMak} id="initial" alt="" />
                    <img src={FinalMark} id="final"alt="" />
                    Durante muito tempo, é a primeira vez que vejo tanto uma loja, 
                    quanto um site, que atendem a tudo que eu preciso 
                </span>
            </div>
        </div>
    )
}

export default Testimonial