import './index.css'
import '../Web/Checkout/index.css'
import Logo from '../../assets/img/logo/logo.png'
import Testimonial from '../../assets/img/login/testimonial.jpg'
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Row } from 'reactstrap';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput';
import {motion} from 'framer-motion/dist/framer-motion';
import CustomerService from '../../services/CustomerService';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Testimonials from "./Testimonials";

const spring = {
    type: "spring",     
    stiffness: 700,
    damping: 30
};

const SignUp = (props) => {
    const [failedFields, setFailedFields] = useState([]);
    const [isPhysicalPerson, setIsPhysicalPerson] = useState(true);
    const [customerData, setCustomerData] = useState({
        first_name: "",
        last_name: "",
        birth_date: "",
        ddd: "",
        telephone: "",
        address: "",
        address_number: "",
        address_complement: "",
        address_reference: "",
        address_district: "",
        address_city: "",
        address_state: "",
        email: "",
        password: "",
        password_confirmation: "",
        cep: "",
        cpf: "",
        cnpj: "",
        social_reason: "",
        fantasy_name: "",
        state_inscription: ""
    });

    const handleUserDataChange = (userDataKey, value) => {
        setCustomerData(prevState => {
            return {
                ...prevState,
                [userDataKey]: value
            }
        })
    }

    const handleUserRegistration = event => {
        event.preventDefault();

        toast.promise(CustomerService.createCustomer(customerData), {
            loading: "Criando usuário",
            success: "Usuário criado",
            error: "Não foi possível criar o usuário"
        }).then(() => {
            setTimeout(() => {
                props.history.push("/email-alert");
            }, 1500)
        }).catch(response => {
            if(response?.failed_fields) {
                setFailedFields(response?.failed_fields);
            } 
        });
    }

    const handleZipCodeAutoComplete = async () => {
        fetch(`https://viacep.com.br/ws/${customerData?.cep}/json`)
        .then(response => {
            return response.json();
        })
        .then(data => {
            setCustomerData(prevState => {
                return {
                    ...prevState,
                    address_district: data?.bairro,
                    address: data?.logradouro,
                    address_city: data?.localidade,
                    address_state: data?.uf
                }
            });    
        })
        .catch(() => {
            toast.error('Cep inválido.')
        });
    }

    const handlePhysicalPerson = isPhysicalPerson => {
        if(isPhysicalPerson) {
            setIsPhysicalPerson(isPhysicalPerson);
            clearCustomerSpecificValue("cnpj");
        }

        setIsPhysicalPerson(isPhysicalPerson);
        clearCustomerSpecificValue("cpf");
    }

    const clearCustomerSpecificValue = customerValue => {
        setCustomerData(prevState => {
            return {
                ...prevState,
                [customerValue]: ""
            }
        });
    }

    return(
        <div className='create-account-area'>
            <div className="slider-area"> 
                <div className="slider-area-content">
                    <div className="logo">
                        <Link to={'/'}>
                            <img src={Logo} alt="" />
                        </Link>
                    </div>
                    <div className="copy-area">
                        <span className='title-section-carousel'> Comece sua jornada com o LaNaMaria.</span>
                        <span className="text-section-carousel"> Venha descobrir o mundo  incrivel do artesanato, aprenda, explore e compartilhe, tudo e em um só lugar.</span>
                    </div>
                    <div className="testimonial-area">
                    <div className="container-testimonial">
                        <div className="profile-testimonial-area">
                            <div className="profile-testimonial-image">
                                <img src={Testimonial} alt=""/>
                            </div>
                            <span className='profile-identification'>
                                <span className='profile-identification-name'>Eric Santos</span>
                                <span className="profile-identification-post">Artesão</span>
                            </span>
                            </div>
                            <div className="testimonial-text">
                                "Depois que entrei no LaNaMaria, o meu amor por artesanato cresceu cada vez mais, eu pude criar receitas invcriveis no blog, além de conhecer pessoas novas que amam arte tanto quanto eu."
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
            <div className="info-form-area">
                <span className='info-title'>Cadastre-se</span>
                <div className="form-type">
                    <h4>Pessoa Física</h4>
                    <div className="handle-input-form-type-area" onClick={() => handlePhysicalPerson(!isPhysicalPerson)} style={{justifyContent: `${isPhysicalPerson ? "flex-start" : "flex-end"}`, cursor: "pointer"}}>
                        <motion.div className="handle" layout transition={spring}></motion.div>
                    </div>
                    <h4>Pessoa Júridica</h4>
                </div>
                    <Row>
                        <span className='init-form'>Informações Pessoais</span>
                        <div className="form-row column">
                           
                            <label htmlfor="name">Nome</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('first_name') && "failed-input"}`}  type="text" id="name" name="name" onChange={event => handleUserDataChange('first_name', event.target.value)} />
                            <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                        </div>
                        <div className="form-row inputs-checkouts column">
                            <label for="lastname">Sobrenome</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('last_name') && "failed-input"}`} type="text" id="lastname" name="lastname" icon="icon-park-outline:edit-name" onChange={event => handleUserDataChange('last_name', event.target.value)}/>
                            <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <div className={`form-row inputs-checkouts column ${isPhysicalPerson ? "fr-3" : "fr-3"}`}>
                            <label htmlFor="date_birth">Data de Nascimento</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('birth_date') && "failed-input"}`} type="date" id="date_birth" name="date_of_birth" min="1900-01-01" onChange={event =>{ handleUserDataChange('birth_date', event.target.value);}}/>
                        </div>
                        <div className="form-row inputs-checkouts column fr-2" style={{display: `${isPhysicalPerson ? "flex" : "none"}`}}>
                            <label htmlFor="tel">CPF</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('cpf') && "failed-input"}`} type="text" id='cpf' value={customerData?.cpf} name='CPF'onChange={event => handleUserDataChange('cpf', event.target.value)} style={{borderColor: `${failedFields.includes('cpf') && "red"}`}}/>
                            <Icon className='icon-input' icon="bi:people-fill" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <div className={`form-row inputs-checkouts column ${isPhysicalPerson ? "fr-3" : "fr-3"}`} style={{width: '15%'}}>
                            <span>
                            <label htmlFor="DDD">DDD</label>
                            
                            </span>
                           
                            <input type="text" id="DDD" placeholder="DDD" className={`inputs-form-checkout`} name='DDD' onChange={event => handleUserDataChange('ddd',  event.target.value)} maxlength="2" />
                            
                        </div>
                        <div className={`form-row inputs-checkouts column ${isPhysicalPerson ? "fr-3" : "fr-3"}`} style={{width: '60%'}}>
                            <label htmlFor="tel">Telefone</label>
                            <TelefoneBrasileiroInput
                                className={`inputs-form-checkout ${failedFields.includes('telephone') && "failed-input"}`}
                                value={customerData?.telephone}
                                
                                id="tel"
                                placeholder="Telefone"
                                onChange={event => handleUserDataChange('telephone',  event.target.value)}
                            />
                            <Icon className='icon-input' icon="ic:baseline-phone" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row style={{display: `${isPhysicalPerson ? "none" : "flex"}`}}>
                        <div className="form-row inputs-checkouts  column">
                            <label htmlFor="cnpj">CNPJ</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('first_name') && "failed-input"}`}  value={customerData?.cnpj} type="text" id='cnpj' name='CNPJ' onChange={event => handleUserDataChange('cnpj', event.target.value)} />
                            <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                        <div className="form-row inputs-checkouts  column">
                            <label htmlFor="fantasy_name">Nome Fantasia</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('fantasy_name') && "failed-input"}`} type="text" id="fantasy_name" name='fantasy_name'onChange={event => handleUserDataChange('fantasy_name', event.target.value)} />
                            <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row style={{display: `${isPhysicalPerson ? "none" : "flex"}`}}>
                        <div className="form-row inputs-checkouts  column">
                            <label htmlFor="corporate_name">Razão social</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('social_reason') && "failed-input"}`} type="text" placeholder="Razão Social" id="corporate_name" name='corporateName' onChange={event => handleUserDataChange('social_reason', event.target.value)} />
                            <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                        <div className="form-row inputs-checkouts  column">
                            <label htmlFor="state_registration">Ins. Estadual</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('state_inscription') && "failed-input"}`} type="text" placeholder="Ins. Estadual" id="state_registration" name='stateRegistration' onChange={event => handleUserDataChange('state_inscription', event.target.value)} />
                            <Icon className='icon-input' icon="fa-solid:building" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row className="my-5">
                        <span className='init-form'>Endereço</span>
                        <Row className="d-flex align-items-center">
                            <div className="form-row inputs-checkouts  column fr-2">
                                <label htmlFor="cep">CEP</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('cep') && "failed-input"}`} type="text"  id="cep" name='CEP' onChange={event => handleUserDataChange('cep', event.target.value)} />
                                <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                            {customerData?.cep.length > 0 &&
                                <div className="form-row inputs-checkouts column fr-2">
                                     <button type="button" className="verify-cep" onClick={handleZipCodeAutoComplete}> Verificar cep </button>
                                </div>                       
                            }
                        </Row>
                        <Row>
                            <div className="form-row inputs-checkouts  column fr-1">
                            <label htmlFor="adress">Endereço</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address') && "failed-input"}`} type="text" id="adress" name='Adress' value={customerData?.address} onChange={event => handleUserDataChange('address', event.target.value)} />
                                <Icon className='icon-input' icon="ri:home-5-fill" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                        </Row>
                        <Row>
                            <div className="form-row inputs-checkouts  column fr-2">
                            <label htmlFor="adress_number">Número</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_number') && "failed-input"}`} type="text"  id="adress_number" name='Adress_number' onChange={event => handleUserDataChange('address_number', event.target.value)} />
                                <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                            <div className="form-row inputs-checkouts column fr-2">
                            <label htmlFor="address_district">Bairro</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_district') && "failed-input"}`} type="text"  id="address_district" name='address_district' value={customerData?.address_district} onChange={event => handleUserDataChange('address_district', event.target.value)} />
                                <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                            </div>                 
                        </Row>
                        <Row>

                            <div className="form-row inputs-checkouts  column fr-2">
                            <label htmlFor="complete">Município</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_city') && "failed-input"}`} type="text" id="complete" name='address_city' value={customerData?.address_city} onChange={event => handleUserDataChange('address_city', event.target.value)} />
                                <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                            <div className="form-row inputs-checkouts  column fr-2">
                            <label htmlFor="address_state"> UF </label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_state') && "failed-input"}`} type="text" id="address_state" name='address_state' value={customerData?.address_state} onChange={event => handleUserDataChange('address_state', event.target.value)} />
                                <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                            </div>
                     
                        </Row>
                        <Row>
                            <div className="form-row inputs-checkouts  column fr-3">
                            <label htmlFor="complete">Complemento</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_complement') && "failed-input"}`} type="text" id="complete" name='Complete' onChange={event => handleUserDataChange('address_complement', event.target.value)} />
                                <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                            </div>  
                            <div className="form-row inputs-checkouts  column fr-1">
                            <label htmlFor="address_reference">Ponto de Referência</label>
                                <input className={`inputs-form-checkout ${failedFields.includes('address_reference') && "failed-input"}`} type="text" id="address_reference" name='address_reference' onChange={event => handleUserDataChange('address_reference', event.target.value)} />
                                <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                            </div>  
                        </Row>
                    </Row>                    
                    <Row>
                    <span className='init-form'>Informações da conta</span>
                        <div className="form-row inputs-checkouts column fr-1">
                            <label htmlFor="email">E-mail</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('email') && "failed-input"}`} type="text" id="email" name='Email' onChange={event => handleUserDataChange('email', event.target.value)} />
                            <Icon className='icon-input' icon="eva:email-outline" color="rgba(0, 0, 0, 0.324)" />

                        </div> 
                    </Row>     
                    <Row>
                        <div className="form-row inputs-checkouts column fr-1">
                            <label htmlFor="password_signup">Senha</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('password') && "failed-input"}`}  type="password" name="password" id='password_signup' defaultValue={customerData?.password}  onChange={event => handleUserDataChange('password', event.target.value)}/>
                            <Icon className='icon-input' icon="uil:padlock" />
                        </div> 
                    </Row> 

                    <Row>
                        <div className="form-row inputs-checkouts column fr-1">
                        <label htmlFor="password_signup">Confirmar senha</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('password_confirmation') && "failed-input"}`} type="password" name="password" id='password_signup' defaultValue={customerData?.password_confirmation}  onChange={event => handleUserDataChange('password_confirmation', event.target.value)}/>
                            <Icon className='icon-input' icon="uil:padlock" />
                        </div> 
                    </Row> 
                    <button type='submit' className='finish-signup'  onClick={event => handleUserRegistration(event)}> Cadastrar </button>
            </div>
        </div>
    )
}

export default SignUp;