import api from "./api";

class PageService {
    pagesId = {
        "termos": 1,
        "cookies": 2,
        "privacidade": 3
    }

    async getLgpdPageByPageName(page) {
        const pageId = this.pagesId[page];

        const response = await api.get(`/store/pages/${pageId}/show`);
        return response;
    }
}

export default new PageService();