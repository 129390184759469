import './index.css'
import { Icon } from '@iconify/react';
import {Link} from 'react-router-dom'
import { toast } from 'react-hot-toast';
import stars from '../../assets/img/stars.png';
import { useState, useContext, useEffect } from 'react';

import AuthContext from '../../context/auth/AuthContext';
import CartContext from '../../context/cart/cartContext';
import WishlistService from '../../services/WishlistService';
import WishlistContext from '../../context/wishlist/wishlistContext';

const noPhoto = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg"

const ProuctCardComponent = ({ item }) => {
    const authContext = useContext(AuthContext);
    const cartContext = useContext(CartContext);
    const wishlistContext = useContext(WishlistContext);

    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        productIsInsertedOnWishlist();
    }, [authContext]);

    const productIsInsertedOnWishlist = () => {
        const wishlistExists = localStorage.hasOwnProperty("wishlist-products");
        
        if(wishlistExists) {
            const productId = item?.CodigoInterno_Produto;
            const wishlistProducts = JSON.parse(localStorage.getItem("wishlist-products"));
            const productAlreadyInserted = wishlistProducts.includes(productId);
    
            const isUserLoggedIn = authContext.isAuthenticated;
            if(productAlreadyInserted && isUserLoggedIn) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false)
            }
        }
    }

    const handleInsertProductInWishlist = () => {
        if (!authContext.isAuthenticated) {
            toast.error('Faça seu login para adicionar o produto na lista de desejos.');
            return;
        }

        const userId = authContext?.user?.id;
        const productId = item?.CodigoInterno_Produto;

        const loadingMessage = toast.loading("Atualizando lista de desejos.");

        WishlistService.insertProduct(userId, productId)
        .then(response => {
            const productData = response.data;
            const productWasInserted = response.status === 201;

            if(productWasInserted) {
                toast.remove(loadingMessage);
                toast.success("Produto inserido na lista de desejos.");
                
                setIsFavorite(true);
                wishlistContext.insertProduct(productData);
            } else {
                setIsFavorite(false);
                toast.success("Produto removido da lista de desejos.");
                wishlistContext.removeProduct(productId);
            }
            
            toast.remove(loadingMessage);
        });
    }
    
    if(item?.PrecoVendaECommercePraticado_ProEmpEmbPre > 0) {
        return(
            <div className="product" style={{minHeight:'418px'}}>
                <div className="image-product" >
                    <a href={`/produto/${item?.CodigoInterno_Produto}/${item?.has_variants ? "exemplo-3" : "exemplo-1"}`}>
                        <img src={item?.image ? process.env.REACT_APP_IMAGE_BASE_URL+ item?.image?.path + item?.image?.name  : noPhoto} alt="" />
                    </a>
                    <Icon 
                        icon={`mdi:cards-heart${isFavorite ? "" : "-outline"}`} 
                        onClick={handleInsertProductInWishlist} 
                        className={`favorite ${isFavorite && "animate"}`} 
                        color="772AA0" 
                        width="24" 
                        height="24"
                    />
                </div>
                <div>
                </div>
                <h4 className="name-product">{item?.Nome_Produto}</h4>
                <div className="ranking">
                    {[...Array(5)].map((value, index) => {
                            return (
                                <Icon 
                                    key={index} 
                                    width="25px" 
                                    color={((item?.product_rating?.average_rating - 1 >= index) ) ? "#FFD700" : "#CCCCCC"}
                                    icon="material-symbols:star-rounded" 
                                />
                            );
                        })}
                    {item?.product_rating && 
                        <h3 className="ranking-number">
                            {Number(item?.product_rating?.average_rating)?.toFixed(1)?.replace('.', ',')} ({item?.product_rating?.total_rating})
                        </h3>
                    }
                </div>  
                <div className="price">
                    {/* <h3 className="old-price">R$ 10,99</h3> */}
                    <h3 className="actual-price">R$ {item?.PrecoVendaECommercePraticado_ProEmpEmbPre.toFixed(2).replace('.',',')}</h3>
                </div>
                {item?.is_available_in_stock || item?.has_variants?
                    <>
                        {item?.has_variants ?
                            <Link to={`/produto/${item?.CodigoInterno_Produto}/exemplo-3`}>
                                <button className="add-to-cart">
                                    <Icon icon="uil:search"  width="24" height="24"  /> Ver Variantes
                                </button>
                            </Link>
                            :
                            <button className="add-to-cart" onClick={() => cartContext.addProduct(item)}>
                                <Icon icon="carbon:add" width="24" height="24" color='white' /> Adicionar ao Carrinho
                            </button>
                        }
                    </>
                    :
                    <button className="not-available-in-stock">
                        <Icon icon="iconamoon:unavailable" width="24" height="24" color='white' /> Estoque indisponível
                    </button>
                }
            </div>
        )
    } else {
        return  <> </>
    }
}

export default ProuctCardComponent;