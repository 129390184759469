import api from './api';
import DefaultLogo from "../assets/img/logo/logo_lanamaria-01-o57qh9e078s9h3a30grmklnt62j6sjsekxxo9hcy2o.png";

class ImageService {
    constructor() {
        this.activeLogoPath = DefaultLogo;
    }

    /**
     * Set active logo
     * @param {string} logoPath
     * @returns {Promise}
     */
    async setActiveLogo(logoPath) {
        this.activeLogoPath = logoPath.toString();
    }

    /**
     * Get active logo path
     * @returns {string}
     */
    async getActiveLogoPath() {
        return this.activeLogoPath;
    }

    /**
     * Get all banners
     * @returns {Promise}
     */
    async getAllBanners() {
        const response = await api.get('admin/images/banners/index');
        return response.data;
    }

    /**
     * Get active logo
     * @returns {Promise}
     **/
    async getActiveLogo() {
        const response = await api.get('admin/images/logos/active');
        return response.data;
    }

    /**
     * Get the banner for department page 
     *
     * @param {Integer} departmentId
     * @return {Promise}
     */
    async getDepartmentBannerByDepartmentId(departmentId) {
        const response = await api.get(`admin/images/banners/department-banner/department?id=${departmentId}`);
        return response.data;
    }
}

export default new ImageService();
