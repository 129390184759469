import api from "./api";
import {useContext} from "react";
import AuthContext from "../context/auth/AuthContext";

class CustomerService {
    async createCustomer(customerData) {
        const ddd = customerData.ddd ? `(${customerData.ddd})` : "";
        const requestBody = {
            ...customerData,
            cnpj: customerData.cnpj.replace(/[^\w\s]/gi, ''),
            cpf: customerData.cpf.replace(/[^\w\s]/gi, ''),
            cep: customerData.cep.replace(/[^\w\s]/gi, ''),
            telephone: ddd.concat(customerData.telephone)
        };

        const response = api.post("/store/customers/create", requestBody);
        return response;
    }

    async login(email, password) {
        const requestBody = {
            email,
            password
        }

        const response = api.post("/store/auth/login", requestBody);
        return response;
    }

    async sendPasswordRecoveryEmail(userEmail) {
        const response = api.get(`store/customers/recover-password?email=${userEmail}`);
        return response;
    }

    async getUserById(userId) {
        const response = api.get(`store/customers/show?user_id=${userId}`);
        return response;
    }

    async updateCustomerById(userId, updatedData,) {
        const requestBody = {
            user_id: userId,
            ...updatedData,
            cnpj: updatedData?.cnpj ? updatedData.cnpj.replace(/[^\w\s]/gi, '') : '',
            cpf: updatedData?.cpf ? updatedData.cpf.replace(/[^\w\s]/gi, '') : '',
            zipcode: updatedData?.zipcode ? updatedData.zipcode.replace(/[^\w\s]/gi, '') : '',
        }

        const response = api.patch("store/customers/update", requestBody);
        return response;
    }

    async addCustomerAddress(userId, customerAddress) {
        const requestBody = {
            user_id: userId,
            ...customerAddress,
            zipcode:  customerAddress.zipcode.replace(/[^\w\s]/gi, '')
        }

        const response = await api.post('store/customers/new-address', requestBody);
        return response;
    }

    async updateCustomerAddress(addressData) {
        const requestBody = {
            ...addressData,
            zipcode: addressData?.zipcode?.replace(/[^\w\s]/gi, '')
        }
        
        await api.patch("/store/customers/update-address", requestBody);
    }

    async setAddressAsDefault(customerId, addressId, isDefault) {
        const requestBody = {
            customerId,
            addressId,
            isDefault
        }

        await api.patch("/store/customers/default-address", requestBody);
    }

    async deleteAddressById(addressId) {
        await api.delete(`/store/customers/delete-address?address_id=${addressId}`);
    }

   async session(redirect) {
        const response = api.get("/store/auth/session", { redirect });
        return response;
   }    
}

export default new CustomerService;