import api from "./api";

class PaymentService {
    async creditCardPayment(cardData) {
        const requestBody = {
            "card_owner_name": cardData?.cardOwnerName,
            "card_number": this.removeCardNumberEmptySpaces(cardData?.cardNumber),
            "expiration_month": cardData?.expirationMonth,
            "expiration_year": cardData?.expirationYear,
            "security_code": cardData?.securityCode,
            "installments": cardData?.installments,
        }

        const response = await api.post('/store/orders/payment-method/credit-card', requestBody);
        return response.data;
    }

    async debitCardPayment(cardData) {
        const requestBody = {
            "card_owner_name": cardData?.cardOwnerName,
            "card_number": this.removeCardNumberEmptySpaces(cardData?.cardNumber),
            "expiration_month": cardData?.expirationMonth,
            "expiration_year" : cardData?.expirationYear,
            "security_code": cardData?.securityCode,
        }

        const response = await api.post('/store/orders/payment-method/debit-card', requestBody);
        return response.data;
    }

    async getInstallmentsValue(cartId) 
    {
        const response = await api.get(`/store/orders/installments?cart_id=${cartId}`);
        return response.data;
    }

    removeCardNumberEmptySpaces(cardNumber) {
        const numberWithoutSpaces = cardNumber?.split(" ")?.join("");
        return numberWithoutSpaces;
    }
}

export default new PaymentService();