import React from "react";
import './index.css'
import Facebook from '../../../src/assets/img/Facebook.png';
import Twitter from '../../../src/assets/img/Twitter.png';
import Instagram from '../../../src/assets/img/Instagram.png';
import Youtube from '../../../src/assets/img/Youtube.png';

function SocialMedia(){
    return(
        <div>
            <div className="containerSocialMedia pb-80 pt-80">
                <div className="contentSocialMedia">
                    <h1 className="social-copy">Siga-nos nas redes sociais</h1>
                    <div className="social-media-icons">
                        <a href="https://www.facebook.com/lojalanamaria/" target="_blank">
                            <img src={Facebook} alt="facebook"/>
                        </a>
                        <a href="https://www.instagram.com/lojalanamaria/" target="_blank">
                            <img src={Instagram} alt="instagram"/>
                        </a>
                        {/* <img src={Instagram} alt="twitter" />
                        <img src={Youtube} alt="youtube" /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialMedia;