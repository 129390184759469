import React from "react";
import './index.css';

import { Link } from "react-router-dom";

function BlogRecent({ posts }){
    return(
        <div className="containerRecent" >
                <div className="contentRecent">
                    <h3 className="Title">Mais Recentes</h3>
                    <div className="boxRecent">
                        <div className="card oneLeft" style={{backgroundImage: `url(https://adminlanamaria.justisecure.com.br${posts?.[0]?.image?.path}${posts?.[0]?.image?.name})`}}>
                            <Link to={`/blog/${posts?.[0]?.id}/postagem-blog`} className="Link">
                                    <span href="#" className="categories">{posts?.[0]?.category}</span>
                                    <h3 className="title-notice-blog">{posts?.[0]?.title}</h3>
                            </Link>
                        </div>
                        
                        <div className="card rightFirst"  style={{backgroundImage: `url(https://adminlanamaria.justisecure.com.br${posts?.[1]?.image?.path}${posts?.[1]?.image?.name})`}}>
                            <Link to={`/blog/${posts?.[1]?.id}/postagem-blog`} className="Link">
                                <span href="#" className="categories">{posts?.[1]?.category}</span>
                                <h3 className="title-notice-blog">{posts?.[1]?.title}</h3>
                            </Link>
                        </div>
                        <div className="card rightSecond"  style={{backgroundImage: `url(https://adminlanamaria.justisecure.com.br${posts?.[2]?.image?.path}${posts?.[2]?.image?.name})`}}>
                                <Link to={`/blog/${posts?.[2]?.id}/postagem-blog`} className="Link">
                                    <span href="#" className="categories">{posts?.[2]?.category}</span>
                                    <h3 className="title-notice-blog">{posts?.[2]?.title}</h3>
                                </Link>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default BlogRecent;