// imports
import './index.css'
import ProductService from '../../services/ProductService'

//libs
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
// components and hooks
import { useState } from 'react'

const NewMenu = ({ departments }) => {
    const [menuMargin, setMenuMargin] = useState(false)
    const [selectDepartament, setSelectDepartament] = useState([])
    const [showMenu, setShowMenu] = useState(false)
    const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL

    return(
        <div className="container new-menu-container">
            <div className="new-menu">
                <span className='button-next-area'><Icon icon="material-symbols:arrow-back-ios-rounded" width="22px" className="button-next" onClick={() => setMenuMargin(false)}/></span>
                <div className="box-content">
                    <ul className={`list-departaments ${menuMargin ? 'active' : ''}`}>
                        {departments?.map(item =>{
                            return (
                                <a href={`/departamento/${item.CodigoInterno_DepartamentoCommerce}`} key={item?.CodigoInterno_DepartamentoCommerce}>
                                    <li className="departament" onMouseLeave={()=>{setShowMenu(false)}} onMouseEnter={()=> {setSelectDepartament(item); setShowMenu(true)} }>
                                        <img className='departament-image' src={`${baseUrl}/images/departments/${item?.CodigoInterno_DepartamentoCommerce}.png`} alt="" />
                                        {item.Nome_DepartamentoCommerce}
                                    </li>
                                </a>
                            )
                        })}
                    </ul>
                </div>
                <span className='button-next-area'><Icon icon="material-symbols:arrow-back-ios-rounded" width="22px" hFlip={true} className="button-next" style={{paddingLeft: '0', paddingRight: '7px'}} onClick={() => setMenuMargin(true)}/></span>
            </div>
            {selectDepartament?.commerce_sections?.length > 0 &&
                <div className="mega-menu-area" style={{display: `${showMenu ? 'block' : 'none'}`}}  onMouseEnter={()=> { setShowMenu(true)}} onMouseLeave={()=>{setShowMenu(false)}}>
                    <div className="mega-menu-list">
                            {selectDepartament.commerce_sections?.map(sections => {
                                return (
                                    <ul className='sections' key={sections?.CodigoInterno_SecaoCommerce}>
                                        <span className='title-sections'>
                                            <a href={`/departamento/${selectDepartament?.CodigoInterno_DepartamentoCommerce}/g/${sections?.CodigoInterno_SecaoCommerce}`}> 
                                                {sections?.Nome_SecaoCommerce}
                                            </a>
                                        </span>
                                        {sections?.commerce_groups?.map(group => {
                                            return(
                                                <li key={group?.CodigoInterno_GrupoCommerce}>
                                                    <a href={`/departamento/${selectDepartament?.CodigoInterno_DepartamentoCommerce}/s/${group?.CodigoInterno_GrupoCommerce}`}>
                                                        <h6 className='subgroup-list'>{group?.Nome_GrupoCommerce}</h6>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                );
                            })}    
                    </div>
                </div> 
            }
        </div>
     )
}

export default NewMenu;