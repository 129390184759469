import React from "react";
import './index.css';

// Components
import {Modal, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";

// Assets
import SuccessGif from '../../../assets/img/gif-success.gif'

const ModalWarning = ({message, buttonMessage, closeButtonMessage = 'fechar',  showModal, setShowModal, callbackFunction = null}) => {

    return(
        <div>
            <Modal isOpen={showModal} centered={true} toggle={()=> setShowModal(!showModal)} >
                <ModalBody>
                        {/* <img src={SuccessGif} alt="" /> */}
                        <h4 className="title-modal">{message}</h4>
                        
                        <button className="back-to-store" style={{color: '#fff'}} onClick={callbackFunction} >{buttonMessage}</button>
                        <button className="back-to-store" style={{color: '#fff'}} onClick={() => setShowModal(false)}>{closeButtonMessage}</button>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalWarning;