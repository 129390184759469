import { render } from 'preact';
import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ProductBannerIndex = ({ productData }) => {
    return (
        <div className="page-header-area bg-img" style={{ backgroundImage: 'url(https://template.hasthemes.com/argima/argima/assets/img/photos/bg-02.jpg)' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="page-header-content">
                            <nav className="breadcrumb-area">
                                <ul className="breadcrumb">
                                    <li>
                                        <Link to={'/'}>
                                            <b>Início</b>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-sep">
                                        <i className="fa fa-angle-right"></i>
                                    </li>
                                    {productData && 
                                        <li>
                                          <Link to={`/produto/${productData?.CodigoInternoPaiGrade_Produto}/exemplo-3`}>  <b>{productData?.main_product?.Nome_Produto}</b> </Link>
                                        </li>
                                    }
                                    <li className="breadcrumb-sep">
                                        <i className="fa fa-angle-right"></i>
                                    </li>
                                    <li>
                                    <Link to={`/produto/${productData?.CodigoInterno_Produto}/exemplo-1`}> <b>{productData?.Nome_Produto}</b> </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductBannerIndex;