
import './index.css'
import { useContext, useState } from 'react';
import { Icon } from '@iconify/react';

import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import CartItemSideBar from './CartItemSideBar';

import LoginModal from '../../LoginModal';
import AuthContext from "../../../context/auth/AuthContext";
import CartContext from '../../../context/cart/cartContext';

const CartSideBar = ({show, setShow}) => {
    const history = useHistory();
    const modalRef = useRef(null);

    const cartContext = useContext(CartContext);
    const authContext = useContext(AuthContext)

    const [modal, setModal] = useState(false)

    const isLoggedIn = authContext.isAuthenticated;

    useEffect(()=>{
        handleOverflow(show)
    },[show])

    useEffect(() => {
        function handleClickOutside(event) {
            if(modalRef.current && show && !modalRef.current.contains(event.target)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        
    }, [modalRef, show]);

    const checkAuth = () =>{
        if(!isLoggedIn){
            setModal(true);
        } else {
            history.push('/carrinho');
        }
    }

    const handleOverflow = show => {
        document.documentElement.style.overflowY = show ? 'hidden' : 'auto';
    }


    return(
        <div ref={modalRef} className={`cart-sidebar-container ${show && 'cart-open'}`}>
                <div className="top-side">
                    <Icon icon="gg:close-r" className='btn-close-cart-sidebar' color='#9D7FAD' height="24px" width="24px" onClick={()=> setShow(false)}/>
                    <h3>Resumo das compras</h3>
                </div>
                <LoginModal showModal={modal} setShowModal={setModal} redirectUrl="/carrinho"/>
                <CartItemSideBar/>
                
                <div className="result-cart">
                    <span className='flex-btns'>
                    <div className="price-list final" >
                        <h5 style={{display: 'flex', alignItems: 'center'}}>Total</h5>
                        <h5 style={{display: 'flex', alignItems: 'center', marginRight: '15px'}} className="descont">R$ {cartContext?.totalPrice}</h5>
                    </div>
                        <button className='confirm-purchase' onClick={checkAuth}>Finalizar Compra</button>
                    </span>
                </div>
        </div>
    );
}

export default CartSideBar;


