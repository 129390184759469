import React, { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';
import ImageService from "../../../../services/ImageService";
import './index.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function BannerIndex(props) {
    const history = useHistory();

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        loadBanners();
    }, []);

    const loadBanners = () => {
        ImageService.getAllBanners().then((data) => {
            setBanners(data);
        });
    }

    return (
        <section className="home-slider-area">
            <div className="home-slider-container">
                <AliceCarousel
                    autoPlay
                    autoPlayInterval={7000}
                    animationDuration={1000}
                    infinite
                >
                    {banners?.length > 0 &&
                        banners?.map( banner => (
                            <a href={`${banner?.redirect_to !== null ? banner?.redirect_to : '#' }`}>
                                <div key={banner.id} className="container-slide" style={{backgroundImage: `url("${process.env.REACT_APP_IMAGE_BASE_URL + banner?.image_path}")`}}>
                                    <h3 className="title-slide" style={{color: `${banner?.hex_color_title}`}}>{banner.title}</h3>
                                    <h3 className="text-slide" style={{color: `${banner?.hex_color_text}`}}>{banner.text}</h3>
                                </div>
                            </a>
                        ))}
                </AliceCarousel>
            </div>
        </section>
    )
}

export default BannerIndex;