function CartIndex() {

    this.produtos = [];

    this.getProdutos = () => {
        return this.produtos
    };

    this.addProdutos = (child, quantidade) => {

        let childIndex = this.produtos.findIndex(p => p.child.CodigoInterno_Produto === child.CodigoInterno_Produto);

        if (childIndex === -1) {
            this.produtos.push({ child: child, quantidade: quantidade });
        } else {
            this.produtos[childIndex].quantidade += quantidade;
        }

        this.triggerEvent();
    }

    this.callbacks = [];

    this.onCartChange = (callback) => {
        this.callbacks.push(callback)
    }

    this.triggerEvent = () => {
        this.callbacks.map((callback) => {
            callback(this.produtos);
        })
    }
}

export default new CartIndex;