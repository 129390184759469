
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ResumeBenefits from "../Web/HomePage/ResumeBenefits";
import SocialIndex from "../Web/Home/Partials/Social/Index";
import Footer from "../Web/HomePage/Footer";
import ProductService from "../../services/ProductService";
import ProductCardComponent from '../../components/ProductCardComponent';


function Index() {
    const { searchValue } = useParams();
    
    const [page, setPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [showProductsNotFoundMessage, setShowProductsNotFoundMessage] = useState(false);

    useEffect(() => {
        getProducts()
        setLoading(true);
        setShowProductsNotFoundMessage(false);
    }, [searchValue, page, productsPerPage]);

    const getProducts = () => {
        ProductService.searchProducts(searchValue, page, productsPerPage).then(products => {
            if(products?.data?.length > 0) {
                setShowProductsNotFoundMessage(false);
            } else {
                setShowProductsNotFoundMessage(true);
            }

            setProducts(products);
        }).finally(() => {
            setLoading(false);
        })
    }

    const increasePage = () => {
        const updatedPage =  Math.min(page + 1, products?.last_page);

        setPage(updatedPage);
   }

   const decreasePage = () => {
        const updatedPage = Math.max(page - 1, 1);

        setPage(updatedPage);
    }

    return (
        <div className="wrapper home-default-wrapper">
            <Header />
            <main className="main-content">
                <div className="page-header-area bg-img" style={{ backgroundImage: "url(https://template.hasthemes.com/argima/argima/assets/img/photos/bg-02.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="page-header-content">
                                    <nav className="breadcrumb-area">
                                        <ul className="breadcrumb">
                                            <li>
                                                <Link to={'/'}>
                                                    <b>Início</b>
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-sep">
                                                <i className="fa fa-angle-right"></i>
                                            </li>
                                            <li>
                                                <b>Busca</b>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="product-area">
                    <div className="container mt-41">
                        <div className="row">
                            <div className="col-lg-12 order-0 order-lg-2">
                                {loading && <div className="container-lds"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
                                {(!loading && products?.data?.length > 0) && (
                                    <>
                                        <div className='FilterPerPage'>
                                            <select value={productsPerPage} name="productsPerPage" onChange={ event => setProductsPerPage(event.target.value) }>
                                                <option value={15}>15 Produtos por página</option>
                                                <option value={30}>30 Produtos por página</option>
                                                <option value={60}>60 Produtos por página</option>
                                                <option value={105}>105 Produtos por página</option>
                                            </select>
                                        </div> 
                                            <div className="row ">
                                                <div className="col-9 container d-flex flex-wrap">
                                                    {products?.data?.map(product => (
                                                        <ProductCardComponent item={product} wish={true} />
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="pagination-content-wrap">
                                                    <nav className="pagination-nav">
                                                        <ul className="pagination justify-content-center">
                                                            <li>
                                                                <a href="#/" onClick={() => setPage(1)}>1</a>
                                                            </li>
                                                            
                                                            <li >
                                                                <a href="#/" style={{display: "flex", justifyContent: "center", alignItems:"center"}}  onClick={decreasePage}>
                                                                    <i className="icon-arrow-left"></i>
                                                                </a>
                                                            </li>

                                                            <li><a className="active" href="#/">{page}</a></li>
                                                            
                                                            <li>
                                                                <a href="#/" onClick={increasePage} style={{ display: "flex", justifyContent: "center", alignItems:"center"}}>
                                                                    <i className="icon-arrow-right"></i>
                                                                </a>
                                                            </li>
                                                            
                                                            <li>
                                                                <a href="#/" onClick={() => setPage(products?.last_page)}>{products?.last_page}</a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showProductsNotFoundMessage && 
                                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                        <h1 style={{textAlign:'center', fontSize:'24px'}}>Parece que nenhum produto foi encontrado com esse critério de pesquisa.</h1>
                                        <p style={{textAlign:'center'}}>Tente novavmente com outro termo para busca ou volte para loja</p>
                                        <a href="/" style={{padding:'12px 32px', backgroundColor:'#9d7fad', color:'white', borderRadius:'12px', textAlign:'center'}}>Voltar para a loja</a>
                                    </div>
                                }
                            </div> 
                        </div>
                    </div>
                </section>

                <section className="divider-area">
                    <div className="container pt-90 pt-lg-70 pb-lg-60">
                        <div className="row">
                            <div className="col-12">
                                <div className="divider-style-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <SocialIndex />
                                        </div>
                                        {/* 
                                        <div className="col-md-6">
                                            <NewsletterIndex />
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Index;