import './index.css'
import EmailGif from '../../../assets/img/gifs/email-gif-alert.gif'
import { Link } from 'react-router-dom'

const AlertEmail = () => {
    return(
        <div className='alert-email-container'>
            <div className="alert-box">
                <img src={EmailGif} className="gif-email-alert" alt="" />
                <span className='email-span-alert'>Um email foi enviado para você!</span>
                <span className='email-desc-alert'>Faça a verificação no email cadastrado, e para continuar e desfrutar de tudo dentro do lanamaria</span>
                <Link to="/">  
                    <button className='back-to-store'>Volta para a loja</button>
                </Link>
            </div>        
        </div>
    )
}

export default AlertEmail