import ProuctCardComponent from '../../ProductCardComponent';
import './index.css'
import { Link } from 'react-router-dom';
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const  noPhoto = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";

const SingleBannerSection = ({ banner }) => {
    return(
        <div className="banner-special-section" style={{backgroundColor: banner?.background_color}}>
            <div className="banner-top">
                <img src={imageBaseUrl + banner?.main_banner?.path + banner?.main_banner?.name} alt="" />
            </div>
            <div>
                <div className="product-list">
                    {banner?.banner_product?.map(product => (
                        
                        <div key={product?.id} className="banner-product">
                            <ProuctCardComponent wish={true} item={product.product_data}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SingleBannerSection;