import { Divider } from "antd";
import React from "react";

import About from "../../components/About";

const AboutUs = () => { 
    return(
        <div>
            <About/>
        </div>
    );
}

export default AboutUs;