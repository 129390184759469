import { BrowserRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import Error404 from "./views/Error/404";
import DepartmentIndex from "./views/Departament/Index";
import ProductSearch from "./views/ProductSearch/Index";
import AboutUs from "./views/AboutUS";
import Example1 from "./views/Web/Product/Example1";
import Example2 from "./views/Web/Product/Example2";
import Example3 from "./views/Web/Product/Example3";
import ScrollToTopIndex from "./components/ScrollToTop/Index";
import BlogPost from "./views/Web/Blog/BlogPost";
import PostList from "./views/Web/Blog/PostList";
import HomePage from "./views/Web/HomePage";
import SignUp from "./views/SignUp";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { useContext } from "react";
import AuthContext from "./context/auth/AuthContext";
import ForgotPasswordPage from "./components/Profile/ForgotPasswordPage";
import LgpdPages from "./views/LgpdPages";
import AlertEmail from "./views/Alerts/AlertEmail";
import LoginModal from "./components/LoginModal";

function Routes() {
    const location = useLocation();
    const authContext = useContext(AuthContext)

    return (
        <BrowserRouter>
            <ScrollToTopIndex />
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/404" component={Error404} />
                <Route exact path="/blog/:id/postagem-blog" component={BlogPost} />
                <Route exact path="/blog/externo" component={PostList} />
                <Route exact path="/departamento/:department/" component={DepartmentIndex}/>
                <Route exact path="/departamento/:department/g/:group" component={DepartmentIndex}/>
                <Route exact path="/departamento/:department/s/:subgroup" component={DepartmentIndex}/>
                <Route exact path="/sobre-nos" component={AboutUs}/>
                <Route exact path="/cookies" component={LgpdPages} />
                <Route exact path="/termos-de-uso" component={LgpdPages} />
                <Route exact path="/privacidade" component={LgpdPages} />
                <Route exact path="/produto/:external_code/exemplo-1" component={Example1} />
                <Route exact path="/produto/:external_code/exemplo-2" component={Example2} />
                <Route exact path="/produto/:external_code/exemplo-3" component={Example3} />
                <Route exact path="/cadastre-se" component={SignUp} />
                <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                <Route exact path="/email-alert" component={AlertEmail} />
                <Route exact path="/validation" component={LoginModal} />
                <Route exact path="/busca/:searchValue" component={ProductSearch}/>
                <ProtectedRoutes />
                {authContext?.isAuthenticated !== null &&  <Redirect from="*" to={`/?redirect_url=${location.pathname}`}/>}
                <Route exact path="*" component={Error404}/>              ?
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;