import api from "./api";

class FreightService { 
    async calculateCartFreight(destinationCep) {
        const requestBody =  {
            destination: destinationCep.replace(/[^\w\s]/gi, '')
        }
        
        const response = await api.post('/store/freight/calculate-cart', requestBody);
        return response.data;
    }

    async calculateProductFreight(destinationCep, productId, quantity)
    {   
        const destination = destinationCep.replace(/[^\w\s]/gi, '');
        
        const response = await api.get(`/store/freight/calculate-product?product_id=${productId}&destination_cep=${destination}&product_quantity=${quantity}`);
        return response;
    }

    async saveFreightData(deliveryAddressId, freightData) {
        const requestBody = {
            selected_delivery_address: deliveryAddressId,
            selected_freight_service: freightData?.codigo,
            selected_freight_price: freightData?.valor,
            selected_freight_name: freightData?.servico
        }

        const response = await api.patch('/store/freight/select-freight', requestBody);
        return response.data;
    }
}


export default new FreightService();