import "./index.css"
import { useContext, useEffect, useState } from "react"  
import Header from '../../../components/Header'
import OrderImg from '../../../assets/img/orders.png'
import Order from "./Order"
import authContext from "../../../context/auth/AuthContext"
import OrderService from "../../../services/OrderService"
import { toast } from "react-hot-toast"
import { Icon } from "@iconify/react"

const OrdersPage = () => {
    const storeUser = useContext(authContext)
    
    const [orders, setOrders] = useState([])
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderFilters, setOrderFilters] = useState({
        filterByStatus: "",
        orderByCreationDate: "",
        showClearFiltersButton: false
    });
    
    useEffect(() => {
        getAllOrdersAvailableStatus()
    }, [])

    useEffect(()=>{
        getOrderList()
    }, [orderFilters.filterByStatus, orderFilters.orderByCreationDate])
    
    const getOrderList = () => {
        const customerId = storeUser?.user.id;

        toast.promise(OrderService.getCustomerOrders(customerId, orderFilters), {
            loading: 'Carregando pedidos...',
            success: 'Pronto!',
            error: 'Não foi possível carregar os pedidos!'
        }).then(response => {
            setOrders(response.data);
        })
    }

    const getAllOrdersAvailableStatus = () => {
        OrderService.getOrderStatuses().then(statuses => {
            setOrderStatuses(statuses)
        });
    }

    const handleFilterChange = event => {
        const inputValue = event.target.value;
        const inputName = event.target.name;

        setOrderFilters(prevState => {
            return {
                ...prevState,
                [inputName]: inputValue,
                showClearFiltersButton: true
            }
        });
    }

    const handleClearFilters = () => {
        setOrderFilters({
            filterByStatus: "",
            orderByCreationDate: "",
            showClearFiltersButton: false
        });
    }

    return(
        <div className="order-page-container">
            <Header/>
            <div className="title-order-page-area">
                <Icon icon="material-symbols:order-approve" color="#9d7fad"  width='64px' /> 
                <span style={{marginLeft: '10px',marginBottom: '-10px'}}>Seus Pedidos</span>
            </div>
            <div className="list-orders-container">
                <div className="filters">
                    {orderFilters.showClearFiltersButton &&
                        <button className="btn" onClick={handleClearFilters}>
                            Limpar filtros
                        </button>
                    } 
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="ordenation-text">Ordenar pedidos por status:</div>
                        <select value={orderFilters.filterByStatus} name="filterByStatus" onChange={event => handleFilterChange(event)} className="form-select  search-area">
                            <option disabled value="">Sem filtros</option>
                            {orderStatuses?.map(status => (
                                <option key={status?.id} value={status?.id}>{status?.status_name}</option>
                                ))}
                        </select>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="ordenation-text">Ordenar pedidos por data:</div>
                        <select value={orderFilters.orderByCreationDate} name="orderByCreationDate" onChange={event => handleFilterChange(event)} className="form-select search-area">
                            <option disabled value="">Sem filtros</option>
                            <option value="desc" >Mais Recentes</option>
                            <option value="asc">Mais Antigos</option>
                        </select>
                    </div>                        
                </div>
                {orders?.map(list => {
                    return(
                        <Order listOrder={list} key={list?.id}/>
                    )
                })}
            </div>
        </div>
    )
}


export default OrdersPage