import api from "./api";

class CartService {
    async getUserCart(user) {
        const response = await api.get("/store/cart/details");
        return response.data;
    }

    async insertProduct(productCode, productAmount = 1, pack) {
        const requestBody = {
            selected_package: pack,
            product_code: productCode,
            product_amount: productAmount
        }

        const response = await api.post("/store/cart/insert-product", requestBody);
        return response.data;        
    }

    async removeProduct(productCode) {
        const response = await api.delete(`/store/cart/remove-product?product_id=${productCode}`);
        return response.data;
    }

    async increaseProductQuantity(productCode) {
        const response = await api.patch(`/store/cart/increase-product-quantity?product_id=${productCode}`);
        return response.data;
    }

    async decreaseProductQuantity(productCode) {
        const response = await api.patch(`/store/cart/decrease-product-quantity?product_id=${productCode}`);
        return response.data;
    }
}

export default new CartService;