import React from "react";
import './index.css';

// Components
import {Modal, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom";

// Assets
import SuccessGif from '../../../assets/img/gif-success.gif'

const ModalSucesss = ({ modalView, setModalView, onClosedCallback = undefined }) => {

    return(
        <div>
            <Modal isOpen={modalView} centered={true} toggle={()=> setModalView(!modalView)} onClosed={onClosedCallback}>
                <ModalBody>
                        <img src={SuccessGif} alt="" />
                        <h4 className="title-modal">Compra feita com sucesso!</h4>
                        <button className="back-to-store"><Link style={{color: '#fff'}} to={'/'}>Voltar para a loja</Link></button>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalSucesss;