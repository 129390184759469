import './index.css'

import { useState, useEffect, useContext } from "react";

import { Icon } from "@iconify/react";
import { Row } from "reactstrap";
import toast from "react-hot-toast";
import ReactInputMask from "react-input-mask";

import CartContext from '../../../../context/cart/cartContext';
import PaymentService from '../../../../services/PaymentService';

import ButtonBackAnimate from "../../CartPage/ButtonBackAnimate";
import ModalWarning from "../../../../components/Modals/ModalWarning";

import Wave from '../../../../assets/img/Cart/wave-final-credit.png';

const defaultDebitCardState = { 
    cardOwnerName: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    securityCode: "",
    installments: ""
}

const CreditCardMethod = () => {
    const cartContext = useContext(CartContext);
    
    const [debitCardData, setDebitCardData] = useState(defaultDebitCardState);
    const [failedInputFields, setFailedInputFields] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [paymentConfirmationUrl, setPaymentConfirmationUrl] = useState("");

    const handleUpdateCardData = inputEvent => {
        const inputName = inputEvent.target.name;
        const inputValue = inputEvent.target.value;

        setDebitCardData(prevState => {
            return {
                ...prevState,
                [inputName]: inputValue
            }
        })
    }

    const handleFinishOrderPayment = () => {
        setFailedInputFields([]);

        const loadingNotification = toast.loading('Processando pagamento');

        PaymentService.debitCardPayment(debitCardData).then(transactionData => {
            const redirectUrl = transactionData?.threeDSecure?.url;
            
            toast.remove(loadingNotification);

            setShowSuccessModal(true);
            cartContext.clearCartProducts();
            setPaymentConfirmationUrl(redirectUrl);
        }).catch(response => {
            const formHasFailedFields = response?.failed_fields?.length > 0;

            if(formHasFailedFields) {
                setFailedInputFields(response?.failed_fields);
            }

            toast.remove(loadingNotification);
        });
    }

    const handleModalSuccessRedirectCallback = () => {
        setShowSuccessModal(false);

        if(paymentConfirmationUrl) {
            window.location.href = paymentConfirmationUrl;
        }
    }

    return(
        <div className="credit-card-method-container">
            <div className="credit-card-method-content">
                <ModalWarning 
                    message="Você será redirecionado para a confirmação do pagamento via redecard" 
                    buttonMessage="Continuar com o pagamento"
                    showModal={showSuccessModal} 
                    setShowModal={setShowSuccessModal} 
                    callbackFunction={handleModalSuccessRedirectCallback}
                />
                <ButtonBackAnimate link={'/pagamento'} buttonText="Voltar para o método de pagamento"/>
                <div className="text-credit-method">
                    <h3>Cartão de débito</h3>
                    <h5>Insira os dados de pagamento para<br /> finalizar a compra</h5>
                </div>
                <form className="form-credit">
                    <Row>
                        <div className="form-row column fr-1">
                            <span>
                                <label htmlFor="cardOwnerName">
                                    <h6>Nome do titular do cartão</h6>
                                </label>
                                <input 
                                    id="cardOwnerName" 
                                    name="cardOwnerName" 
                                    type="text" 
                                    value={debitCardData?.cardOwnerName?.toUpperCase()}
                                    onChange={event => handleUpdateCardData(event)}
                                    placeholder="Digite o nome do titular do cartão" 
                                    className={`inputs-form-checkout ${failedInputFields?.includes("card_owner_name") && 'failed-input-field'}`} 
                                />
                                <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                            </span>
                        </div>
                    </Row>
                    <Row>
                        <div className="form-row column fr-1 mt-25">
                            <span>
                                <label htmlFor="cardOwnerName">
                                    <h6>Número do cartão</h6>
                                </label>

                                <ReactInputMask  
                                    id="cardNumber" 
                                    name="cardNumber" 
                                    type="text" 
                                    maskChar={null}
                                    mask="9999 9999 9999 9999 999" 
                                    onChange={event => handleUpdateCardData(event)} 
                                    className={`inputs-form-checkout ${failedInputFields?.includes("card_number") && 'failed-input-field'}`} 
                                    placeholder="Número do Cartão" 
                                /> 
                                <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                            </span>
                        </div>
                    </Row>
                    <Row className="mt-25">
                        <div className="form-row">
                            <span>
                                <label htmlFor="expirationMonth">
                                    <h6>Mês de expiração</h6>
                                </label>
                                <ReactInputMask id="expirationMonth" name="expirationMonth" mask="99" maskChar={null} placeholder="Mês (MM)" onChange={event => handleUpdateCardData(event)} className={`inputs-form-checkout ${failedInputFields?.includes("expiration_month") && "failed-input-field"}`}/>
                            </span>

                            <span className="ml-10">
                                <label htmlFor="expirationYear">
                                    <h6>Ano de expiração</h6>
                                </label>
                                <ReactInputMask id="expirationYear" name="expirationYear" mask="99" maskChar={null} placeholder="Ano (AA)" onChange={event => handleUpdateCardData(event)}  className={`inputs-form-checkout ${failedInputFields?.includes("expiration_year") && "failed-input-field"}`} />
                            </span>
                        </div>
                        <div className="form-row column">
                            <span>
                                <label htmlFor="securityCode">
                                    <h6>Código de segurança</h6>
                                </label>
                                <ReactInputMask mask="9999" maskChar={null} placeholder="Código de segurança (CVV)" id="securityCode" name="securityCode"  onChange={event => handleUpdateCardData(event)} className={`inputs-form-checkout ${failedInputFields?.includes("security_code") && "failed-input-field"}`}/>
                                <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                            </span>
                        </div> 
                    </Row>
                </form>
                <img src={Wave} className="wave-final-credit" alt="" />
                <br />
                <button type="submit" className="finish" onClick={handleFinishOrderPayment}>Finalizar Pedido</button>
            </div>
        </div>
    );
}

export default CreditCardMethod;