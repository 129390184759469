import './index.css'
import { Icon } from '@iconify/react';
import toast from 'react-hot-toast'
import CustomerService from '../../../services/CustomerService';
import { useState } from 'react';
import Wave from'../../../assets/img/purple-wave.png'
const ForgotPasswordPage = () => {
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

    const handleForgetPassword = () => {
        toast.promise(CustomerService.sendPasswordRecoveryEmail(forgotPasswordEmail), {
            success: "Email de recuperação de senha enviado",
            loading: "Enviando email para recuperação",
            error: "Não foi possível enviar o email de recuperação"
        });
        setTimeout(()=>{
            window.location.href = "/";
        },1000)
    }

    return(
        <div className='container-page-forgot-password'>
            <div className="box-page" style={{zIndex: 2}}>
                <Icon icon="mingcute:safe-lock-fill" height='120' width='120' color="#9d7fad" style={{marginTop:'30px'}}/>
                <span style={{fontSize: '24px', fontWeight: '500', textAlign: 'center'}}>Recuperação de Conta</span>
                <span style={{textAlign:'center'}}>Parece que você teve problemas com sua autenticação, <br/>digite abaixo o seu e-mail para recuperação</span>
                <div className="d-flex flex-column" style={{gap: '10px'}}>
                    <input type="email" className="input-email" placeholder='Digite seu e-mail aqui' onChange={event => setForgotPasswordEmail(event.target.value)}/>
                    <button className="btn" onClick={handleForgetPassword}>Enviar</button>
                </div>
            </div>
            <img src={Wave} style={{position: 'absolute', bottom: '0', width: '100%', zIndex: 1}} alt="" />
        </div>
    )
}

export default ForgotPasswordPage;