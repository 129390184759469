import './index.css';
import { Icon } from '@iconify/react';
import { useState, useEffect, useContext } from 'react';

import Footer from '../../Web/HomePage/Footer';
import Header from '../../../components/Header';
import AuthContext from '../../../context/auth/AuthContext';
import WishlistService from '../../../services/WishlistService';
import WishlistContext from '../../../context/wishlist/wishlistContext';
import ProuctCardComponent from '../../../components/ProductCardComponent';

const WishListPage = () => {
    const authContext = useContext(AuthContext);
    const wishlistContext = useContext(WishlistContext);

    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const userId = authContext?.user?.id;

        if(userId) {
            getUserWishlist(userId)
        }
    }, [authContext]);

    const getUserWishlist = userId => {
        WishlistService.getWishlistByUserId(userId)
        .then(response => {
            const productsData = response.data;
            
            setLoading(false);
            wishlistContext.updateWishlist(productsData);
        });
    }

    return(
        <>
        <Header />
    
        <div className='container-wishlist'>
            <div className="wish-title-area">
                <span>Lista de Desejos  <Icon icon={`mdi:cards-heart`} color="772AA0" /></span>
                <span className='desc-wish-title'>Abaixo você poderá ver os produtos que você adicionou a sua lista de desejos, podendo ver ou remover algum produto.</span>
            </div>
            <hr />

            {loading 
                ? <div className="container-lds"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                :                     
                   <>
                    {wishlistContext?.products?.length > 0 &&
                        <div className="products-wish">
                            {wishlistContext?.products?.map((item) => {
                                if(item?.product?.PrecoVendaECommercePraticado_ProEmpEmbPre > 0) {
                                    return(
                                        <div className={`product-wish ${item?.product?.CodigoInterno_Produto}`} key={item?.product?.CodigoInterno_Produto}>
                                            <ProuctCardComponent item={item?.product} wish={false}/>
                                        </div>
                                    )
                                } 
                            })}
                        </div>
                    }
                    {wishlistContext?.products <= 0 &&
                        <span style={{marginTop: '200px', textAlign: 'center', maxWidth: '95%'}}>Parece que você ainda não possui nenhum item na sua lista de desejos</span>
                    }
                   </>
            }    
        </div>
         <Footer/>
         </>
    )
}

export default WishListPage;