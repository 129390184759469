import { useEffect, useRef, useState } from 'react';
import ProductService from '../../../services/ProductService';
import { Collapse } from 'reactstrap';

const CategoryFilterLeft = ({ categoryId, filters, setFilters, clearFilters, productsLength, productsLoading, setShowLoading, selectedGroup, selectedSection, updateSelectedGroup, updateSelectedSection }) => {
    const filterContainerRef = useRef(null);
    const searchProductNameRef = useRef(null);
    
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [isGroupFilterOpen, setIsGroupFilterOpen] = useState(false);
    const [isBrandFilterOpen, setIsBrandFilterOpen] = useState(false);
    const [isSizeFilterOpen, setIsSizeFilterOpen] = useState(false);
    const [isColorFilterOpen, setIsColorFilterOpen] = useState(false);
    const [isFiltersFixedOnTop, setIsFiltersFixedOnTop] = useState(false);
    const [isFiltersFixedOnBottom, setIsFiltersFixedOnBottom] = useState(false);
    const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);
   
    useEffect(() => {    
        window.addEventListener("scroll", handleStickyFilters);

        return () => {
            window.removeEventListener("scroll", handleStickyFilters);
        }
    }, [productsLoading]);


    useEffect(() => {
        setLoadingFilters(true);
        ProductService.getSearchFilters(categoryId).then(response => {
            setSelectedFilters(response);
        }).finally(() => {
            setLoadingFilters(false);
        });

        setShowClearFiltersButton(false);
    }, [categoryId]);

    const handleStickyFilters = () => {
        const scrollTop = window.scrollY;
        const filterContainerHeight = filterContainerRef?.current?.offsetHeight - 50;

        if(scrollTop >= 500  && filterContainerHeight >= scrollTop && productsLoading === false) {
            setIsFiltersFixedOnTop(true);
        } else {
            setIsFiltersFixedOnTop(false);
        }

        if(scrollTop >= filterContainerHeight) {
            setIsFiltersFixedOnBottom(true);
        } else {
            setIsFiltersFixedOnBottom(false);
        }
    }

    const handleAddFilter  = (filterId, filterName) => {
        const filterAlreadyAdded = filters[filterName].includes(filterId);

        if(filterAlreadyAdded) {
            const filterIndex = filters[filterName].indexOf(filterId);
            const removedFilters = filters[filterName].splice(filterIndex, 1);

            setFilters(prevState => ({ 
                [filterName]: removedFilters,
                prioritize_query: true,
                ...prevState 
            }));
        } else {
            const insertedFilters = filters[filterName].concat(filterId);

            setFilters(prevState => ({ 
                ...prevState,
                page: 1,
                [filterName]: insertedFilters,
                prioritize_query: true,
            }));
        }

        setShowLoading(true);
        window.scrollTo(0, 450);
        setShowClearFiltersButton(true);
    }

    const handleProductNameFilter = (event) => {
        event.preventDefault();
        const productNameFilter = searchProductNameRef?.current?.value;

        setShowLoading(true);
        setShowClearFiltersButton(true);
        setFilters(prevState => ({ ...prevState, 'product_name': productNameFilter }));
    }

    const handleClearFilters = () => {
        window.scrollTo(0, 450);

        clearFilters();
        setShowClearFiltersButton(false);
        searchProductNameRef.current.value = "";
    }

    const handleUpdateSection = (sectionId) => {
        if(selectedSection) {
            updateSelectedSection(null);
        }
        setFilters(prevState => {
            const sectionAlreadyChecked = prevState?.section_id  === sectionId;
            
            return { 
                ...prevState,
                section_id: sectionAlreadyChecked ? null : sectionId
            }
        }); 

        setShowClearFiltersButton(true);
    }

    const handleUpdateGroup = (groupId) => {
        if(selectedGroup) {
            updateSelectedGroup(null);
        }

        setFilters(prevState => {
            const groupAlreadyChecked = prevState?.group_id  === groupId;

            return {
                ...prevState,
                group_id: groupAlreadyChecked ? null : groupId,
            }
        });

        setShowClearFiltersButton(true);
    }

    const handleClassUpdate = className => {
        if(isFiltersFixedOnTop && productsLoading === false) {
            if(isGroupFilterOpen && isBrandFilterOpen && isColorFilterOpen) {
                return  "";
            }

            return productsLength > 6 ? className : "";
        }

        return "";
    }

    return (
        <div className={`col-lg-3  ${handleClassUpdate("filters-container")}`} style={{position: "relative"}} ref={filterContainerRef}>
            <div className={`shop-sidebar-wrapper ${handleClassUpdate("fixed-filters")} ${(isFiltersFixedOnBottom && productsLength) ? "fixed-filters-on-bottom" : ""}`}>
                {loadingFilters === true &&
                <div className="container-lds"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                }
                {loadingFilters === false && 
                    <>
                       <div className="sidebar-item d-flex justify-content-between">
                            <span>
                                <h4 className="sidebar-title mb-45">Filtrar</h4>
                            </span>
                            {showClearFiltersButton && 
                                <span>
                                    <button className="d-flex btn-clear-filters" onClick={handleClearFilters}>
                                        Limpar filtros
                                    </button>
                                </span>
                            }
                        </div>

                        <div className="newsletter-content-wrap" style={{ marginTop: '-30px' }}>
                            <div className="newsletter-form">
                                <form onSubmit={event => handleProductNameFilter(event)}>
                                    <input type="text" id="txtSearch" ref={searchProductNameRef} className="form-control search-button" placeholder="Busque por nome" autoComplete="off"/>
                                    <button className="btn btn-submit" type="submit">
                                        <i className="icon-magnifier icon-search-head"></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        {selectedFilters?.sections?.length > 0 &&
                            <div className="sidebar-item mb-40 mt-30">
                                <h4 className="small-title">
                                    <a onClick={() => setIsGroupFilterOpen(prevState => !prevState)}>Categoria</a>
                                </h4>
                                <div className="sidebar-body">
                                    <Collapse isOpen={isGroupFilterOpen}>
                                        <div className="sidebar-menu-list pb-2">
                                            {selectedFilters?.sections?.map(section => (
                                                <div key={section?.CodigoInterno_SecaoCommerce} className="list-item">
                                                    <input 
                                                        readOnly
                                                        id={`list-groups-${section?.CodigoInterno_SecaoCommerce}`}
                                                        type="checkbox" 
                                                        name={`group-${section?.CodigoInterno_SecaoCommerce}`}
                                                        className="form-check-input" 
                                                        value={section?.CodigoInterno_SecaoCommerce} 
                                                        checked={filters?.section_id === section?.CodigoInterno_SecaoCommerce}
                                                        onClick={() => handleUpdateSection(section?.CodigoInterno_SecaoCommerce)}
                                                     />
                                                    <label className="form-check-label" htmlFor={`list-groups-${section?.CodigoInterno_SecaoCommerce}`}>{section?.Nome_SecaoCommerce}</label>
                                                </div>
                                            ))}
                                            <hr />
                                            {selectedFilters?.groups?.map(subGroup => (
                                                <div key={subGroup?.CodigoInterno_GrupoCommerce} className="list-item">
                                                    <input 
                                                        readOnly
                                                        id={`list-subgroups-${subGroup?.CodigoInterno_GrupoCommerce}`}
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        value={subGroup?.CodigoInterno_GrupoCommerce} 
                                                        checked={filters?.group_id === subGroup?.CodigoInterno_GrupoCommerce}                                                
                                                        onClick={() => handleUpdateGroup(subGroup?.CodigoInterno_GrupoCommerce)}
                                                     />
                                                    <label className="form-check-label" htmlFor={`list-subgroups-${subGroup?.CodigoInterno_GrupoCommerce}`}>{subGroup?.Nome_GrupoCommerce}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        }

                        {selectedFilters?.brands?.length > 0 &&
                            <div className="sidebar-item mb-40">
                                <h4 className="small-title">
                                    <a onClick={() => setIsBrandFilterOpen(prevState => !prevState)}>Marca</a>
                                </h4>
                                <div className="sidebar-body">
                                    <Collapse isOpen={isBrandFilterOpen}>
                                        <div className="sidebar-menu-list pb-2">
                                            {selectedFilters?.brands?.map(brand => (
                                                <div key={brand?.id} className="list-item">
                                                    <input 
                                                        id={`list-brands-${brand?.id}`} 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        value={brand?.id} 
                                                        checked={filters?.brands.includes(brand.id)}
                                                        onChange={() => handleAddFilter(brand?.id, "brands")}
                                                     />
                                                    <label className="form-check-label" htmlFor={`list-brands-${brand?.id}`}>{brand?.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        }
                        
                        {selectedFilters?.sizes?.length > 0 && 
                            <div className="sidebar-item mb-40">
                                <h4 className="small-title">
                                   <a onClick={() => setIsSizeFilterOpen(prevState => !prevState)}>Tamanho</a>
                                </h4>
                                <div className="sidebar-body">
                                    <Collapse isOpen={isSizeFilterOpen}>
                                        <div className="sidebar-menu-list pb-2">
                                            {selectedFilters?.sizes?.map(size => (
                                                <div key={size?.CodigoInterno_Tamanho} className="list-item">
                                                    <input 
                                                        id={`list-size-${size?.CodigoInterno_Tamanho}`} 
                                                        type="checkbox"
                                                        value={size?.CodigoInterno_Tamanho} 
                                                        className="form-check-input" 
                                                        checked={filters?.sizes?.includes(size.CodigoInterno_Tamanho)}
                                                        onChange={() => handleAddFilter(size?.CodigoInterno_Tamanho, "sizes")}
                                                    />
                                                    <label className="form-check-label" htmlFor="list-size">{size?.NomeSite_Tamanho}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        }
                        
                        {selectedFilters?.colors?.length > 0 && 
                            <div className="sidebar-item mb-40">
                                <h4 className="small-title">
                                   <a onClick={() => setIsColorFilterOpen(prevState => !prevState)}>Cor</a>
                                </h4>
                                <Collapse isOpen={isColorFilterOpen}>
                                    <div className="sidebar-body">
                                        <ul className="sidebar-menu-list pb-2">
                                            {selectedFilters?.colors?.map((color, index) => {
                                                const loweredCaseColorNames = filters?.colors?.map(color => color?.toLowerCase())

                                                return(
                                                    <div className="list-item" key={color?.CodigoInterno_Cor}>
                                                        <input 
                                                            id={`list-color-${color?.CodigoInterno_Cor}`}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value={color?.Nome_Cor}
                                                            checked={loweredCaseColorNames?.includes(color?.Nome_Cor?.toLowerCase())}
                                                            onChange={() => handleAddFilter(color?.Nome_Cor, "colors")}
                                                        />
                                                        <label className="form-check-label pl-2" htmlFor={`list-color-${color?.CodigoInterno_Cor}`}>{color?.Nome_Cor}</label>
                                                    </div>

                                                );
                                            })}
                                        </ul>
                                    </div>
                                </Collapse>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default CategoryFilterLeft;