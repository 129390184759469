import React from "react";
import './index.css';
import DepartamentTest from '../../../../assets/img/departaments-test.png'
import DepartamentTest2 from '../../../../assets/img/departaments-test2.png'
import NumberProducts from '../../../../assets/img/number-of-products.png'
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const FeaturedDepartaments = () => {
    return(
        <div className="container-featured-departaments">
            <div className="featured-departaments-content">
                <div className="image-featured-departaments">
                    <img src={DepartamentTest} className="image-departament" alt="" />  
                    <img src={NumberProducts} className="number-products" alt="" />  
                </div>       
                <div className="info-departaments">
                    <div className="title-departaments">
                        <h3>Tricô</h3>
                    </div>
                    <div className="text-departaments">
                        <h3>Aqui você vai encontrar os melhores materiais do Brasil para suas receitas de tricô, organizados e cuidados pela nossa equipe do Lá Na Maria. 
                        Aqui nós preservamos cada linha, agulha, tecido e material com cuidado e elegância.</h3>
                    </div>
                    <Link to={"/departamento/5"}>
                        <button className="go-to-departaments">
                            Ver Departamento <Icon icon="bx:arrow-to-top" height="32px" width="32px"/>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="featured-departaments-content">   
                <div className="info-departaments">
                    <div className="title-departaments">
                        <h3>Crochê</h3>
                    </div>
                    <div className="text-departaments">
                        <h3>Aqui você vai encontrar os melhores materiais do Brasil para suas receitas de crochê, organizados e cuidados pela nossa equipe do Lá Na Maria. 
                        Aqui nós preservamos cada linha, agulha, tecido e material com cuidado e elegância, temos diversos especialista que estão no mundo de artesanato a muito tempo,
                        você sempre terá as melhores dicas e indicações, para crescer e aproveitar o artesanato do melhor jeito possivel.</h3>
                    </div>
                    <Link to={'/departamento/1'}> 
                        <button className="go-to-departaments">
                            Ver Departamento <Icon icon="bx:arrow-to-top" height="32px" width="32px"/> 
                        </button>
                    </Link>
                </div>
                <div className="image-featured-departaments">
                    <img src={DepartamentTest2} alt="" className="image-departament" />   
                    <img src={NumberProducts} className="number-products" alt="" /> 
                </div>    
            </div>
        </div>
    );
}

export default FeaturedDepartaments;