import React from "react";
import './index.css'
import Logo  from '../../../../assets/img/logo/logo.png'
import MethodPayment from '../../../../assets/img/method-payment.png'
import { Link } from "react-router-dom";

const Footer = () => {
        const actualDate = new Date();
        const actualYear = actualDate.getFullYear();
    return(
        <div className="container-footer">
            <div className="footer-content">
                <div className="footer-row">
                    <img src={Logo} className="logo-footer" alt="" />
                    <h3 className="text-footer">
                        Se aventurar no mundo do artesanato, fazer oficinas com quem tem paixão pelo assunto, 
                        encontrar insipiração, trocar experiências e dar forma às ideias em um ateliê de criação. 
                        Que lugar é esse? É LÁNAMARIA!
                    </h3>
                    <h3 className="text-localization">
                        Av. Guilherme Cotching, 1965<br />
                        Vila Maria, São Paulo - SP<br />
                        CEP: 02113-017
                    </h3>
                </div>
                <div className="footer-row">
                    <ul className="footer-menu">
                        <li><Link to={'/sobre-nos'}>Sobre Nós</Link></li>
                        <li><Link to={'/cookies'}>Cookies</Link></li>
                        <li><Link to={'/termos-de-uso'}>Termos de uso</Link></li>
                        <li><Link to={'/privacidade'}>Politicas de privacidade</Link></li>
                    </ul>
                    {/* <img src={MethodPayment} className="method-payments" alt="" /> */}
                </div>
                <div className="footer">
                <h3> © {actualYear} LáNaMaria. Todos os direitos reservados | Desenvolvido por JustiSecure </h3>
                </div>
            </div>
        </div>
    );
}

export default Footer;