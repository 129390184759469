import React from "react";

import Header from "../Header";
import Apresentation from "./Apresentation";
import Description from "./Description";
import Story from "./Story";
import Values from "./Values";
import Community from "./Community";
import Footer from "../../views/Web/HomePage/Footer";

const About = () => { 
    return(
        <>  
            <Header/>
            <Apresentation/>
            <Description/>
            <Story/>    
            <Values/>
            <Community/>
            <Footer/>
        </>
    );
}

export default About;