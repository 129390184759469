import { useContext, useReducer, useState } from "react"
import toast from "react-hot-toast";
import CartService from "../../services/CartService";
import CouponService from "../../services/CouponService";

import CartContext from "./cartContext"
import FreightService from "../../services/FreightService";

const defaultCartState = {
    cartData: {},
    products: [],
    totalPrice: 0,
    totalWithDiscounts: 0,
    productsCount: 0,
    selectedAddress: {},
    selectedFreightService: {}
}

const cartReducer = (state, action) => {
    if(action.type === "updateCart") {
        const cartTotal = action.cartData.total;
        const products = action.cartData.products;
        const productsCount = products?.length;
        const guestToken = action?.cartData?.guest_token;
        const totalWithDiscounts = action?.cartData?.total_with_discount;

        if(guestToken) {
            localStorage.setItem("guest-token", guestToken);
        }
        return {
            ...state,
            cartData: action?.cartData,
            products: products,
            totalPrice: cartTotal,
            productsCount: productsCount ? productsCount : 0,
            totalWithDiscounts: totalWithDiscounts
        }
    }

    if(action.type === "increaseProductQuantity") {
        const cartProducts = state.products;
        const productId = action?.productCode;
        const cartTotal = action?.cart?.total
        const cartTotalWithDiscounts = action?.cart?.total_with_discount;

        const updatedProducts = cartProducts.map(product => {
            if(product?.cart_product_data?.CodigoInterno_Produto === productId) {
                return {
                    ...product,
                    amount: ++product.amount
                };
            }
            
            return product;
        });

        return {
            ...state,
            products: updatedProducts,
            totalPrice: cartTotal,
            totalWithDiscounts: cartTotalWithDiscounts
        }
    }

    if(action.type === "decreaseProductQuantity") {
        const cartProducts = state.products;
        const productId = action.product?.cart_product_data?.CodigoInterno_Produto;
        const cartTotal = action?.cart?.total
        const cartTotalWithDiscounts = action?.cart?.total_with_discount;


       
        const updatedProducts = cartProducts.map(product => {
            if(product?.cart_product_data?.CodigoInterno_Produto === productId) {
                return {
                    ...product,
                    amount: --product.amount
                };
            }
            
            return product;
        });

        return {
            ...state,
            products: updatedProducts,
            totalPrice: cartTotal,
            totalWithDiscounts: cartTotalWithDiscounts
        }
    }

    if(action.type === "clearCartProducts") {
        return defaultCartState;
    }

    return defaultCartState;
}   

const CardProvider = props => {
    const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);

    const addProductToCartHandler = (newProduct, amount = 1, size = null) => {
        const productAlreadyExists = cartState?.products?.find(product => (
            product?.cart_product_data?.CodigoInterno_Produto === newProduct?.CodigoInterno_Produto
        ));
        
        if(productAlreadyExists) {
            toast.error('O produto já foi inserido no carrinho');
            return;
        }

        const selectedPackage = size === null ? newProduct?.CodigoInterno_ProEmb : size;
        
        toast.promise(CartService.insertProduct(newProduct?.CodigoInterno_Produto, amount, selectedPackage), {
            loading: 'Inserindo produto no carrinho...',
            success: 'Pronto!',
            error: 'Não foi possível inserir o produto no carrinho'            
        }).then(response => {
            const cartData = response;
            dispatchCartAction({ type: 'updateCart', cartData });
        });
    }

    const removeProductCartHandler = product => {
        const productCode = product?.cart_product_data?.CodigoInterno_Produto;

        toast.promise(CartService.removeProduct(productCode), {
            loading: 'Removendo produto do carrinho...',
            success: 'Pronto!',
            error: 'Não foi possível remover o produto do carrnho'
        }).then(response => {
            const cartData = response;
            dispatchCartAction({ type: 'updateCart', cartData });
        });
    }

    const getUserCartHandler = (user) => {
        CartService.getUserCart(user).then(response => {
            const cartData = response;

            dispatchCartAction({ type: 'updateCart', cartData, user});
        });
    }

    const addCouponDiscountHandler = (coupon, user) => {
        toast.promise(CouponService.applyDiscount(coupon, user), {
            error: "Cupom inválido.",
            loading: "Verificando cupom.",
            success: "Cupom válido."
        }).then(response => {
            const cartData = response.data;
            dispatchCartAction({ type: "updateCart", cartData });
        });
    }

    const increaseProductQuantityHandler = (product) => {
        const productCode = product?.cart_product_data?.CodigoInterno_Produto;

        toast.promise(CartService.increaseProductQuantity(productCode), {
            error: "Não foi possível aumentar a quantidade do produto",
            loading: "Atualizando o carrinho",
            success: "Carrinho atualizado!"
        }).then(response => {
            dispatchCartAction({ type: "increaseProductQuantity", cart: response, productCode});
        });
    }

    const decreaseProductQuantityHandler = (product) => {
        const productCode = product?.cart_product_data?.CodigoInterno_Produto;

        if(product.amount > 1) {
            toast.promise(CartService.decreaseProductQuantity(productCode), {
                error: "Não foi possível aumentar a quantidade do produto",
                loading: "Atualizando o carrinho",
                success: "Carrinho atualizado!"
            }).then(response => {
                dispatchCartAction({ type: "decreaseProductQuantity", cart: response, product});
            });
        }
    }

    const updateCartFreightData = (destinationAddressId, freightData) => {
       return FreightService.saveFreightData(destinationAddressId, freightData).then(response => {
            const cartData = response;
            dispatchCartAction({ type: "updateCart", cartData});
        }).catch(false);
    }

    const clearCartProductsHandler = () => {
        dispatchCartAction({ type: "clearCartProducts" });
    }

    const cartContext = {
        cartData: cartState.cartData,
        products: cartState.products,
        totalPrice: cartState.totalPrice,
        productsCount: cartState.productsCount,
        totalWithDiscounts: cartState.totalWithDiscounts,
        selectedAddress: cartState.selectedAddress,
        selectedFreightService: cartState.selectedFreightService,
        addProduct: addProductToCartHandler,
        removeProduct: removeProductCartHandler,
        getUserCart: getUserCartHandler,
        addCouponDiscount: addCouponDiscountHandler,
        increaseProductQuantity: increaseProductQuantityHandler,
        decreaseProductQuantity: decreaseProductQuantityHandler,
        clearCartProducts: clearCartProductsHandler,
        updateCartFreightData: updateCartFreightData
    }

    return (
        <CartContext.Provider value={cartContext}>
            {props.children}
        </CartContext.Provider>
    );
}

export default CardProvider;