import api from './api';

class ProductService {
    async getProductById(id) {
        const response = await api.get(`/celta/products?id=${id}`);
        return response.data;
    }

    async getAllProducts(id, page, per_page, query) {
        const response = await api.get(`/celta/products/category?id=${id}&page=${page}&per_page=${per_page}&query=${query}`);
        return response.data;
    }
    async getFeaturedProducts(id, page, per_page){
        const response = await api.get(`/celta/products/category?id=${id}&page=${page}&per_page=${per_page}`);
        return response.data;
    }
    async getSearchFilters(categoryId) {
        const response =  await api.get(`/celta/products/available-filters?id=${categoryId}`);
        return response.data;
    }

   async getFilteredProducts(filterData, department, section, group) {
        const requestBody = {
            ...filterData,
            department_id: department,
            prioritize_query: (section || group) ? true : false
        }

        const response = await api.post("/celta/products/filter", requestBody);
        return response.data;
   }

   async getAvailableFeaturedProducts(departmentId) {
        const response = await api.get(`/store/home-page/featured-products?id=${departmentId}`);
        return response.data;
   }

   async getBannerSpecialSection() {
        const response = await api.get("/store/home-page/custom-section/get-active");
        return response.data;
    }

    async getDepartments() {
        const response = await api.get("celta/menu-departments");
        return response.data;
    }

    async getProductVariantByProductInternalCode(id) {
        const response = api.get(`/celta/product-variants/${id}/show`);
        return response;
    }

    async getProductVariantById(id) {
        const response = await api.get(`/celta/product-variants?id=${id}`);
        return response;
    }

    async searchProducts(searchValue,  page, productsPerPage) {
        const response = await api.get(`/store/products/search?value=${searchValue}&page=${page}&per_page=${productsPerPage}`);
        return response?.data;
    }

    async getRandomProducts() {
        const response = await api.get("/store/home-page/random-products");
        return response?.data;
    }

    async getRelatedProducts(productId) {
        const response = await api.get(`/celta/products/related-products?product_id=${productId}`)
        return response;
    }

    async getOftenPurshasedProducts() {
        const response = await api.get("/celta/products/often-purshased");
        return response;
    }

    async getProductRating(productId, customerId) {
        const response = await api.get(`/celta/products/product-rating?product_id=${productId}&customer_id=${customerId}`);
        return response;
    }

    async createProductRating(customerId, productId, ratingValue)
    {
        const requestBody = {
            customerId,
            productId, 
            ratingValue
        }

        const response = await api.post("/celta/products/rate-product", requestBody);
        return response;
    }
}

export default new ProductService();
