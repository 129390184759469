import './index.css'
import React, { useEffect, useState } from "react";

// Components
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const showTextTimeout = 300;
const ButtonBackAnimate = ({link, buttonText="Voltar para o carrinho"}) => {
    const [buttonContent, setButtonContent] = useState("");
    const [showButtonText, setShowButtonText] = useState(false);

    useEffect(() => {
        let showButtonMessageTimeoutId;
        
        if(showButtonText) {
            showButtonMessageTimeoutId = setTimeout(() => setButtonContent(buttonText) , showTextTimeout);
        } 
        
        return () => {
            setButtonContent("");
            clearInterval(showButtonMessageTimeoutId)
        }
    }, [showButtonText]);

    return(
        <a href={link}>
            <button className='btn-back' onMouseEnter={() => setShowButtonText(true)} onMouseLeave={() => setShowButtonText(false)}>
                <Icon icon="akar-icons:arrow-right" color='#fff' />
                {buttonContent}
            </button>
        </a>
    );
}

export default ButtonBackAnimate;