import { useState } from "react";
import AuthContext from "./AuthContext";
import CustomerService from "../../services/CustomerService";

const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: null,
        user: null,
        isAuthenticated: null
    });
    
    const handleAuthenticateUser = atuhenticationData  => {
        const user = atuhenticationData?.user;
        const token = atuhenticationData?.token;
        localStorage.setItem("auth-token", token);

        setAuthData(prevState => {
            return {
                ...prevState,
                token: token,
                user: user,
                isAuthenticated: true
            }
        });
    }

    const handleGetUserSession = (redirect = false) => {
        CustomerService.session(redirect).then((response) => {
            const { data } = response;

            setAuthData(prevState => {
                return {
                    ...prevState,
                    user: data,
                    isAuthenticated: true
                }
            })
        }, () => {
            setAuthData(prevState => {
                return {
                    ...prevState,
                    isAuthenticated: false
                }
            })
        });
    }

    const handleSignOutUser = () => {
        setAuthData({
            token: null,
            user: null,
            isAuthenticated: null
        });

        localStorage.removeItem("auth-token");
    }

    const authContext = {
        token: authData.token,
        user: authData.user,
        isAuthenticated: authData.isAuthenticated,
        authenticateUser: handleAuthenticateUser,
        getUserSession: handleGetUserSession,
        signOutUser: handleSignOutUser
    }
    
    return (
        <AuthContext.Provider value={authContext}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;