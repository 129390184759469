import React from "react";
import './index.css'

import Quality from '../../../assets/img/quality-img-01.png'
import Adventure from '../../../assets/img/adventure-img-01.png'
import Inspiration from '../../../assets/img/inspiração-img-01.png'

const Values = () => {
    return(
        <div className="values-container">
            <h3 className="title-values">O <span style={{color: "var(--primary-color)"}}>LáNaMaria</span> é para pessoas que :</h3>
            
            <div className="quality-container">
                <div className="img-area">
                    <img src={Quality} className="quality" alt="" />
                </div>
                <div className="text-area">
                    <h3 className="title-quality">Querem produtos de <span>qualidade</span></h3>
                    <h5 className="text-quality">No LáNaMaria você vai encontrar produtos de qualidade e elegância, cuidados pela nossa super equipe, para sempre manter a organização e arte dos nossos produtos.</h5>
                </div>
                <svg width="289" height="479" viewBox="0 0 289 479" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="239.5" cy="239.5" r="239" stroke="#CFABE2" strokeDasharray="13 13"/>
                 </svg>
            </div>
            <div className="adventure-container">
                <div className="text-area-adventure">
                    <h3 className="title-adventure">Querem se <span>aventurar</span>  no mundo do <span>artesanato</span></h3>
                    <h5 className="text-adventure">No LáNaMaria você vai ter novas experiências e se aprofundar cada vez mais nesse mundo de artesanatos, podendo ver receitas em nossos blogs, comprando produtos 
                    ideais para você começar do jeito certo, além de compartilhar experiências com outras pessoas desse mundo fantástico.</h5>
                </div>
                <div className="img-area">
                    <img src={Adventure} className="adventure" alt="" />
                </div>
            </div>
            <div className="quality-container">
                <div className="img-area">
                    <img src={Inspiration} className="inspiration" alt="" />
                </div>
                <div className="text-area">
                    <h3 className="title-quality">Querem ter <span>inspirações</span> e compartilhar experiências</h3>
                    <h5 className="text-quality">No LáNaMaria você vai ter a maior e melhor fonte de criatividade e produtos para o seu negócio ou
                    hobby, e também vai poder saber como as outras pessoas fazem e evoluir juntamente com toda a nossa comunidade.</h5>
                </div>
                <svg className="second-circle" width="573" height="573" viewBox="0 0 573 573" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="286.5" cy="286.5" r="285.734" stroke="#CFABE2" strokeWidth="1.53209" strokeDasharray="19.92 19.92"/>
                </svg>
            </div>
        </div>
    );
}


export default Values;