import './index.css'
import { Icon } from '@iconify/react';
import { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import CustomerService from '../../services/CustomerService';
import { Row } from 'reactstrap'
import { toast } from 'react-hot-toast';

const customerAddressDefaultValue = {
    addressName: "",
    address: "",
    number: "",
    complement: "",
    reference: "",
    district: "",
    city: "",
    state: "",
    zipcode: ""
}

const ModalAdress = ({showModal, setShowModal, updateAddressesList}) => {
    const authContext = useContext(AuthContext);
    const [failedFields, setFailedFields] = useState([]);

    const [customerAddress, setCustomerAddress] = useState(customerAddressDefaultValue);

    const handleUserAddressChange = (inputKey, inputValue) => {
        setCustomerAddress(prevState => {
            return {
                ...prevState,
                [inputKey]: inputValue
            }
        });
    }

    const handleAddNewAddress = event => {
        event.preventDefault();
        const userId = authContext.user.id;
        toast.promise(CustomerService.addCustomerAddress(userId, customerAddress), {
            loading: "Criando endereço",
            success: "Usuário atualizado",
            error: "Não foi possível criar endereço"
        }).then(() => {
            setTimeout(() => {
                setShowModal(false);
                updateAddressesList();
                setCustomerAddress(customerAddressDefaultValue)
            }, 1500)
        }).catch(response => {
            if(response?.failed_fields) {
                setFailedFields(response?.failed_fields);
            } 
        });
    }

    const handleZipCodeAutoComplete = async () => {
        fetch(`https://viacep.com.br/ws/${customerAddress?.zipcode}/json`)
        .then(response => {
            return response.json();
        })
        .then(data => {
            setCustomerAddress(prevState => {
                return {
                    ...prevState,
                    district: data?.bairro,
                    address: data?.logradouro,
                    city: data?.localidade,
                    state: data?.uf
                }
            });
        })
        .catch(() => {
            toast.error('Cep inválido.')
        });
    }

    return(
        <>
            <div className='black' style={{display: `${showModal ? 'block' : 'none'}`}}>a</div>
            <div className="modal-adress-container-modal" style={{display: `${showModal ? 'block' : 'none'}`}}>
                <Icon icon="material-symbols:close-rounded" color="#6e6e6e" className="close-modal-adress" onClick={()=> setShowModal(false)}/>
                <div className="box-content-adress-modal">
                    <span>
                        Cadastrar Novo Endereço
                    </span>
                    <Row className="d-flex align-items-center">
                        <div className="form-row inputs-checkouts  column fr-3">
                            <label htmlFor="cep">Nome do endereço</label>
                            <input value={customerAddress.addressName} placeholder="Ex: Casa, Trabalho" className={`inputs-form-checkout ${failedFields.includes('addressName') && "failed-input"}`} type="text"  id="cep" name='cep' onChange={event => handleUserAddressChange('addressName', event.target.value)} />
                            <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <div className="form-row inputs-checkouts  column fr-3">
                            <label htmlFor="cep">CEP</label>
                            <input value={customerAddress.zipcode} className={`inputs-form-checkout ${failedFields.includes('zipcode') && "failed-input"}`} type="text"  id="cep" name='cep' onChange={event => handleUserAddressChange('zipcode', event.target.value)} />
                            <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                        {customerAddress?.zipcode?.length > 0 &&
                            <div className="form-row inputs-checkouts column fr-3">
                                <button type="button" className="verify-cep" onClick={handleZipCodeAutoComplete}> Verificar cep </button>
                            </div>                       
                        } 
                    </Row>
                    <Row>
                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="adress">Endereço</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('address') && "failed-input"}`} type="text" id="adress" name='address' value={customerAddress.address} onChange={event => handleUserAddressChange('address', event.target.value)} />
                            <Icon className='icon-input' icon="ri:home-5-fill" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                    </Row>
                    <Row>
                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="adress_number">Número</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('number') && "failed-input"}`} type="text"  id="adress_number" name='number' value={customerAddress?.number} onChange={event => handleUserAddressChange('number', event.target.value)} />
                            <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                        <div className="form-row inputs-checkouts column fr-3">
                        <label htmlFor="address_district">Bairro</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('district') && "failed-input"}`} type="text"  id="address_district" name='district' value={customerAddress?.district} onChange={event => handleUserAddressChange('district', event.target.value)} />
                            <Icon className='icon-input' icon="material-symbols:123" color="rgba(0, 0, 0, 0.324)" />
                        </div>                 
                    </Row>
                    <Row>

                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="complete">Município</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('city') && "failed-input"}`} type="text" id="complete" name='city' value={customerAddress?.city} onChange={event => handleUserAddressChange('city', event.target.value)} />
                            <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="address_state"> UF </label>
                            <input className={`inputs-form-checkout ${failedFields.includes('state') && "failed-input"}`} type="text" id="address_state" name='state' value={customerAddress?.state} onChange={event => handleUserAddressChange('state', event.target.value)} />
                            <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                        </div>
                
                    </Row>
                    <Row className="d-flex items-center">
                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="complete">Complemento</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('complement') && "failed-input"}`} type="text" id="complete" name='complete' value={customerAddress?.complement} onChange={event => handleUserAddressChange('complement', event.target.value)} />
                            <Icon className='icon-input' icon="tabler:antenna-bars-1" color="rgba(0, 0, 0, 0.324)" />
                        </div>  
                        <div className="form-row inputs-checkouts  column fr-3">
                        <label htmlFor="address_reference">Ponto de Referência</label>
                            <input className={`inputs-form-checkout ${failedFields.includes('reference') && "failed-input"}`} type="text" id="address_reference" name='reference' value={customerAddress?.reference} onChange={event => handleUserAddressChange('reference', event.target.value)} />
                            <Icon className='icon-input' icon="map:postal-code" color="rgba(0, 0, 0, 0.324)" />
                        </div>  
                        <div className="form-row inputs-checkouts  column fr-3">
                            <button type='submit' className='finish-signup mt-25'  onClick={handleAddNewAddress}> Salvar </button>
                        </div>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ModalAdress;