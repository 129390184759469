import api from './api';

class BlogService {
    async getBlogPostById(id) {
        const response = await api.get(`/store/blog/post/show?id=${id}`);
        return response.data;
    }

    async getPosts() {
        const response = await api.get('/store/blog/post/index/?page=1&per_page=10&query=');
        return response.data;
    }

    async getMostAccessedPosts(postsQuantity) {
        const response = await api.get(`store/blog/post/most-accessed?total_posts=${postsQuantity}`);
        return response.data;
    }

    async getMostRecentPosts(postsQuantity) {
        const response = await api.get(`store/blog/post/most-recent?total_posts=${postsQuantity}`)
        return response.data;
    }
}

export default new BlogService;
