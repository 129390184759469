import './index.css'

import { useState, useEffect, useContext } from "react";

import { Icon } from "@iconify/react";
import { Row } from "reactstrap";
import toast from "react-hot-toast";
import ReactInputMask from "react-input-mask";

import CartContext from '../../../../context/cart/cartContext';
import PaymentService from '../../../../services/PaymentService';

import Loader from '../../../../components/Loader/Index';
import ButtonBackAnimate from "../../CartPage/ButtonBackAnimate";
import ModalSucesss from "../../../../components/Modals/ModalSuccess";

import Wave from '../../../../assets/img/Cart/wave-final-credit.png';

const defaultCreditCardState = { 
    cardOwnerName: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    securityCode: "",
    installments: ""
}

const CreditCardMethod = () => {
    const cartContext = useContext(CartContext);
    
    const [loading, setLoading] = useState(true);
    const [installments, setInstallments] = useState([]);
    const [creditCardData, setCreditCardData] = useState(defaultCreditCardState);
    const [failedInputFields, setFailedInputFields] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect( () => {
        getInstallments();
    }, []);    

    const getInstallments = () => {
        PaymentService.getInstallmentsValue(cartContext?.cartData?.id)
        .then(installments => setInstallments(installments))
        .finally(() => setLoading(false))
    }

    const handleUpdateCardData = inputEvent => {
        const inputName = inputEvent.target.name;
        const inputValue = inputEvent.target.value;

        setCreditCardData(prevState => {
            return {
                ...prevState,
                [inputName]: inputValue
            }
        })
    }

    const handleFinishOrderPayment = () => {
        setFailedInputFields([]);

        const loadingNotification = toast.loading('Processando pagamento');

        PaymentService.creditCardPayment(creditCardData).then(() => {               
            setShowSuccessModal(true);
            cartContext.clearCartProducts();

            toast.remove(loadingNotification);
        }).catch(response => {
            const formHasFailedFields = response?.failed_fields?.length > 0;

            if(formHasFailedFields) {
                setFailedInputFields(response?.failed_fields);
            }

            toast.remove(loadingNotification);
        });
    }

    const handleModalSuccessRedirectCallback = () => {
        window.location.href = '/';
    }

    if (loading) {
        return <Loader />
    } else {

        return(
            <div className="credit-card-method-container">
                <div className="credit-card-method-content">
                    <ModalSucesss modalView={showSuccessModal} setModalView={setShowSuccessModal} onClosedCallback={handleModalSuccessRedirectCallback}/>
                    <ButtonBackAnimate link={'/pagamento'} buttonText="Voltar para o método de pagamento"/>
                    <div className="text-credit-method">
                        <h3>Cartão de Crédito</h3>
                        <h5>Insira os dados de pagamento para<br /> finalizar a compra</h5>
                    </div>
                    <form className="form-credit">
                        <Row>
                            <div className="form-row column fr-1">
                                <span>
                                    <label htmlFor="cardOwnerName">
                                        <h6>Nome do titular do cartão</h6>
                                    </label>
                                    <input 
                                        id="cardOwnerName" 
                                        name="cardOwnerName" 
                                        type="text" 
                                        value={creditCardData?.cardOwnerName?.toUpperCase()}
                                        onChange={event => handleUpdateCardData(event)}
                                        placeholder="Digite o nome do titular do cartão" 
                                        className={`inputs-form-checkout ${failedInputFields?.includes("card_owner_name") && 'failed-input-field'}`} 
                                    />
                                    <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                                </span>
                            </div>
                        </Row>
                        <Row>
                            <div className="form-row column fr-1 mt-25">
                                <span>
                                    <label htmlFor="cardNumber">
                                        <h6>Número do cartão</h6>
                                    </label>
    
                                    <ReactInputMask  
                                        id="cardNumber" 
                                        name="cardNumber" 
                                        type="text" 
                                        maskChar={null}
                                        mask="9999 9999 9999 9999 999" 
                                        onChange={event => handleUpdateCardData(event)} 
                                        className={`inputs-form-checkout ${failedInputFields?.includes("card_number") && 'failed-input-field'}`} 
                                        placeholder="Número do Cartão" 
                                    /> 
                                    <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                                </span>
                            </div>
                        </Row>
                        <Row className="mt-25">
                            <div className="form-row">
                                <span>
                                    <label htmlFor="expirationMonth">
                                        <h6>Mês de expiração</h6>
                                    </label>
                                    <ReactInputMask id="expirationMonth" name="expirationMonth" mask="99" maskChar={null} placeholder="Mês (MM)" onChange={event => handleUpdateCardData(event)} className={`inputs-form-checkout ${failedInputFields?.includes("expiration_month") && "failed-input-field"}`}/>
                                </span>
    
                                <span className="ml-10">
                                    <label htmlFor="expirationYear">
                                        <h6>Ano de expiração</h6>
                                    </label>
                                    <ReactInputMask id="expirationYear" name="expirationYear" mask="99" maskChar={null} placeholder="Ano (AA)" onChange={event => handleUpdateCardData(event)}  className={`inputs-form-checkout ${failedInputFields?.includes("expiration_year") && "failed-input-field"}`} />
                                </span>
                            </div>
                            <div className="form-row column">
                                <span>
                                    <label htmlFor="securityCode">
                                        <h6>Código de segurança</h6>
                                    </label>
                                    <ReactInputMask mask="9999" maskChar={null} placeholder="Código de segurança (CVV)" id="securityCode" name="securityCode"  onChange={event => handleUpdateCardData(event)} className={`inputs-form-checkout ${failedInputFields?.includes("security_code") && "failed-input-field"}`}/>
                                    <Icon className='icon-input' icon="icon-park-outline:edit-name" />
                                </span>
                            </div> 
                        </Row>

                        <Row>
                            <div className="form-row column ">
                                <h6 className="installment-title">Parcelamento</h6>
                                <select name="installments" defaultValue="0" onChange={event => handleUpdateCardData(event)} className={`installment ${failedInputFields?.includes("installments") && "failed-input-field"}`}>
                                    <option disabled defaultChecked value={"0"}>Selecione uma opção de parcelamento</option>
                                    {installments?.map(installment => (
                                        <option key={installment?.quantity} value={installment?.quantity}> {installment?.quantity}X de R${installment?.value?.replace('.', ',')}</option>
                                    ))}
                                </select>
                            </div>
                        </Row>
                    </form>
                    <img src={Wave} className="wave-final-credit" alt="" />
                    <br />
                    <button type="submit" className="finish" onClick={handleFinishOrderPayment}>Finalizar Pedido</button>
                </div>
            </div>
        );
    } 
}

export default CreditCardMethod;