import './index.css'
import { Link } from 'react-router-dom';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const  noPhoto = "https://qas.leancommerce.com.br/Content/desktop/images/produto-sem-imagem-1000x1000.jpg";


const MultipleBannerSection = ({ banner }) => {

    return(
        <div className="banner-special-section" style={{backgroundColor: banner?.background_color}}>
            <div className="banner-top">
                <img src={imageBaseUrl + banner?.main_banner?.path + banner?.main_banner?.name} alt="" />
            </div>
            <div className="flex">
                <div className="banner-left">
                    <img src={imageBaseUrl + banner?.secondary_banner?.path + banner?.secondary_banner?.name} alt="" />
                </div>
                <div className="products-specials">
                    {banner?.banner_product?.map(product => (
                        <div key={product?.id} className="product-specials-list">
                            <Link to={product.product_data?.has_variants ? `/produto/${product?.external_code}/exemplo-3` : `/produto/${product?.external_code}/exemplo-3`}>
                                <img 
                                    width={100}
                                    height={100}
                                    alt="Imagem do produto"
                                    src={product?.product_data?.image ? imageBaseUrl + product?.product_data?.image?.path + product?.product_data?.image?.name : noPhoto}
                                />
                            </Link>
                            <h4 style={{color: banner?.font_color}} className='title-product-specials'>{product?.product_data?.Nome_Produto}</h4>
                        </div>
                    ))}
                    <Link to={''}><button className='btn-special'>Comprar Agora</button></Link>
                </div>
            </div>
        </div>
    )
}

export default MultipleBannerSection;

