import './index.css'
import React, { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-hot-toast"

// Assets
import CreditCard from '../../../../assets/img/Cart/credit-card.png'
import WaveTop from '../../../../assets/img/Cart/wave-top-payment-method.png'
import WaveBottom from '../../../../assets/img/Cart/wave-bottom-payment-method.png'

// Components
import ButtonBackAnimate from "../../CartPage/ButtonBackAnimate"

import CartContext from '../../../../context/cart/cartContext'
import OrderService from "../../../../services/OrderService"

const PaymentMethod = () => {
    const history = useHistory();
    
    const cartContext = useContext(CartContext);
    console.log(cartContext)
    const handleCreateOrder = () => {

        toast.promise(OrderService.createOrder(), {
            loading: "Criando pedido",
            error: "Não foi possível criar o pedido",
            success: "Pedido criado com sucesso"
        }).then(() => {
            setTimeout(() => {
                history.push("/"); 
                cartContext.clearCartProducts();
            }, 2000);
        });
    }

    return(
        <div className="payment-method-container">
            <ButtonBackAnimate link={'/carrinho/endereco'} buttonText="Escolher endereço de entrega"/>
            <img src={WaveTop} className="wave-top-payment-method" alt="" />
            <img src={WaveBottom} className="wave-bottom-payment-method" alt="" />
            <h3 className="title-payment-method">Selecione o método de pagamento</h3>
            <div className="payment-method-list">
                <div className="payment-option" id="credit_card">
                    <img src={CreditCard} alt="" />
                    <h4>Cartão de Crédito</h4>
                    <Link to="/pagamento/cartao-de-credito" className="link-payment" />
                </div>
                <div className="payment-option" id="credit_card">
                    <img src={CreditCard} alt="" />
                    <h4>Cartão de débito</h4>
                    <Link to="/pagamento/cartao-de-debito" className="link-payment" />
                </div>
                <div className="payment-option" id="gift_card">
                    <img src={CreditCard} alt="" />
                    <h4>Cartão Presente</h4>
                    <a className="link-payment" onClick={handleCreateOrder} />
                </div>
            </div>
        </div>
    )
}

export default PaymentMethod;