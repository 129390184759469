import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageService from '../../../services/ImageService';

function CategoryBannerIndex({ departmentId  })  {
    const [imageLink, setImageLink] = useState("https://template.hasthemes.com/argima/argima/assets/img/photos/bg-02.jpg");
    const [departmentName, setDepartmentName] = useState("");

    useEffect(() => {
        ImageService.getDepartmentBannerByDepartmentId(departmentId).then(response => {
            const department = response?.department?.Nome_DepartamentoCommerce;            
            setDepartmentName(department);

            if(response?.banner) {
                const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
                const imagePath = response?.banner?.path;
                const imageName = response?.banner?.name;
                
                const imageLink = imageUrl + imagePath + imageName;
                setImageLink(imageLink);
            }
        });
    }, []);

    return (
        <div className="page-header-area bg-img" style={{ backgroundImage: `url(${imageLink})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="page-header-content">
                            <nav className="breadcrumb-area">
                                <ul className="breadcrumb">
                                    <li>
                                        <Link to={'/'}>
                                            <b>Início</b>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-sep">
                                        <i className="fa fa-angle-right"></i>
                                    </li>
                                    <li>
                                        <b>Categoria</b>
                                    </li>
                                    <li className="breadcrumb-sep">
                                        <i className="fa fa-angle-right"></i>
                                    </li>
                                    <li>
                                        <b>{departmentName}</b>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryBannerIndex;