import { toast } from "react-hot-toast";
import Logo from '../../assets/img/Logo.png';
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import HandIcon from '../../assets/img/login/handle.png';

import CartContext from "../../context/cart/cartContext";
import AuthContext from "../../context/auth/AuthContext";
import CustomerService from "../../services/CustomerService";
import { Modal, ModalBody } from "reactstrap";

function LoginModal({ showModal, setShowModal, redirectUrl = "/"}) {
    const history = useHistory(); 
    const authContext = useContext(AuthContext);
    const cartContext = useContext(CartContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = event  => {
        event.preventDefault();

        toast.promise(CustomerService.login(email, password), {
            success: "Sucesso",
            loading: "Verificando credencias",
            error: "Não foi possível fazer o login"
        }).then(response => {
            const { data } = response;
            setShowModal(false);

            cartContext.getUserCart();
            authContext.authenticateUser(data);
    
            history.push(redirectUrl);
        });
    }

    return (
        <Modal className="login-modal-area" isOpen={showModal} centered={true} toggle={() => setShowModal(false)}>
            <ModalBody>
                <div className="login-modal-content">
                    <img src={Logo} className="login-logo" />
                    <div className="text-login">
                        <span className='title-login'>Olá <img src={HandIcon} alt="" /></span>
                        <span className="desc-login">Vamos entrar na sua conta para que você possa desfrutar de tudo no LáNaMaria</span>
                    </div>
                    <form className='form-login' onSubmit={event => handleLogin(event)}>
                        <input type="text" name='Email' id="email" placeholder='E-mail' onChange={event => setEmail(event.target.value)} />
                        <input type="password" name='Password' id="password" placeholder='Senha' onChange={event => setPassword(event.target.value)}  />
                        <div className="form-extra">
                            <span className="checkbox-area">
                                {/* <input type="checkbox" name="remember" id="remember" /> */}
                                {/* <label htmlFor="remember" className='remember'>Lembrar de mim </label> */}
                            </span>
                            <a href="/forgot-password" className='forgot-password'>Esqueceu sua senha?</a>
                        </div>
                        <button type='submit' className='confirm-login' >Entrar</button>
                    </form>
                    <a href="/cadastre-se" className='create-account'>Não possui conta ainda? Cadastre-se.</a>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default LoginModal;