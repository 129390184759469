
import {React, useEffect, useState} from "react";
import './index.css';

import ProductService from "../../../../services/ProductService";
import ProuctCardComponent from "../../../../components/ProductCardComponent";

const FeaturedProducts = (props) => {

    const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(0);

    useEffect(()=>{
        getFeaturedProducts()
    },[selectedDepartment]);

    const getFeaturedProducts = () => {
        
        ProductService.getAvailableFeaturedProducts(selectedDepartment)
            .then(response => {
                const departments = response?.departments;
                const products = response?.products;

                setLoading(false);
                setProducts(products);
                setDepartments(departments);
            })
            .finally(setLoading(true))
    }

    return(
        <div className="container-featured-products">
            <div className="section-title text-center">
                <h2 className="title">Produtos em destaque</h2>
                <div className="desc">
                    <p>Veja as tendências do mercado!</p>
                </div>
            </div>
            <div className="select-departament">
                {departments?.map(department => (
                    <div
                        className={`featured-departament-card ${selectedDepartment === department.id ? 'active' : ''}`}
                        onClick={()=>{ setSelectedDepartment(department.id); console.log('foi') }}
                    >
                        <img src={`${imageUrl}/images/departments/${department?.external_section}.png`}  alt="imagem departamento" />
                        <span>{department?.section?.Descricao_Secao}</span>
                    </div>
                ))}
            </div>
            
            {loading && <div className="container-lds"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
                 
            
            {!loading &&
                <div className="products-container">
                    {products.map(productData => (
                        productData?.is_active && (
                            <div key={productData?.id}>
                                <ProuctCardComponent wish={true} item={productData?.product} />
                            </div>
                        )
                    ))}
                </div>
            }
        </div>
    );
}

export default FeaturedProducts;