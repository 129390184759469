import './index.css'

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Icon } from '@iconify/react'
import { toast } from 'react-hot-toast';


import Header from '../../../../components/Header';
import DebitCard from "../PaymentMethods/DebitCard";
import CreditCard from '../PaymentMethods/CreditCard';
import ProductRatingModal from '../../../../components/ProductRatingModal';

import OrderService from '../../../../services/OrderService';
import AuthContext from '../../../../context/auth/AuthContext';


const OrderDetails = (props) => {
    const authContext = useContext(AuthContext);
    const {order_id, product_to_rate } = useParams();
    
    const [date, setDate] = useState();
    const [options, setOptions] = useState();
    const [orderDetail, setOrderDetail] = useState({});
    const [selectedProductToRate, setSelectedProductToRate] = useState({});
    const [showProductRatingModal, setShowProductRatingModal] = useState(false);


    useEffect(()=>{
        getOrderDetails();
    },[])

    useEffect(()=>{
        filterDate()
    },[orderDetail])

    function getOrderDetails() {
        const customerId = authContext?.user?.id;
        
        OrderService.getOrderDetails(order_id, customerId)
        .then(response => {
            const order = response.data;
            const orderProducts = response?.data?.order_product;

            if(product_to_rate) {
                searchProductToRateInOrderProducts(orderProducts);
            }

            setOrderDetail(order);
        });

    }
    function filterDate () {
        if(orderDetail.length >= -1){
            const date = new Date (orderDetail.created_at)
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false, timeZone: 'America/Sao_Paulo'};
            setDate(date)
            setOptions(options)
        }
    }

    function searchProductToRateInOrderProducts(products) {
        products?.map(product => {
            if(product?.product_data?.CodigoInterno_Produto === Number(product_to_rate)) {
                setShowProductRatingModal(true);
                setSelectedProductToRate(product);
                return;
            }
        });
    }

    function getTotalDiscounts() {
        const discountValue = orderDetail?.total - orderDetail?.total_with_discounts; 
        return discountValue.toFixed(2).replace('.', ',');
    }

    function handleCancelOrder() {
        toast.promise(OrderService.cancelOrder(order_id), {
            loading: "Cancelando pedido...",
            success: "Pedido cancelado!",
            error: "Não foi possível cancelar o pedido"
        }).then(() => {
            getOrderDetails();
        });
    }

    function handleShowProductRatingModal(product) {
        setShowProductRatingModal(true);
        setSelectedProductToRate(product);
    }

    function handlePaymentMethod() {
        const paymentMethods = {
            "credit_card": <CreditCard payment={orderDetail?.order_payment} />,
            "debit_card": <DebitCard payment={orderDetail?.order_payment} />
        }
        
        const paymentMethodExists = paymentMethods?.hasOwnProperty(orderDetail?.order_payment?.type);

        if(paymentMethodExists) {
            return paymentMethods[orderDetail?.order_payment?.type];
        }
    }

    return(
        <div>
            <ProductRatingModal 
                openModal={showProductRatingModal}
                setOpenModal={setShowProductRatingModal} 
                product={selectedProductToRate}
                redirectUrl={`/pedidos/${order_id}/detalhes`}
            />
            <Header />
            <div className="container-order-details">
                <span className='title-order-details'>
                    <Icon icon="material-symbols:order-approve" color="#9d7fad" width="60px"/> 
                    Pedido {orderDetail?.id}
                </span>
                <span className='date-order-detail'>
                    <span>
                        Data do pedido: 
                        {new Date(orderDetail?.created_at).toLocaleString('pt-BR').replace(',', ' às ')}
                        <br />
                  
                    </span>
                </span>
                <div className="table-order">
                    <div className="line-initial">
                        <span className='table-product-line-order'>Produto</span>
                        <span>Preço</span>
                        <span>Quantidade</span>
                        <span>Subtotal</span>
                    </div>
                    {orderDetail?.order_product?.map(product => {
                        return(
                                <div className="line-initial" key={product.id}>
                                    <span className='table-product-line-order'>{product?.product_data?.Nome_Produto}</span>
                                    <span>R$ {product.original_value.replace('.', ',')}</span>
                                    <span>{product?.quantity}</span>
                                    <span>R$ {product.total_value.replace('.', ',')}</span>
                                    <button className="rate-product-button" onClick={() => handleShowProductRatingModal(product)}>Avaliar produto</button>
                                </div>
                        )
                    })}
                    
                </div>
                <div className="price-details">
                    <div className="line-price-details">
                        <span>Subtotal</span>
                        <span>R$ {orderDetail?.total?.replace('.', ',')}</span>
                    </div>
                    <div className="line-price-details">
                        <span>Custo de Entrega</span>
                        <span>R$ {orderDetail?.freight?.freight_value?.toString().replace('.', ',')}</span>
                    </div>
                    {orderDetail?.total_with_discounts > 0 && 
                        <div className="line-price-details">
                            <span>Descontos</span>
                            <span>{orderDetail?.total_with_discounts > 0 && `-R$ ${getTotalDiscounts()}`}</span>
                        </div>
                    }
                    <div className="line-price-details">
                        <span>Total de Pedido</span>
                        <span>R$ {orderDetail?.total_price}</span>
                    </div>
                </div>
                <div className="container-extra-details">
                    <div className="adress-details">
                    <span>
                        <h5>Endereço de Cobrança e Entrega</h5>
                        <i> 
                            Entrega via: {orderDetail?.freight?.freight_service_name}<br />
                            Em até {orderDetail?.freight?.delivery_date} dias úteis <br /> <br/>
                            {orderDetail?.freight?.address?.address_name}<br />
                            {orderDetail?.freight?.address?.street} - {orderDetail?.freight?.address?.number} {orderDetail?.freight?.address?.complement && `- ${orderDetail?.freight?.address?.complement}`}   {orderDetail?.freight?.address?.reference && `- ${orderDetail?.freight?.address?.reference}`}<br />
                            {orderDetail?.freight?.address?.complement} {orderDetail?.freight?.address?.city} - {orderDetail?.freight?.address?.zipcode}
                        </i>
                    </span>
                    </div>
                    <div className="adress-details">
                    <span>
                        {handlePaymentMethod()}
                    </span>
                    </div>
                </div>
                
                <div className="line-btns">
                    <button className='cancel-order' onClick={handleCancelOrder}>CANCELAR PEDIDO</button>
                    {/* <button className='track-order'>RASTREAR PEDIDO</button> */}
                </div>
                
            
            </div>
        </div>
    )
}

export default OrderDetails;