import React from "react";
import './index.css'

import Line from '../../../assets/img/line-3.png'
import Facebook from '../../../assets/img/Icones/facebook.png'
import Instagram from '../../../assets/img/Icones/instagram.png'
import Twitter from '../../../assets/img/Icones/twitter.png'
import Youtube from '../../../assets/img/Icones/youtube.png'
import Network from '../../../assets/img/community-img-01.png'
import Experience from '../../../assets/img/community-img-02.png'

const Community = () => {
    return(
        <div className="community-container">
            <h3 className="title-community">Tenha e compartilhe experiências na nossa comunidade</h3>
            <img src={Line} className="community-line" alt="" />
            <div className="social-media-community">
                <a href="" className="Facebook"><img src={Facebook} alt="" /></a>
                <a href="" className="Instagram"><img src={Instagram} alt="" /></a>
                <a href="" className="Twitter"><img src={Twitter} alt="" /></a>
                <a href="" className="Youtube"><img src={Youtube} alt="" /></a>
            </div>
            <div className="images-community">
            <img src={Network} alt="" />
            <img src={Experience} alt="" />
            </div>
        </div>
    );
}


export default Community;